var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container_cart",on:{"wheel":_setup.onWheel,"mousedown":_setup.onMouseDown,"mousemove":_setup.onMouseMove,"mouseup":_setup.onMouseUp,"mouseleave":_setup.onMouseUp}},[_c('div',{staticStyle:{"position":"absolute","top":"10px","right":"10px","display":"flex","gap":"10px","z-index":"2"}},[_c('button',{staticClass:"button_plus",on:{"click":_setup.zoomIn}},[_vm._v("+")]),_c('button',{staticClass:"button_plus",on:{"click":_setup.zoomOut}},[_vm._v("-")])]),_c('img',{style:({
        willChange: 'transform',
        transform: `scale(${_setup.scale}) translate(${_setup.translateX}px, ${_setup.translateY}px)`,
        transformOrigin: 'center center',
        cursor: _setup.isDragging ? 'grabbing' : 'grab',
        transition: _setup.imageLoaded ? 'transform 0.1s ease-out' : 'none', // Плавность движения
      }),attrs:{"src":_setup.plan_17,"alt":"План 17","draggable":"false"},on:{"load":function($event){_setup.imageLoaded = true}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }