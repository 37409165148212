 <script setup>
  import plan_17 from "../files/plan_17.svg";
  import { ref, onMounted } from "vue";

  const scale = ref(1); // Масштаб изображения
  const translateX = ref(0); // Смещение по оси X
  const translateY = ref(0); // Смещение по оси Y

  const isDragging = ref(false); // Указывает, идет ли перетаскивание
  let startX = 0; // Начальная позиция X при захвате
  let startY = 0; // Начальная позиция Y при захвате
  const imageLoaded = ref(false); // Указывает, загружена ли картинка

  const animateImage = () => {
    // Анимация приближения и отдаления
    scale.value = 1.5; // Начальное приближение
    setTimeout(() => {
      scale.value = 1; // Отдаление через 500ms
    }, 100);
  };

  const onWheel = (event) => {
    const zoomStep = 0.1;
    scale.value += event.deltaY > 0 ? -zoomStep : zoomStep;
    scale.value = Math.min(Math.max(scale.value, 0.5), 3); // Ограничиваем зум
  };

  const zoomIn = () => {
    scale.value = Math.min(scale.value + 0.1, 3); // Увеличиваем масштаб
  };

  const zoomOut = () => {
    scale.value = Math.max(scale.value - 0.1, 0.5); // Уменьшаем масштаб
  };

  const onMouseDown = (event) => {
    isDragging.value = true;
    startX = event.clientX - translateX.value; // Учитываем текущее смещение translateX
    startY = event.clientY - translateY.value; // Учитываем текущее смещение translateY
  };

  const onMouseMove = (event) => {
    if (!isDragging.value) return;

    // Рассчитываем новое смещение
    translateX.value = event.clientX - startX;
    translateY.value = event.clientY - startY;
  };

  const onMouseUp = () => {
    isDragging.value = false;
  };

  onMounted(() => {
    imageLoaded.value = true;
    setTimeout(() => {
      animateImage(); // Запуск анимации при загрузке
    }, 200); // Небольшая задержка для плавного появления
  });
</script>

<template>
  <div class="container_cart" @wheel="onWheel" @mousedown="onMouseDown" @mousemove="onMouseMove" @mouseup="onMouseUp" @mouseleave="onMouseUp">
    <div style="position: absolute; top: 10px; right: 10px; display: flex; gap: 10px; z-index: 2">
        <button class="button_plus" @click="zoomIn">+</button>
        <button class="button_plus" @click="zoomOut">-</button>
      </div>
  
      <img
        :src="plan_17"
        alt="План 17"
        draggable="false"
        @load="imageLoaded = true"
        :style="{
          willChange: 'transform',
          transform: `scale(${scale}) translate(${translateX}px, ${translateY}px)`,
          transformOrigin: 'center center',
          cursor: isDragging ? 'grabbing' : 'grab',
          transition: imageLoaded ? 'transform 0.1s ease-out' : 'none', // Плавность движения
        }"
      />
    </div>
  </template>
  
  <style scoped>
    img {
      transition: opacity 0.5s ease-in-out, transform 0.1s ease-out;
    }
    .container_cart {
      width: 60vw;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
      height: 70vh;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgb(240, 240, 240);
      position: relative;
    }
    .button_plus {
      width: 35px;
      height: 35px;
      background: #fff;
      cursor: pointer;
      border: 2px solid #095776;
      font-size: 16px;
      font-weight: bold;
      color: #062c3b;
    }
  
    .button_plus:hover:before,
    .button_plus:hover:after {
      background: #fff;
      transition: 0.2s;
    }
  
    .button_plus:hover {
      background-color: #095776;
      color: white;
      transition: 0.2s;
    }
  </style> 