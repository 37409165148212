<template>
  <div>
    <div class="form" id="element-to-convert" ref="form_1">
      <div class="header_description">
        <span class="mini_text">
          Приложение 1
          <br />
          к Положению об обучении и развитии
          <br />
          персонала организации
        </span>
        <h5>
          Генеральному директору
          <br />
          ООО "Транспортные Технологии"
          <br />
          Тертычному С.Н.
        </h5>
        <div class="input_block">
          <label for="">
            <input type="text" />
            (Должность)
          </label>
          <label for="" style="margin-top: -2%">
            <input type="text" />
          </label>
          <label for="">
            <input type="text" />
            (Наименование подразделения)
          </label>
          <label for="">
            <input type="text" />
            (фамилия, инициалы)
          </label>
        </div>
      </div>
      <div class="text" style="margin-top: -2%">
        <h4 align="center">Служебная записка</h4>
        <div class="description_report">
          <p style="display: flex; align-items: baseline">
            &nbsp;Прошу направить в &nbsp;
            <label style="flex-grow: 1; display: flex; flex-direction: column">
              <input type="text" style="width: 100%; margin-bottom: 0" />
              <span style="font-size: 12px; margin-top: 4px; text-align: center">наименование обучающей организации</span>
            </label>
          </p>
          <p style="display: flex; align-items: baseline">
            для обучения по теме &nbsp; "
            <label style="flex-grow: 1; display: flex; flex-direction: column">
              <input type="text" style="width: 100%; margin-bottom: 0" />
              <span style="font-size: 12px; margin-top: 4px; text-align: center">указать тему семинара</span>
            </label>
            "
          </p>
          <p style="display: flex; align-items: baseline; flex-wrap: wrap">
            Дата проведения семинара &nbsp;"
            <label style="display: flex; flex-direction: column; width: 10%">
              <input type="text" style="width: 100%; margin-bottom: 0" />
            </label>
            "
            <label style="display: flex; flex-grow: 1; flex-direction: column; width: 20%">
              <input type="text" style="width: 100%; margin-bottom: 0" />
            </label>
            &nbsp;
            <label style="display: flex; flex-grow: 1; flex-direction: column; width: 15%">
              <input type="text" style="width: 100%; margin-bottom: 0" />
            </label>
            <label style="display: flex; flex-grow: 1; flex-direction: column; width: 5%">г.</label>
            <label style="display: flex; flex-grow: 1; flex-direction: column; width: auto">с сохранением заработной платы следующего сотрудника:</label>
          </p>
          <p style="display: flex; align-items: baseline">
            <label style="flex-grow: 1; display: flex; flex-direction: column">
              <input type="text" style="width: 100%; margin-bottom: 0" />
              <span style="font-size: 12px; margin-top: 4px; text-align: center">ФИО, должность</span>
            </label>
          </p>
          <p style="display: flex; align-items: baseline; flex-wrap: wrap">
            Стоимость обучения:
            <label style="display: flex; flex-grow: 1; flex-direction: column; width: 10%">
              <input type="text" style="width: 100%; margin-bottom: 0" />
            </label>
            рублей
          </p>
          <p>Обучение: обязательное/ в целях повышения квалификации/связано с внесением изменений в нормативные акты Российской Федерации (выбрать необходимое).</p>
          <p style="display: flex; align-items: baseline; flex-wrap: wrap">
            <label style="display: flex; flex-direction: column">
              <input type="text" style="width: 100%; margin-bottom: 0" />
              <span style="font-size: 12px; margin-top: 4px; text-align: center">(должность)</span>
            </label>
            &nbsp;
            <label style="display: flex; flex-grow: 1; flex-direction: column">
              <input type="text" style="width: 100%; margin-bottom: 0" />
              <span style="font-size: 12px; margin-top: 4px; text-align: center">(подпись)</span>
            </label>
            &nbsp;
            <label style="display: flex; flex-grow: 1; flex-direction: column">
              <input type="text" style="width: 100%; margin-bottom: 0" />
              <span style="font-size: 12px; margin-top: 4px; text-align: center">(расшифровка)</span>
            </label>
          </p>
        </div>
      </div>

      <div class="date_block" style="margin-top: -2%">
        <p align="right">
          "
          <input type="text" style="width: 10%" />
          "
          <input type="text" style="width: 20%" />
          &nbsp;
          <input type="text" style="width: 10%" />
          г.
        </p>
      </div>

      <div class="visa">
        <b>Визы:</b>
        <div class="visa-item">
          <b>Руководитель структурного подразделения:</b>
          <div>
            <input type="text" />
            /
            <input type="text" />
          </div>
        </div>
        <div class="visa-item">
          <b>Руководитель финансовой службы:</b>
          <div>
            <input type="text" />
            /
            <input type="text" />
          </div>
        </div>
      </div>
    </div>
    <div class="navigation">
      <span class="back" @click="$emit('clearForm', true)">
        <b-icon icon="chevron-left"></b-icon>
        Вернуться назад
      </span>
      <ButtonSendApplicationUIElement :nameApplication="'Заявка на обучение'" />

      <b-button variant="success" @click="exportToPDF" style="margin-top: 2%; width: 20%">
        Скачать
        <b-icon icon="file-pdf" style="width: 20px" />
      </b-button>
    </div>
  </div>
</template>

<script>
  import html2pdf from "html2pdf.js";
  import ButtonSendApplicationUIElement from "../ui/ButtonSendApplicationUIElement.vue";

  export default {
    components: {
      ButtonSendApplicationUIElement,
    },
    methods: {
      exportToPDF() {
        // Сохраняем исходные стили
        const element = document.getElementById("element-to-convert");
        const originalStyles = element.style.cssText;

        // Изменяем стили для экспорта в PDF
        element.style.height = "auto";
        element.style.boxShadow = "none";
        element.style.width = "796px";

        // Экспортируем в PDF
        this.$nextTick(() => {
          html2pdf()
            .set({
              margin: 1,
              filename: "Заявка на обучение.pdf", // Adjust extension to .pdf
              image: { type: "jpeg", quality: 1 }, // Maximize image quality
              html2canvas: { scale: 2 }, // Increase scale for better clarity
              jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            })
            .from(element)
            .save()
            .then(() => {
              // Restore original styles after PDF generation
              element.style.cssText = originalStyles;
            });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../style/style_form.scss";

  .visa {
    padding-left: 10%;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
  }

  .visa-item {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Выравнивание по центру по вертикали */
  }

  .visa-item b {
    font-family: "Times New Roman", Times, serif;
    font-weight: 500; /* Перенесено сюда для ясности */
  }

  .visa-item input {
    width: 40%;
    margin: 0; /* Убираем отступы */
    padding: 5px; /* Установите внутренний отступ, если нужно */
  }
</style>
