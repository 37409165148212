<script>
  import { mapState } from "vuex";
  import api from "@/api/staff";

  export default {
    data() {
      return {
        responseUser: [],
        allResponseUsers: [],
        awardIcons: ["📯", "🏆", "🏀", "🎉", "⭐️", "👠"],
        awardsList: ["award_1", "award_2", "award_3", "award_4", "award_5", "award_6"],
        querySearch: "",
        focusedAwards: [], // Список фокусированных наград
      };
    },
    computed: {
      ...mapState({
        user: (state) => state.auth.users,
        uid: (state) => state.auth.user.user.id,
      }),
    },
    async mounted() {
      await this.getFullData();
    },
    methods: {
      async getFullData() {
        try {
          this.querySearch = "";
          let response = await api.staffGlobal();
          const russianLettersRegex = /^[А-Яа-яёЁ\s]+$/;
          this.allResponseUsers = response.data.data.filter((item) => russianLettersRegex.test(item.last_name));
          this.responseUser = [...this.allResponseUsers];
        } catch (err) {
          console.error("Ошибка при загрузке сотрудников", err);
        }
      },
      updateApplication(value) {
        const russianLettersRegex = /^[А-Яа-яёЁ\s]+$/;

        if (value.length === 0) {
          this.responseUser = [...this.allResponseUsers];
          return;
        }

        this.responseUser = this.allResponseUsers.filter((user) => {
          const lastName = user.last_name.toLowerCase();
          return lastName.includes(value.toLowerCase()) && russianLettersRegex.test(lastName);
        });
      },
      async setNewAwardsForUser(id, award) {
        try {
          let response = await api.currentUser(id);
          let newAwards = response.data.gifts ? `${response.data.gifts},${award}` : award;

          await api.changeUserData(id, { gifts: newAwards });
          this.$toast.success("Успешно!\nСотруднику присвоена награда\nОн её увидит в личном кабинете", { timeout: 4000 });
          this.getFullData();
        } catch (err) {
          console.error("Ошибка присвоения награды", err);
        }
      },
      handleAwardClick(userId, award) {
        const user = this.allResponseUsers.find((u) => u.id === userId);
        if (user && user.gifts && user.gifts.split(",").includes(award)) {
          alert("Эта награда уже присвоена пользователю.");
        } else {
          this.setNewAwardsForUser(userId, award);
          // Добавляем фокус
          this.focusedAwards.push({ userId, award });
        }
      },
      isFocused(userId, award) {
        return this.focusedAwards.some((entry) => entry.userId === userId && entry.award === award);
      },
    },
  };
</script>

<template>
  <div>
    <input type="text" placeholder="Поиск сотрудников..." @input="updateApplication($event.target.value)" v-model="querySearch" />
    <span class="description">* Фильтр работает по фамилии пользователя</span>
    <div style="max-height: 45vh; overflow: auto; width: 100%">
      <table style="width: 100%" class="custom-table">
        <thead>
          <tr>
            <th>Фамилия</th>
            <th>Имя</th>
            <th>Отчество</th>
            <th>Должность</th>
            <th>Награды</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in responseUser" :key="user.id">
            <td>{{ user.last_name }}</td>
            <td>{{ user.first_name }}</td>
            <td>{{ user.middle_name }}</td>
            <td>{{ user.post }}</td>
            <td class="awards">
              <span
                v-for="(icon, index) in awardIcons"
                :key="index"
                :class="{
                  active: user.gifts && user.gifts.split(',').includes(awardsList[index]),
                  disabled: user.gifts && user.gifts.split(',').includes(awardsList[index]),
                  focused: isFocused(user.id, awardsList[index]),
                }"
                :style="{
                  filter: user.gifts && user.gifts.split(',').includes(awardsList[index]) ? 'grayscale(0%)' : isFocused(user.id, awardsList[index]) ? 'grayscale(0%)' : 'grayscale(100%)',
                  cursor: user.gifts && user.gifts.split(',').includes(awardsList[index]) ? 'not-allowed' : 'pointer',
                }"
                @click="handleAwardClick(user.id, awardsList[index])"
              >
                {{ icon }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="allResponseUsers.length === 0" class="loading">
      <p>Загрузка сотрудников компании...</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import "../style/style.scss";

  .icon {
    font-size: 2vh;
    transition: filter 0.3s;
  }
  .awards {
    span {
      padding: 0 0.5vw;
      display: inline-block;
      transition: filter 0.3s, transform 0.2s;

      &:hover {
        transform: scale(1.1); /* Лёгкое увеличение при наведении */
        filter: grayscale(0%);
      }
    }

    .disabled {
      pointer-events: none;
    }

    .active {
      filter: grayscale(0%);
    }

    .focused {
      filter: grayscale(0%);
    }

    /* Эффект hover только для доступных кликнутых элементов */
    span:not(.disabled):not(.active):hover {
      filter: grayscale(0%) brightness(1.2); /* Убираем серый фильтр и добавляем яркость */
    }
  }

  .disabled {
    pointer-events: none;
  }
  .active {
    filter: grayscale(0%);
  }
  .description {
    margin-top: 2%;
    font-size: 12px;
    color: grey;
  }
  .loading {
    text-align: center;
    margin-top: 10vh;
  }
</style>
