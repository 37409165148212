<script>
  import api from "@/api/staff"; // Подключение API
  import Loader from "@/components/loader/loader.vue";

  export default {
    components: { Loader },
    data() {
      return {
        loader: false,
        tasksUser: [
          {
            task: "Подписать док-ты о приеме на работу в отделе по работе с персоналом",
            checked: false,
          },
          {
            task: "Пройти инструктаж по охране труда",
            checked: false,
          },
          {
            task: "Получение пропуска в СБ",
            checked: false,
          },
          {
            task: "Познакомиться с рабочим местом",
            checked: false,
          },
          {
            task: "Познакомиться с коллективом",
            checked: false,
          },
          {
            task: "Ознакомиться с планом помещения",
            checked: false,
          },
        ],
        tasksUserWeek: [
          {
            task: "Взаимодействие с талисманом компании",
            checked: false,
          },
          {
            task: "Пройти 'Опрос №1'",
            checked: false,
          },
        ],
        firstDaysTasksList: [], // Задачи на первый день
        firstWeekTasksList: [], // Задачи на первую неделю
      };
    },
    async mounted() {
      try {
        this.loader = true;
        // Получаем ID пользователя
        const uid = JSON.parse(localStorage.getItem("vuex"));
        const response = await api.currentUser(uid.auth.uid);

        // Обработка задач первого дня
        const firstDayTasks = JSON.parse(response.data.first_days_tasks_list || "[]");
        this.firstDaysTasksList = firstDayTasks;
        this.tasksUser.forEach((task) => {
          const matchedTask = firstDayTasks.find((item) => Object.keys(item)[0] === task.task);
          if (matchedTask) {
            task.checked = matchedTask[task.task];
          }
        });

        // Обработка задач первой недели
        const firstWeekTasks = JSON.parse(response.data.first_week_tasks_list || "[]");
        this.firstWeekTasksList = firstWeekTasks;
        this.tasksUserWeek.forEach((task) => {
          const matchedTask = firstWeekTasks.find((item) => Object.keys(item)[0] === task.task);
          if (matchedTask) {
            task.checked = matchedTask[task.task];
          }
        });

        this.loader = false;
      } catch (error) {
        console.error("Ошибка при загрузке данных пользователя:", error);
        this.loader = false;
      }
    },
    methods: {
      async checkData(index, isWeek = false) {
        try {
          // Выбираем список задач (на день или на неделю)
          const tasks = isWeek ? this.tasksUserWeek : this.tasksUser;
          const taskList = isWeek ? this.firstWeekTasksList : this.firstDaysTasksList;

          // Обновляем задачу
          const task = tasks[index];
          const updatedTasks = [
            ...taskList.filter((item) => Object.keys(item)[0] !== task.task),
            { [task.task]: task.checked },
          ];

          // Обновляем соответствующий список
          if (isWeek) {
            this.firstWeekTasksList = updatedTasks;
          } else {
            this.firstDaysTasksList = updatedTasks;
          }

          // Формируем запрос
          const query = isWeek
            ? { first_week_tasks_list: JSON.stringify(updatedTasks) }
            : { first_days_tasks_list: JSON.stringify(updatedTasks) };

          // Отправляем запрос
          const uid = JSON.parse(localStorage.getItem("vuex"));
          await api.changeUserData(uid.auth.uid, query);
        } catch (error) {
          console.error("Ошибка при обновлении данных:", error);
        }
      },
    },
  };
</script>


<template>
  <div>
    <Loader :loader="loader" />
    <div style="height: 70vh; overflow: auto">
      <!-- Список задач на первый день -->
      <div style="display: flex; justify-content: space-between; align-items: end; gap: 2%; width: 95%">
        <h4 class="list_task">Список задач на 1 день</h4>
        <span class="amount_task">Всего задач: {{ tasksUser.length }}</span>
        <span class="amount_task">Выполнено: {{ tasksUser.filter((task) => task.checked).length }}</span>
      </div>
      <div class="content_todo_day">
        <div class="content_todo_day__list">
          <ul>
            <li v-for="(task, index) in tasksUser" :key="index">
              <div>
                <p :class="{ line_through: task.checked }">{{ task.task }}</p>
                <input type="checkbox" v-model="task.checked" @change="checkData(index)" :disabled="task.checked" />
              </div>
              <hr />
            </li>
          </ul>
        </div>
      </div>

      <!-- Список задач на первую неделю -->
      <div style="display: flex; justify-content: space-between; align-items: end; gap: 2%; width: 95%">
        <h4 class="list_task">Список задач на 1 неделю</h4>
        <span class="amount_task">Всего задач: {{ tasksUserWeek.length }}</span>
        <span class="amount_task">Выполнено: {{ tasksUserWeek.filter((task) => task.checked).length }}</span>
      </div>
      <div class="content_todo_day">
        <div class="content_todo_day__list">
          <ul>
            <li v-for="(task, index) in tasksUserWeek" :key="index">
              <div>
                <p :class="{ line_through: task.checked }">{{ task.task }}</p>
                <input
                  type="checkbox"
                  v-model="task.checked"
                  @change="checkData(index, true)"
                  :disabled="task.checked"
                />
              </div>
              <hr />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
  @import "../style/style_todo.scss";
</style>
