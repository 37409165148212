<template>
  <div>
    <Loader :loader="loader"></Loader>
    <div style="width: 100%; overflow-x: auto; overflow-y: auto; position: relative; left: 50%; height: 50vh; transform: translate(-50%, 0)">
      <table class="table table-sm table-bordered table-hover" style="margin: 0">
        <thead class="thead-light" style="background: #e9ecef !important">
          <tr>
            <th>Название</th>
            <th>Дата создания</th>
            <th>Дата изменения</th>
            <th>Файл</th>
            <th>Действие</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="bdr in BDR_report" :key="bdr.id">
            <td>{{ bdr.name }}</td>
            <td>{{ new Date(bdr.created_at).toLocaleString() }}</td>
            <td>{{ new Date(bdr.updated_at).toLocaleString() }}
            </td>
            <td>
              <a target="_blank" :href="bdr.file" v-if="bdr.file"><img src="@/assets/excel.png" alt="" width="50px !important" /></a>
            </td>
            <td>
              <!-- <button style="height: 100%; vertical-align: middle; display: flex; align-items: center; justify-content: center; background: none" @click="OpenCreateBDR_report(bdr.id)">
                <img src="@/assets/edit5.png" class="icon-active" alt="" />
              </button> -->
              <b-button size="sm" class="mb-2 border rounded p-2" style="width: 100%; margin: 0 !important" @click="OpenCreateBDR_report(bdr.id)">
                <b-icon icon="gear-wide-connected" aria-hidden="true" focusable="false"></b-icon>
                Изменить
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import Loader from "@/components/loader/loader.vue";
  import api from "@/api/report";
  import { mapState } from "vuex";

  export default {
    components: { Loader },
    data() {
      return {
        loader: false,
        BDR_report: "",
      };
    },
    computed: {
      ...mapState({
        user: (state) => state.auth.user,
        uid: (state) => state.auth.uid,
      }),
    },
    async mounted() {
      try {
        this.loader = true;
        let response = await api.getBDRreport();
        this.BDR_report = response.data.data;
        this.loader = false;
        this.$toast.success("Отчеты загружены", { timeout: 3000 });
      } catch (err) {
        this.loader = false;
        this.$toast.error("Отчеты не загружены", { timeout: 3000 });
        console.debug(err);
      }
    },
    methods: {
      OpenCreateBDR_report(id) {
        window.open("/edit-bdr/" + `${id}`, "_blank");
      },

    },
  };
</script>
