const fin_counerpartie = {
    "ПОСТУПЛЕНИЯ ПО ОПЕРАЦИОННОЙ ДЕЯТЕЛЬНОСТИ": {'week_days': {},'plan': 0, 'prognoz': 0},
    "Доходы ЦС": {
        'week_days': {},
        'plan': 0,
        'prognoz': 0,
        'companies': {
            "ARAVT GROUP ТОО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "CARPE DIEM ENERGY DMCC": {'week_days': {},'plan': 0, 'prognoz': 0},
            "GLP Operator": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Logistic resurs TOO": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ST CARGO LOGISTIC TOO": {'week_days': {},'plan': 0, 'prognoz': 0},
            "TOO CITITRANS": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АВАНТТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Авион Норд ТОО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АУРА ОЙЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВЕКТОР-ДВИЖЕНИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВЕРАТЭК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГК Титан АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГНК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГПН": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГПТ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Грифон": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Дельта-Трейд ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Диптранс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Еврохим": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЗСТК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЛУКОЙЛ ЛУБРИКАНТС": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Мечел": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МЕЧЕЛ-ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ННК-ТРАНС АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НХС": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НХТК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ОБНИНСКОРГСИНТЕЗ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ОКСИ ЭНЕРДЖИ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ОМСК ТРАНС-СЕРВИС": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ОТС-ЛОГИСТИКА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПГК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПЕТРОЛЕУМ ТОО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПМХ-ТРАНСПОРТ ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПОТ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ППО-КЛМ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Премиум Ойл Транс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРОМЖДСЕРВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Проминвест": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЕЙЛ СЕРВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЕСУРС ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Р-ЛАЙН ТРАНСПОРТНЫЕ СИСТЕМЫ ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РН-Транс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РСТ-Оператор": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РТК-Групп": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РТС-Транс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РусОйлЭкс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РУСТЭК-МАГИСТРАЛЬ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЭУ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "САНТОРИНИ ВИКТОРИ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СГК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СОКАР РУС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТАР ГРУПП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТС_ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТС-ГРУПП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Стэнком": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СЭТ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТАЙГА ЭНЕРДЖИ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТАКТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Татнефтехим ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТВ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТЛК ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТНТ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТрансТэк": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРЕЙД ОЙЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Триумф ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТТК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УРАЛ ЛОГИСТИКА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УРАЛНЕФТЕТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ФАВОРИТТРАНССЕРВИС ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ФГК АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Форст Трейдинг": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Элемент": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Энергоресурсы": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЯДРАН АО": {'week_days': {},'plan': 0, 'prognoz': 0}
        }
    },
    "Доходы ПВ": {
        'week_days': {},
        'plan': 0,
        'prognoz': 0,
        'companies': {
            "Alkos": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Carbon Trading Sp.": {'week_days': {},'plan': 0, 'prognoz': 0},
            "FSL": {'week_days': {},'plan': 0, 'prognoz': 0},
            "GLP Operator": {'week_days': {},'plan': 0, 'prognoz': 0},
            "GR TRADE ТОО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "HORUS Intertrade & Consulting KFT ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "KMG SUPPLY COMPANY": {'week_days': {},'plan': 0, 'prognoz': 0},
            "METALLOINVEST TRADING AG": {'week_days': {},'plan': 0, 'prognoz': 0},
            "PartnerInterFreight (ПартнэрИнтэрФрэйт)  ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "TRANSPORT TECHNOLOGIES GP OOO": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АзияТрейдЛогистик": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АйЭмТи": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АЛОРАН ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Альфа-транс ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АльянсИнерт": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АНГСТРЕМ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АНТРАЦИТ ТРЕЙД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АРДЕН ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Арселор ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АСР-Групп ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Атлант": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АТР ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БАЗАЛТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Белкоммерц": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БПТранспорт ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БТЛЦ ГП": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БТС-ЛОГИСТИКА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БУЛАТОВСКИЙ БАЗАЛЬТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БЦК_Мин": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВЕСКО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Виста": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВМЗ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Волжская ладья": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВОСТОК ГК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВС ЛОГИСТИК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВТК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВЭЙ-ГРУПП.РФ ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Геда": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГК ВАГОНСЕРВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГНК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГПН": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГПТ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГРУЗОВАЯ КОМПАНИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Дар Транс Логистик": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ДЖЕТТА СТРОЙ ЛТД ТОО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ДЛСГРУПП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ДНК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ДРУЖКОВСКОЕ РУДОУПРАВЛЕНИЕ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ДублТранс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Евро Лоджистик Трейд": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЕВРОСИБ СПБ-ТС АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Евротрансрейл": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Еврощебень": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Зеленый клин ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЗУР ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИКТ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИНТЕР ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИНТЕРЛОГИСТИКС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Исткомтранс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КазТрейдЛогистик": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КАЛУГАВАГОНСЕРВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КАМЕЛОТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Каракан Транс Сервис (КТС)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Карбо Логистикс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КАРБОН УГОЛЬ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Карботрейд": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КАРБО-ТРЕЙД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Касол": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КаспийскГаз": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КВАТРО ПЛ ЛОГИСТИКА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КВУ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КЕРАМРЕСУРС": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КИТ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Клиши": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КОКС": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Лавский карьер": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЛВ Транс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЛЕБЕДИНСКИЙ ГОК АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Логистика-МП": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Лотра": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЛСР-Базовые": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МАГИСТРАЛЬ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Магнезит ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Майкубен-Вест ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МГОК ИМ. А.В. ВАРИЧЕВА АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Мечел/ТЦФТО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МЕЧЕЛ-ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МИКТРансАзия": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МИШ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МИШ-Евразия": {'week_days': {},'plan': 0, 'prognoz': 0},
            "М-Карго ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "М-ПЛАСТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МТК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МТЛК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НВК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НКСИ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НК-СИТИ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Новая Горная Компания": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НПК АО (Новая перевозочная Компания)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НСТ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НТК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НТК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ОГДК АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ОТИ Логистика": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ОЭМК ИМ. А.А. УГАРОВА АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПГК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Первая Мультимодальная Компания": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПК ФЕРРУМ ТРЕЙД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПМХ-ТРАНСПОРТ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Пореченский карьер": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ППО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Промагротранс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРОМУГОЛЬСЕРВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПУТ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РВК ООО (ИНН 5406995691)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Регион": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЕЙЛ ЭКСПРЕСС": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РемиНико": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЖД-Л": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РН-Транс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Росальянс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РПК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РС-транс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РТЛ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РТС": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РУСНЕРУДТРЕЙД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РУС-ОЙЛЭКС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Русский уголь": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РУТ ЛОГИСТИК ТК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Рэд Холт": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЭЙЛ ПРО ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЭУ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Сарматт": {'week_days': {},'plan': 0, 'prognoz': 0},
            "САТКИНСКАЯ НЕРУДНАЯ КОМПАНИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Саянпромтранс ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СГК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СДС-Уголь": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Серебрянский Завод": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СЗТК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СибАнтрацит": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СИБТРЕЙД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Сибуглемет": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СИГМА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СКС ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СОКАР": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТЕЛЛА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Строительная Торговая Компания": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Строй-Древ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Стройсервис": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТРОЙТЕХНО-УРАЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТ-ЮНИТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СУЭК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СЧПЗ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СЭТ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Талдинская Трейдинговая Компания ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТВ ОО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТГК АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТД РКУ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТД ТЛС ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Тенгри Транс TOO": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Терминал Морской Рыбный Порт ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТК ЕВРАЗХОЛДИНГ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТК Славия": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТЛК Старый ключ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТНП ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТНТ ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Топкарготранс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТОРГОВЫЙ ДОМ ТИАН ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНЗИТ СЕРВИС": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Транс Инвест ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНСГРУЗ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТрансКом ТОО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Транслизинг": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНСМЕТКОКС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Трансрада": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Трансрапид": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНССИБУРАЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНСХИМРЕСУРС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТрасКом": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Т-Сервис": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТСТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТТ ЛОГИСТИКА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТТК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТУЛАЧЕРМЕТ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТЭК Ультима": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УГМК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Уголь-Транс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УГПХ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УК Разрез Степной АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УК ЮЖНЫЙ АЛЬЯНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УКСК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Урал-Логистика": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УРАЛЬСКАЯ СТАЛЬ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УРАЛЬСКАЯ ТРАНСПОРТНАЯ КОМПАНИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ФЕНИКС ООО НК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Филиал Грузовая служба-Запад в г.Смоленске ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ФЛК_ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Центр комир ТОО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЦОФБ ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЧЭМК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ШАХТА ГРАМОТЕИНСКАЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ШАХТОУПРАВЛЕНИЕ ОБУХОВСКАЯ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭкспортТрейдинг": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭЛСИ ЛОГИСТИКА СИБИРЬ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭНЕРГО-ТРЭЙД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭНЭРГО-ТРЭЙД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Юг-Транс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЮДК-ГРУПП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЮЖНО-СИБИРСКИЙ АО ТД": {'week_days': {},'plan': 0, 'prognoz': 0},
            "0": {'week_days': {},'plan': 0, 'prognoz': 0},
            "CEMEX ENGINEERING": {'week_days': {},'plan': 0, 'prognoz': 0}
        }
    },
    "Аренда (поступления)": {
        'week_days': {},
        'plan': 0,
        'prognoz': 0,

        'companies': {
            "CEMEX ENGINEERING": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВЕКТОР РЕЙЛ АКТИВ ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВЕЛЕС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВЕЛЕС ТК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ДельтаХолдинг": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ДНК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Дулаанвинг": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИНТЕР ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Казцинк": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ММСК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НЕФТЕТРАНСПОРТ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПГК ПАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Плановые_поступления_Аренда": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПМХ ТРАНСПОРТ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПромСтройМаш": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РТА": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РТС-ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Рутгерс Севертар ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СОФИТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Союзметаллсервис ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТЕЛЛА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТС-Групп": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СЭТ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТЛК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТЛС": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТНТ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Тонж-логистик": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТОО «QUANTUM CHEMICAL» ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Эдельвейс Транс ТОО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭЙ_СИ_РЕЙЛ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Энергоресурсы": {'week_days': {},'plan': 0, 'prognoz': 0},
        }
    },
    "Прочие возмещения": {
        'week_days': {},
        'plan': 0,
        'prognoz': 0,

        'companies': {
            "SIA TRANSWEST": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АБЦ-РЕЙЛ ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АЙ ЭМ ТИ ЭКСПРЕСС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АСР-Групп ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АТЛАНТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БАЗАЛТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БАЛТТРАНССЕРВИС ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БВРЗ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БРАНСВИК РЕЙЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВЕКТОР РЕЙЛ АКТИВ ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВЕРАТЭК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Виста Фрэйт": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВымпелКом": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГАЗПРОМТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГК НОВОТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГРУЗОВАЯ КОМПАНИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Дельта-Трейд ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ДЛСГРУПП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Евро Лоджистик Трейд": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЖЕЛЕЗНОДОРОЖНЫЕ АКТИВЫ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИВК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИКК АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИТЕК ЛОГИСТИК АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Казцинк-ТемирТранс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Кантский Цементный завод": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Карбо Логистикс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КАРБОН УГОЛЬ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КВРЗ НОВОТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КИБЕР ЭРА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КОВАЛЕВ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КОНЦЕРН ДЛС АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КОНЦЕРН ДЛС АО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МАКСИМА ЛОГИСТИК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МЕЖРАЙОННАЯ ИФНС РОССИИ № 22 ПО МОСКОВСКОЙ ОБЛАСТИ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МЕРСЕДЕС-БЕНЦ ФАЙНЕНШЛ СЕРВИСЕС РУС ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МОДУМ-ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НВК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НЕФТЕТРАНССЕРВИС": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НКС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ННК-ТРАНС АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ОЙЛТРАНС ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ОКТЯБРЬСКАЯ ДИРЕКЦИЯ ИНФРАСТРУКТУРЫ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПАНАВТО ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПГК АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Первая Мульмодальная Компания ТОО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Плановые_поступл. (возмещения)_Аренда": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Премиум Ойл Транс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Промагротранс ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРОМИНВЕСТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Промстроймаш": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРОМТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРОМТРАНССЕРВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРОМУГОЛЬТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЕИЛГО ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЕЙЛ СЕРВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЕСУРС ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РефТранс ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЖД ОАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РН-ТРАНС АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РТА-Транс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РТК ГРУПП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РТС-ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РУС-ОЙЛЭКС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СЕВЕРОТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СЕТКО ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СК ПАРИ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СЛК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СОВФРАХТ ПАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СОГАЗ_А": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СОФИТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТРОЙ-ДРЕВ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТС": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СЭТ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТГК АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТНТ ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Тонж логистик": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТОО РТС Operator": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТрансЛизинг ООО (ИНН 3663058836)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТрансЛизингСервис АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНСМЕТКОКС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНСОЙЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНСПОРТНО-ЛОГИСТИЧЕСКАЯ КОМПАНИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНС-ТЭК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТСТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТТ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТТК-ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТФМ-ГАРАНТ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТЭК ТРАНСЛИН": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТЭК-ННЭ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УГД по Есильскому району": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УП Витебское отделение Бел.ж.д. Новополоцкая промывочно-пропарочная станция": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УРАЛ ЛОГИСТИКА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ФГК АО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Филиал Грузовая служба-Запад в г.Смоленске ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ФОРТС": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЦИФРОВАЯ ЛОГИСТИКА": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭЙ-СИ-РЕЙЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭКОЛАЙН ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Элемент": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭНЕРГОРЕСУРСЫ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭНТУЗИАСТ-С ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЮНИРЕЙЛГРУПП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЮНИТРАНС РЭЙЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
        }
    },
    "Доходы от ремонтов": {
        'week_days': {},
        'plan': 0,
        'prognoz': 0,

        'companies': {
            "AB LTG Cargo Литва": {'week_days': {},'plan': 0, 'prognoz': 0},
            "APS TOO (AsiaPartnerService)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "SIA TRANSWEST": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АБЦ-РЕЙЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АЙ ЭМ ТИ ЭКСПРЕСС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Александров Александр Иванович": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АЛТАЙВАГОН АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АТЛАНТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БВРЗ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БВРП НОВОТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БРАНСВИК РЕЙЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВАГОНДОРМАШ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВЕКТОР РЕЙЛ АКТИВ ООО (бывший Брансвик)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВКМ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВКМ-СЕРВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРЗ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРК - 2 АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРК - 3 АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРП НОВОТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГПТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГРС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ДЕЛОВОЙ ВИЗИТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Джанкой Крымская ж.д.": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЖД-СТРОЙ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИКК АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИТА": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Казцинк-ТемирТранс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КВРЗ НОВОТРАНС ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КТЖ-Грузовые перевозки Казахстан": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МАГИСТРАЛЬ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НАХОДКА": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НВК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НЕВАТРАНССЕРВИС ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Нижнекамскнефтехим ПАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НТК ООО (ИНН 9731013266)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ООО ИВС": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ОРСК-ВАГОНОРЕМОНТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПГК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Петролеум": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Плановый_контрагент": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Премиум Ойл Транс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРОМТРАНССЕРВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРОТЕХ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РАЗВИТИЕ ЖК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЖД ОАО / КДИ ЦДИ (ВЧД)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РТА-Транс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СВР-ЧЕЛЯБИНСК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СЛК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СПЕЦЭНЕРГОТРАНС АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТВМ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТЕХТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТОО PTC Holding": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТОО Камкор вагон": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНЗИТ-Н ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНСПОРТНО-ЛОГИСТИЧЕСКАЯ КОМПАНИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНСРЕСУРС ООО (запчасти)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТЭК-ННЭ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ФЕОКОМ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ФОРТС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭВР ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭЙМАР ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
        }
    },
    "Претензии и судебная работа": {
        'week_days': {},
        'plan': 0,
        'prognoz': 0,

        'companies': {
            "ARAVT GROUP ТОО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Daugavpils Lokomotivju Remonta Rupnica ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "GLP Operator": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Logistic resurs TOO": {'week_days': {},'plan': 0, 'prognoz': 0},
            "PartnerInterFreight (ПартнэрИнтэрФрэйт)  ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "TOO CITITRANS": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АБЦ-РЕЙЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Авион Норд ТОО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АлоисТрансСервис": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АЛОРАН ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АЛТАЙВАГОН АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АльянсИнерт": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АНТРАЦИТ ТРЕЙД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АРДЕН ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АРКЕТГРУПП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АТР ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АУРА ОЙЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БАЗАЛТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БАНК ВТБ (ПАО) ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Барнаульский ВРЗ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БВРЗ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БВРП НОВОТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Белорусская железная дорога ГО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БМП ОАО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БПТРАНСПОРТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БТЛЦ ГП": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БТС-ЛОГИСТИКА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВАГОН АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВАГОНДОРМАШ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВАГОНОРЕМОНТНАЯКОМПАНИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВАГОН-СЕРВИС ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВДБ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВЕКТОР-ДВИЖЕНИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВЕРАТЭК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВКМ Бурея ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВКМ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВКМ-СЕРВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВОСТОК ГК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРЗ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРК - 1 АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРК - 2 АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРК - 3 АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРК Купино ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРП НОВОТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРЦ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВС ЛОГИСТИК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВТК ОРСК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВЫБОР-ТРАНЗИТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГАЗПРОМ НЕФТЬ ПАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГАЗПРОМТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГРИФОН АО НВФ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГРС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГРУЗОВОЙ КОНТИНГЕНТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГСП-ТРЕЙД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ДАЛЬВАГОНОРЕМОНТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Дельта-Трейд ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ДИККОМ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Диптранс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ДЛСГРУПП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ДОЛЕРИТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЕВРОТРАНСРЕЙЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЕВРОХИМ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЖДВ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЖЕЛДОРСЕРВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЗЕЛЕНЫЙ КЛИН ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЗСТК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЗУР ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИКТ_ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИНТЕР ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИНТЭК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Исткомтранс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИФНС № 26": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КАМЕЛОТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КАМЕНСКИЙ ЩЕБЕНОЧНЫЙ ЗАВОД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КАРБОН УГОЛЬ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КАРБО-ТРЕЙД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КАСОЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КАСПИЙГАЗ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КВАТРО ПЛ ЛОГИСТИКА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КВРЗ НОВОТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КВРП НОВОТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КВУ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КОКС ПАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КОЛЕСА РЖД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Копцов Владислав Петрович ИП": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Красноярсккрайуголь АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЛЕРУА-ТРЕЙД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЛЛМЗ-КАМАХ ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЛОГИСТИКА-МП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЛУКОЙЛ Лубринтс Центральная Азия TOO": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МАГИСТРАЛЬ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МАЙКУБЕН-ВЕСТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МГОК АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МЕЧЕЛ-ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МИКТРАНСАЗИЯ ТОО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "М-КАРГО (БЫВШИЙ М-ПЛАСТ)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МТК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НАХОДКА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НБ ТАЙГА ЭНЕРДЖИ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НВК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НЕФТЕТРАНСПОРТ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НИЖНЕКАМСКНЕФТЕХИМ ПАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НКТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НМТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ННК-ТРАНС АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НОВАЯ ГОРНАЯ УК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НОВОРОССИЙСКИЙ МОРСКОЙ ТОРГОВЫЙ ПОРТ ПАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НОВОТРАНС КС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НСТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НТГ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НТК АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НХТК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ОГДК АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ОКСИ ЭНЕРДЖИ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ОТИ ЛОГИСТИКА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ОТЭК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ОТЭКО АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПГК ПАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПМ-РЕСУРС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Поступления по ИЛ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ППО ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ППО-КЛМ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Приазовье ЗАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРОМЖДСЕРВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРОМИНВЕСТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРОМТРАНС-А ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРОМУГОЛЬСЕРВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРОМУГОЛЬТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РАЗРЕЗ СТЕПНОЙ УК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РАФ СЕРВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РВК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЕЙЛ СЕРВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЕСУРС ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЖД ОАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Р-Лайн ТС": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РН-ТРАНС АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РТК ГРУПП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РТС-ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РУС-ОЙЛЭКС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РУСТЭК-МАГИСТРАЛЬ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РУТ ЛОГИСТИК ТК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "САМАРСКИЙ ГИПСОВЫЙ КОМБИНАТ ЗАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "САНТОРИНИ ВИКТОРИ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "САТКИНСКАЯ НЕРУДНАЯ КОМПАНИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "САЯНПРОМТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СВРЗ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СВРК АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СГК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СДС-УГОЛЬ АО ХК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СЕРЕБРЯНСКИЙ ЦЕМЕНТНЫЙ ЗАВОД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СИБТРЕЙД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СКС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СЛК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СОВФРАХТ ПАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СОКАР РУС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СОКАР ЭНЕРГОРЕСУРС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СПЕЦЭНЕРГОТРАНС АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТАЛЬНОЙ ПУТЬ ОМК АО (бывш. ВРК-3)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТАР ГРУПП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТРОЙ-ДРЕВ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТРОЙСЕРВИС АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТС_ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТС-ГРУПП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТ-ЮНИТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СЧПЗ ТРЕЙД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТАГАНРОГСКИЙ МОРСКОЙ ТОРГОВЫЙ ПОРТ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТАЙГА ЭНЕРДЖИ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТАКТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТАНДЕМ-ТРАНСГРУПП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТАТНЕФТЕХИМ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Татнефть-Транс ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТВ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТВМ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТК ЕВРАЗХОЛДИНГ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТК РУТ ЛОГИСТИК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТОК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТОМСКАЯ ТОПЛИВНАЯ КОМПАНИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Топкарготранс ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТОРГОВЫЙ ДОМ РКУ (ТИАН)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНС ИНВЕСТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТрансЛизинг ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНСМЕТКОКС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНСПОРТНАЯ КОМПАНИЯ СЛАВИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНСПОРТНО-ЛОГИСТИЧЕСКАЯ КОМПАНИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНСРАДА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНСРЕСУРС ООО (запчасти)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНССТРОЙ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНС-ТЭК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНСХИМРЕСУРС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРЕЙД ОЙЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Т-СЕРВИС ЛОГИСТИКС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТСУ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТТ ЛОГИСТИКА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТЮМЕНЬ ЖД СЕРВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УВК ЗАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УГМК-ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УГОЛЬ-ТРАНС АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УГПХ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УГШК ЗАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УК Разрез Степной АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УК ЮЖНЫЙ АЛЬЯНС  ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УРАЛ ЛОГИСТИКА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УРАЛВАГОНЗАВОД НПК ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УРАЛХИМ-ТРАНС ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УРАЛЬСКАЯ СТАЛЬ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УФК по Алтайскому краю(МЕЖРАЙОННАЯ ИФНС РОССИИ № 14 ПО АЛТАЙСКОМУ КРАЮ)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ФЛК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Центр Комир KZ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЦИФРОВАЯ ЛОГИСТИКА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЦОФ БЕРЁЗОВСКАЯ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЧАСТНОЕ ПРЕДПРИЯТИЕ ДУБЛТРАНС": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЧЕЛЯБОБЛТОППРОМ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЧЭМК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ШАХТА ГРАМОТЕИНСКАЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭЛЕКТРОКЕРАМИКА АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭЛЕМЕНТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭНЕРГОРЕСУРСЫ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭНЭРГО-ТРЭЙД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЮВК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЮДК-ГРУПП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЮЖНО-СИБИРСКИЙ АО ТД": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЮНИТРАНС РЭЙЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЮСКОВ ЕВГЕНИЙ АЛЕКСАНДРОВИЧ ИП": {'week_days': {},'plan': 0, 'prognoz': 0},
        }
    },
    "Поступления от УЭ (лом, склад)": {
        'week_days': {},
        'plan': 0,
        'prognoz': 0,

        "companies": {
            "SIA TRANSWEST": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АБЦ-РЕЙЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АВТОРИТЕТ ТЭК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Александров Александр Иванович": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АМАЛЬ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АМ-ТЕХ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БВРЗ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БВРП НОВОТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВАГОНДОРМАШ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВАГОНОРЕМОНТНАЯКОМПАНИЯ ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВЕГА-М ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВЕКТОР РЕЙЛ АКТИВ ООО (бывший Брансвик)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВИБ-ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВКМ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВМТ ООО (ВторМетТранс)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Возрождение ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВолгаМеталл ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРЗ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРК - 2 АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРК СИБИРЬ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРК-ГАРАНТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРК-ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРП НОВОТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВТЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВТОРИЧНЫЕ РЕСУРСЫ КАРЕЛИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВТОРМЕТАЛЛ ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВТОРСНАБ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГАРМЕТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГРАНЬ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГРС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ДОРБСТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ДЭМЗ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Енисейвагонтранс ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЖД 1520 ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЖД ПРОФИ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЖД ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЖД-ГАРАНТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЖДК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИТЕК ЛОГИСТИК АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КазВторМет ТОО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КВРЗ НОВОТРАНС ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КОНТЕЙНЕРНЫЙ СЕРВИС ТК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КОРУНД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КРАЙМЕТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МАГИСТРАЛЬ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МЕЛОНКОМ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МИР ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МОНОЛИТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "М-СТАЛЬ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НАХОДКА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НВК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НЕВАТРАНССЕРВИС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НикоМет ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НОРДМЕТАЛЛ ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НТ-СЕРВИС ГАЙ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Партнер ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПГК ПАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Плановый_контрагент": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПО ТАТВТОРЧЕРМЕТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРЕССЛОМ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРИМОРВТОРСЫРЬЕ-ЯКУТИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРОМВЕСТ ООО ПО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРОМСТРОЙМАШ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРОМТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПСК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РАЗВИТИЕ ЖК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РВК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЖД ОАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Сарбаз Ордасы TOO": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СД ГРУПП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СЕРВИСВАГОН ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СибПромРесурс": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СМК_ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СОВФРАХТ ПАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТАЛЬНОЙ ПУТЬ ОМК АО (бывш. ВРК-3)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТАТВТОРЧЕРМЕТ ПО ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТЕХНОЛОГИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТЕХСНАБ МК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТЕХТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТК АРГО ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТМК МЕТА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТМК Ярцево": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТрансЛизинг АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Транслом ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНСМЕТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНСРЕСУРС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТЭК ТРАНСЛИН": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТЮМЕНСКАЯ СЕРВИСНАЯ ЖД КОМПАНИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УГОЛЬ-ТРАНС АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УРАЛТРЕЙД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ФЕРРУМ ЦЕНТР ВАГОННОГО СЕРВИСА  ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ХИДАН ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЦРВ АБДУЛИНО ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЧЕРМЕТ-ВОЛЖСКИЙ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭЙМАР ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭКОРЕКС-МЕТАЛЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Эльбрус ООО": {'week_days': {},'plan': 0, 'prognoz': 0}
        }
    },
    "Прочие поступления": {
        'week_days': {},
        'plan': 0,
        'prognoz': 0,

        'companies': {
            "AB LTG CARGO": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Daugavpils Lokomotivju Remonta Rupnica ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "KZT Express": {'week_days': {},'plan': 0, 'prognoz': 0},
            "NGSA LLC/ОсОО НГСА": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Rail Trans Invest": {'week_days': {},'plan': 0, 'prognoz': 0},
            "TRANSPORT TECHNOLOGIES GP OOO": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АВТОБАД ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АВТОСТОП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АЛЬФАСТРАХОВАНИЕ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "АЛЬЯНС ОЙЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БВРЗ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БВРП НОВОТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БЕЛОРУССКОЕ МОРСКОЕ ПАРОХОДСТВО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "БСК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Бусов Виталий Сергеевич": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВАГОН-СЕРВИС ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВИДЕОГЛАЗ ЦЕНТР ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВКМ ООО (Находка)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРК - 1 АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРК - 2 АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРК-ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВРП НОВОТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВС АВТО ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ВСЕСВЕТОДИОДЫ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГПС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ГРС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ДАЙМЭКС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Дедюля А. П. ИП": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ДЕЛОВОЙ ВИЗИТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ДЕЛЬТАХОЛДИНГ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Евразия ТС": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЕВРОКАЗ-ТРАНС ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЖД-ГАРАНТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИВС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИЗДАТЕЛЬСКИЙ ДОМ ГУДОК ОАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Интернет Решения ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИНТЕРФАКС АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИТВ ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ИФНС РОССИИ ПО ЦЕНТРАЛЬНОМУ РАЙОНУ Г. ЧЕЛЯБИНСКА": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КАРЬЕР КОККОМЯКИ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КЖД_ФГП": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КИБЕР ЭРА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КОНТУР СКБ ПФ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "КТЖ-Грузовые перевозки Казахстан": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Кузеванов Дмитрий Александрович ИП": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Ласкина Светлана Валентиновна": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МАКС-ИВЕНТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "МИ ФНС России по управлению долгом": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Микаелян Завен Сережаевич": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НВК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НИЖНЕКАМСКНЕФТЕХИМ ПАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НКСИ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НОРДМЕТАЛЛ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "НТК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ОЖХ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Отдел судебных приставов по Менделеевскому и Тукаевскому районам УФССП России по Республике Татарста ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ОТЕЛЬСТРОЙ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ОТКРЫТЫЙ ЛИЗИНГ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПГК ПАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПЕРВАЯ ОФИСНАЯ КОМПАНИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Петролеум - ремонты": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПЖТ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПОДАРОЧНАЯ КОМПАНИЯ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ППГХО ПАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРОМТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПРОТЕХ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ПТЛК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РАЙФФАЙЗЕНБАНК АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЕДАКЦИЯ ЖУРНАЛА РЖД-ПАРТНЕР ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЕМСПЕЦТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЕСО-Гарантия": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЕСУРС ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЖД ОАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РТИТС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РТК ГРУПП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РТС (РегионТрансСервис)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РТС-ТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "РЭД БОКС КО. ЗАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Сбербанк России": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СВР-ЧЕЛЯБИНСК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СГ-ТРАНС АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СДСП ООО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СЕВЕРОТРАНС ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Серчинформ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СЛК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СОГАЗ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТАЛЬНОЙ ПУТЬ ОМК АО (бывш. ВРК-3)": {'week_days': {},'plan': 0, 'prognoz': 0},
            "СТОЛ ТУМБА КРЕСЛО ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Т4К ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТАЛТЭК ТРАНС АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТАМР Транс Сервис": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТАТНЕФТЬ ИМ. В.Д. ШАШИНА ПАО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Тертычный Сергей Николаевич ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТЕХНОЛОГИИ ПРАВА ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТЕХСНАБ МК ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Тинькофф Банк АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Титова Татьяна Олеговна": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТОО РТС Holding": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТОО РТС Operator": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТПП РОССИИ, ТПП РФ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНСЛИЗИНГ ООО НОВЫЙ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТРАНСОУШЕН РУ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ТЮМЕНЬ ВАГОННОЕ ДЕПО ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "УГД по Есильскому району": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ФГУП_КЖ": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Филиал Грузовая служба-Запад в г.Смоленске ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ФИНАНСБИЗНЕСГРУПП ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "Хабибуллина Л.Н.": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ХАРАКТЕРОФФ ООО ТК": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЦРПИ АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ШЕЛКО ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭКЗА АО": {'week_days': {},'plan': 0, 'prognoz': 0},
            "ЭКСПЕРТ-БЮРО ПОЛИГРАФ ООО": {'week_days': {},'plan': 0, 'prognoz': 0},
        }
    },
    "Возмещение НДС": {'week_days': {},'plan': 0, 'prognoz': 0},

}

export default {
    fin_counerpartie
}