<template>
  <div>
    <!-- <h1>В разработке</h1> -->
    <component :is="state.activeComponent"></component>
  </div>
</template>

<script>
  import NewApplicationModule from "../module/NewApplicationModule.vue";
  import NewsModule from "../module/NewsModule.vue";
  import BirthdayModule from "../module/BirthdayModule.vue";
  import StaffListModule from "../module/StaffListModule.vue";
  import MyStaffModule from "../module/MyStaffModule.vue";
  import ReportsModule from "../module/ReportsModule.vue";
  import MyApplicationsModule from "../module/MyApplicationsModule.vue";
  import MyProfileModule from "../module/MyProfileModule.vue";
  import NewEmployeePage from "../pages/NewEmployeePage.vue";
  export default {
    components: { NewApplicationModule, NewsModule, BirthdayModule, StaffListModule, MyStaffModule, ReportsModule, MyApplicationsModule, MyProfileModule, NewEmployeePage },
    inject: ["state"], // Получаем реактивное состояние от родителя
  };
</script>
