<template>
  <div style="display: flex; justify-content: center; align-items: center">
    <div id="id01" class="modal" v-if="showAuthForm">
      <div class="modal_container animate" @animationend="onAnimationEnd">
        <div class="logo_block">
          <img src="../assets/photo.png" alt="логотип компании" class="logo_img" :class="{ 'fade-in': showImage }" />
        </div>
        <div class="form_block">
          <h2>SpruTT</h2>
          <p>
            Добро пожаловать! Войдите в свою учетную запись,
            <br />
            чтобы пользоваться корпоративным порталом
          </p>
          <div class="container">
            <div class="email">
              <b-icon icon="envelope-fill" font-scale="1.2"></b-icon>
              <input type="text" placeholder="i.ivanov@tehtrans.com" v-model="email" v-on:keyup="handleInputOnKeyup" />
            </div>

            <div class="password">
              <b-icon icon="lock-fill" font-scale="1.2"></b-icon>
              <input type="password" placeholder="Введите пароль" v-model.trim="password" @keyup.enter="submitEntry()" id="password-input" />
            </div>
          </div>

          <label class="show-password">
            <input type="checkbox" class="password-checkbox" @change="togglePasswordVisibility" />
            <p>Показать пароль</p>
          </label>
          <button @click="submitEntry()" :disabled="isSending">
            <span v-show="!isSending">Войти</span>
            <b-icon v-show="isSending" icon="three-dots" animation="cylon" font-scale="1.5"></b-icon>
          </button>
        </div>
      </div>
    </div>

    <ScreensaverVue :watch_hello="watch_hello" v-if="backgr" :firstname="first_name" :lastname="last_name" />
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { actionTypes } from "@/store/modules/auth";
  import ScreensaverVue from "../helpers/Screensaver.vue";
  export default {
    name: "Authorization",
    components: { ScreensaverVue },

    data() {
      return {
        showImage: false,
        email: "",
        password: "",
        watch_hello: false,
        backgr: true,
        showAuthForm: true,
        CheckLocalStorage: localStorage.getItem("accessToken"),
        first_name: "",
        last_name: "",
        isSending: false,
      };
    },
    methods: {
      onAnimationEnd() {
        this.showImage = true; // Активируем появление картинки после завершения анимации
      },
      async submitEntry() {
        if (this.email.length > 0) {
          this.isSending = true;
          this.$store
            .dispatch(actionTypes.login, {
              email: this.email,
              password: this.password,
            })
            .then((user) => {
              if (this.$store.state.auth.isLoggedIn) {
                this.first_name = this.$store.state.auth.user.user?.first_name;
                this.last_name = this.$store.state.auth.user.user?.last_name;
                this.$toast.success(`Вы успешно авторизованы `, {
                  timeout: 2500,
                });
                this.isSending = false;
                this.showAuthForm = false;
              }
            })
            .then((res) => {
              return new Promise((resolve) => {
                this.watch_hello = true;
                setTimeout(() => {
                  this.backgr = false;
                  resolve();
                }, 2500);
              })
              .then(() => {
                window.location.href = "/main";
                // location.reload()
              });
            })
            .catch((error) => {
              this.isSending = false;
              this.$toast.error(`Ошибка авторизации \n${error.response.data}`, {
                timeout: 4000,
              });
            });
        } else {
          this.isSending = false;
          this.$toast.error(`Ошибка авторизации \nВведите Email`, {
            timeout: 4000,
          });
        }

        return false;
      },
      handleInputOnKeyup() {
        this.email = this.email.toLowerCase();
      },
      togglePasswordVisibility() {
        const passwordInput = document.getElementById("password-input");
        passwordInput.type = passwordInput.type === "password" ? "text" : "password";
      },
    },
    computed: {
      ...mapState({
        user: (state) => state.auth.user,
        authStatus: (state) => state.auth.isLoggedIn,
      }),

      // authStatus: function(){
      //     if(this.user.token) return true
      //     return false
      // }
    },
    watch: {
      CheckLocalStorage() {
        if (this.CheckLocalStorage === "") {
          this.showAuthForm = true;
        } else {
          this.showAuthForm = false;
        }
      },

      authStatus: function (newVal, oldVal) {
        if (oldVal === false && newVal === true) {
          setTimeout(() => {
            this.showAuthForm = false;
          }, 1500);
        }
      },
    },
    mounted() {
      if (this.authStatus) this.showAuthForm = false;

    },
  };
</script>

<style lang="scss" scoped>
  .modal_container {
    position: relative; /* Для анимации */
    background: #fefefe;
    width: 40%;
    min-height: 45vh;
    margin: 5% auto;
    display: grid;
    grid-template-columns: 0.4fr 1fr;
    border-radius: 10px;
    overflow: hidden;
    .logo_block {
      background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(../assets/logo_1.jpg) no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      .logo_img {
        height: 8%;
        opacity: 0;
        transition: opacity 1s ease; /* Плавное появление в течение 1 секунды */
      }

      .logo_img.fade-in {
        opacity: 1; /* Показываем изображение */
      }
    }
    .form_block {
      width: 90%;
      margin: 5% auto;
      h2 {
        text-align: left;
      }
      p {
        line-height: 155%;
        margin-bottom: 5px;
        font-size: 14px;
        color: #000;
        opacity: 0.65;
        font-weight: 400;
      }
      input {
        border: none;
        border-bottom: 1px solid lightgrey;
        width: 90%;

        transition: border-bottom 0.3s ease-in-out;
        &:focus {
          outline: none;
          border-bottom: 1px solid #264722;
        }
        &::placeholder {
          text-align: left;
        }
        .email,
        .password {
          display: flex;

        }
      }
    }
  }
  .show-password {
    display: flex;
    width: 20%;
    white-space: nowrap;

    input[type="checkbox"] {
      margin-right: 8px; // Отступ между чекбоксом и текстом
      margin-left: 15px;
    }
  }
  .mainModalProgressBar {
    background: rgb(85, 85, 85, 0.7);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  input[type="text"],
  input[type="password"] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-bottom: 1px solid #000;
    transition: border-bottom 0.5s ease-in-out;
    &:focus {
      outline: none !important;
      text-decoration: none !important;
    }
  }

  button {
    background-color: #264722;
    color: white;
    padding: 5px 20px;
    margin: 6% 0 0 0;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 10px;
  }

  button:hover {
    background-color: #34632e;
  }

  .container {
    padding: 16px;
    display: flex;
    flex-direction: column;
  }

  span.psw {
    float: right;
    padding-top: 16px;
  }

  .modal {
    display: block;
    position: fixed;
    z-index: 99999999999999999999999999999999 !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: 60px;
  }

  .modal-content {
    background-color: #fefefe;
    margin: 5% auto 15% auto;
    border: 1px solid #888;
    width: 40%;
  }

  .animate {
    animation: animatezoom 1s ease;
    animation-fill-mode: forwards; /* Сохраняем конечное положение после анимации */
  }

  @keyframes animatezoom {
    from {
      transform: translateY(-50%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  h2 {
    font-family: "Arial Black", sans-serif;
  }
  @media screen and (max-width: 300px) {
    span.psw {
      display: block;
      float: none;
    }
  }

  @media screen and (max-width: 1620px) {
    .modal_container {
      width: 50%;
    }
  }
  @media screen and (max-width: 600px) {
    .modal-content .modal {
      width: 90vw !important;
    }
  }

  @media screen and (max-width: 900px) {
    .modal_container {
      grid-template-columns: 1fr;
      width: 90vw;
      height: auto;
      .logo_block {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 0px;
        .logo_img {
          height: 50%;
        }
      }
    }
    p {
      text-wrap: balance;
    }

    .show-password {
      p {
        white-space: nowrap;
      }
      width: 30%;
    }
  }
</style>
