<script>
  export default {
    data() {
      return {};
    },
    mounted() {},
    methods: {
      async getData() {
        try {
          let response = await fetch("https://1c/v83_hrm_tt/hs/employee/condition", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer 0JDQtNC80LjQvdC40YHRgtGA0LDRgtC+0YA6WnZvMzE0MTU5",
            },
          });
          let data = response;
          console.log(data, "123");
        } catch (error) {
          console.log(error);
        }
      },
    },
  };
</script>
<template>
  <div>
    <button @click="getData">click</button>
  </div>
</template>
