<template>
  <div>
    <b-tabs content-class="mt-3" justified>
      <b-tab title="БДР" active><lkBDR></lkBDR></b-tab>
      <b-tab title="БРОС вагоны"><lkBROC></lkBROC></b-tab>
      <b-tab title="KPI"><lkKPI></lkKPI></b-tab>
    </b-tabs>
  </div>
</template>

<style scoped>
</style>


<script>
import lkBDR from "@/components/Table/lk/lk-reports.vue/lk-report-bdr.vue";
import lkBROC from "@/components/Table/lk/lk-reports.vue/lk-report-broc.vue";
import lkKPI from "@/components/Table/lk/lk-reports.vue/lk-report-kpi.vue";

export default {
  data() {
    return {
        
    };
  },
  components: { lkBDR, lkBROC, lkKPI },
};
</script>