<template>
  <div>
    <Loader :loader="loader">
      <div v-if="total_objects > 0" style="display: flex; gap: 2%; justify-content: space-around; align-items: center; padding-top: 5%">
        <p>Всего записей: {{ total_objects }}</p>
        <p>Обработано записей: {{ processed_objects }}</p>
      </div>
    </Loader>
    <div class="air_block" style="width: 98vw; margin: 1% 0 0 0">
      <modal @startStopLoader="startStopLoader" :title="titleInsuredCase" :insuredCase="insuredCase" :DataForModal="DataForModal" />
      <!-- <ModalCreateNewInsured :title="is_insurances_cases_insured" :text="text" @startStopLoader="startStopLoader"/> -->
      <div class="air_block_header">
        <h4>Реестр застрахованных вагонов</h4>
      </div>
      <hr />
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Застрахованные вагоны" active>
            <b-card-text style="margin-left: -5%; margin-top: -2%">
              <div>
                <br />
                <br />
                <h4 class="air_block_header" v-show="getInsuredWagonsData.length > 0">
                  <b-spinner :variant="'secondary'" style="width: 1.5rem; height: 1.5rem" v-show="is_save_row" />
                  &nbsp;Застрахованные вагоны
                </h4>

                <div>
                  <b-button-toolbar>
                    <b-button-group>
                      <b-button size="sm" class="mb-2 border rounded p-2 d-flex" style="min-width: 150px !important">
                        <b-icon icon="cloud-download" aria-hidden="true" focusable="false"></b-icon>
                        <PregisterIsuredwagonsSearch
                          ref="childComponent"
                          @getInsuredWagons="getInsuredWagons"
                          @clearTableData="clearTableData"
                          @getOwnWagonsCompare="getOwnWagonsCompare"
                          style="margin-left: 5px !important"
                        />
                      </b-button>
                      <b-button size="sm" class="mb-2 border rounded p-2" @click="openModal()">
                        <b-icon icon="file-earmark" aria-hidden="true" focusable="false"></b-icon>
                        Внести данные
                      </b-button>
                      <b-button size="sm" class="mb-2 border rounded p-2" @click="DownloadExcel('test')">
                        <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
                        Выгрузить в Excel
                      </b-button>
                      <b-button size="sm" class="mb-2 border rounded p-2" style="background: #264722" @click="saveData()">
                        <b-icon icon="check-all" aria-hidden="true" focusable="false"></b-icon>
                        Сохранить изменения
                      </b-button>
                    </b-button-group>
                  </b-button-toolbar>
                  <PregisterInsuredWagonsAddColumnVue @add_column_el="addNewObjectInColumns" style="width: 34%" v-if="getInsuredWagonsData.length > 1" />
                </div>

                <span class="description-text">Всего записей {{ getInsuredWagonsData.length ?? 0 }}</span>
                <br />
                <br />
                <div style="display: flex; justify-content: space-between"> 
                  <label for="" style="color: grey">
                    Для множественного поиска вагонов
                    <br />
                    <input v-model="wagonFilter" @input="applyCustomWagonFilter" placeholder="Введите номера вагонов через пробел" class="searchBlock" />
                  </label>
                  <div style="display: flex; flex-direction: column; ">
                    <div class="mt-2">Увеличение: x{{ rangeTable }}</div>
                    <b-form-input id="range-2" v-model="rangeTable" type="range" min="1" max="10" step="0.1"></b-form-input> 
                  </div>
                </div>
                <!--   :manualColumnMove="true" -->
                <hot-table
                  ref="hotTableComponent"
                  :data="getInsuredWagonsData"
                  :rowHeaders="true"
                  :columns="columns"
                  :preventOverflow="'horizontal'"
                  :filters="true"
                  :language="'ru-RU'"
                  :manualColumnResize="true"
                  :autoWrapRow="true"
                  :autoWrapCol="true"
                  :height="sizeTable"
                  :width="'100%'"
                  :fillHandle="true"
                
                  :dropdownMenu="dropdownMenuOptions"
                  :cells="cellConfig"
                  :afterColumnResize="onColumnResize"
                  :afterColumnMove="onColumnMove"
                  :contextMenu="customContextMenu"
                  :nestedRows="true"
                  :licenseKey="'non-commercial-and-evaluation'"
                  style="overscroll-behavior: contain !important"
                ></hot-table>
                <!-- customContextMenu -->
                <br />
                <!-- Перечень незастрахованных вагонов -->
                <OwnWagonsCompareTable
                  :getOwnWagonsCompareData="getOwnWagonsCompareData"
                  :columns="columns_own_wagons"
                  :columns_table_copy="columns_table_copy"
                  @startStopLoader="startStopLoader"
                  @updateFullData="updateFullData"
                  @amountRequests="amountRequests"
                  :dropdownMenuOptions="dropdownMenuOptions"
                  :is_mini_loader="mini_loader"
                />
              </div>
            </b-card-text>
          </b-tab>
          <b-tab title="Страховые случаи">
            <b-card-text>
              <saveAccidientVue @startStopLoader="startStopLoader" />
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { saveAs } from "file-saver";

  import api_directory from "@/api/directory";
  import Loader from "@/components/loader/loader.vue";
  import PregisterIsuredwagonsSearch from "./components/PregisterIsuredwagonsSearch.vue";
  import { HotTable } from "@handsontable/vue";
  import { registerAllModules } from "handsontable/registry";
  import { registerLanguageDictionary, getLanguagesDictionaries, ruRU } from "handsontable/i18n";
  import modal from "./modules/modal.vue";
  registerLanguageDictionary(ruRU);
  registerAllModules();
  // import 'handsontable/dist/handsontable.full.min.css';
  import PregisterInsuredWagonsAddColumnVue from "./components/PregisterInsuredWagonsAddColumn.vue";
  import OwnWagonsCompareTable from "./components/OwnWagonsCompareTable.vue";
  import saveAccidientVue from "./components/saveAccidient.vue";
  import moment from "moment";
  import "moment/locale/ru";
  import XLSX from "xlsx"; // `vue-xlsx` предоставляет объект XLSX

  export default {
    components: {
      Loader,
      PregisterIsuredwagonsSearch,
      PregisterInsuredWagonsAddColumnVue,
      HotTable,
      OwnWagonsCompareTable,
      saveAccidientVue,
      modal,
    },
    data() {
      return {
        total_objects: 0,
        processed_objects: 0,
        rangeTable: 1,
        originalData: [], // исходные данные
        modifiedRows: [], // измененные строки
        wagonFilter: "",
        text: "",
        loader: false,
        mini_loader: false,
        nameClient: [],
        is_save_row: false,
        insuredCase: false,
        titleInsuredCase: "",
        DataForModal: [],
        start_column: [], // не изменяемые данные

        customContextMenu: {
          items: {
            new_case: {
              name: "Новый страховой случай",
              callback: () => this.handleContextMenuClick("new"),
            },

            archived_cases: {
              name: "Архивные страховые случаи",
              callback: () => this.handleContextMenuClick("archived"),
            },
            get_history: {
              name: "История изменений по вагону",
              callback: () => this.handleContextMenuClick("history"),
            },
          },
        },
        columns: [
          {
            title: "Номер вагона",
            data: "wagon_number",
            width: "150px",
            minWidth: "150px",
            readOnly: true,
          },
          {
            title: "Тип вагона",
            data: "wagon_type",
            editor: "select",
            selectOptions: ["ПВ", "ЦС"],
            width: "150px",
          },
          {
            title: "Собст. на момент страхования",
            data: "owner_at_insurance_moment",
            editor: "select",
            selectOptions: ["ДЕЛОВОЙ ВИЗИТ, ООО", 'ООО "ТРАНСПОРТНЫЕ ТЕХНОЛОГИИ"', "OОО TRANSPORT TECHNOLOGIES GP"],
          },
          {
            title: "Страховая компания",
            data: "insurance_company",
            width: "200px",
          },
          { title: "№ договора", data: "agr_number", width: "150px" },
          {
            title: "Дата договора",
            data: "agr_date",
            type: "date",
            width: "150px",
            dateFormat: "DD.MM.YYYY",
            correctFormat: true, // Принудительное применение формата
            allowInvalid: false, // Отклоняет некорректные даты
            datePickerConfig: {
              showWeekNumber: false,
              i18n: {
                previousMonth: "Предыдущий месяц",
                nextMonth: "Следующий месяц",
                months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
                weekdays: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
                weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
              },
              format: "DD.MM.YYYY",
            },
          },
          {
            title: "Дата прекращения действия договора страхования",
            data: "agr_date_end",
            type: "date",
            width: "200px",
            dateFormat: "DD.MM.YYYY",
            correctFormat: true, // Принудительное применение формата
            allowInvalid: false, // Отклоняет некорректные даты
            width: 450,
            datePickerConfig: {
              showWeekNumber: false,
              i18n: {
                previousMonth: "Предыдущий месяц",
                nextMonth: "Следующий месяц",
                months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
                weekdays: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
                weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
              },
              format: "DD.MM.YYYY",
            },
          },
          { title: "Собственник", data: "owner", width: "150px" },
          {
            title: "Страховая сумма, руб",
            data: "insurance_sum",
            type: "numeric",
            numericFormat: { pattern: "0 0.00" },
            width: "200px",
          },
          {
            title: "На балансе",
            data: "on_balance_1c",
            width: "150px",
            editor: "select",
            selectOptions: ["Да", "Нет"],
          },
          {
            title: "Дата постройки",
            data: "build_date",
            dateFormat: "YYYY-MM-DD",
            correctFormat: true,
            width: "150px",
          },
          { title: "Срок службы", data: "lifetime", width: "150px" },
          { title: "Группа СТЖ", data: "group", width: "150px" },
          {
            title: "Собственник СТЖ",
            data: "owner_at_insurance_moment",
            width: "180px",
          },
        ],
        columns_table_copy: [
          { title: "Номер вагона", data: "wagon_number", width: "150px" },
          {
            title: "Тип вагона",
            data: "wagon_type",
            editor: "select",
            selectOptions: ["ПВ", "ЦС"],
            width: "150px",
          },
          {
            title: "Собст. на момент страхования",
            data: "owner_at_insurance_moment",
            editor: "select",
            selectOptions: ["ДЕЛОВОЙ ВИЗИТ, ООО", 'ООО "ТРАНСПОРТНЫЕ ТЕХНОЛОГИИ"', "OОО TRANSPORT TECHNOLOGIES GP"],
          },
          {
            title: "На балансе 1С",
            data: "on_balance_1c",
            width: "150px",
            editor: "select",
            selectOptions: ["Да", "Нет"],
          },
          {
            title: "Страховая компания",
            data: "insurance_company",
            width: "200px",
          },
          { title: "№ договора", data: "agr_number", width: "150px" },
          {
            title: "Дата договора",
            data: "agr_date",
            type: "date",
            width: "150px",
            dateFormat: "DD.MM.YYYY",
            correctFormat: true, // Принудительное применение формата
            allowInvalid: false, // Отклоняет некорректные даты
            datePickerConfig: {
              showWeekNumber: false,
              i18n: {
                previousMonth: "Предыдущий месяц",
                nextMonth: "Следующий месяц",
                months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
                weekdays: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
                weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
              },
              format: "DD.MM.YYYY",
            },
          },
          {
            title: "Дата прекращения действия договора страхования",
            data: "agr_date_end",
            type: "date",
            width: "200px",
            dateFormat: "DD.MM.YYYY",
            correctFormat: true, // Принудительное применение формата
            allowInvalid: false, // Отклоняет некорректные даты
            width: 450,
            datePickerConfig: {
              showWeekNumber: false,
              i18n: {
                previousMonth: "Предыдущий месяц",
                nextMonth: "Следующий месяц",
                months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
                weekdays: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
                weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
              },
              format: "DD.MM.YYYY",
            },
          },
        ],
        // dropdownMenuOptions: ['clear_column', 'filter_by_condition', 'filter_by_value'],
        dropdownMenuOptions: ["filter_by_condition", "filter_action_bar", "filter_by_value", "clear_column"],

        // Для маленькой таблицы незастрахованных вагонов
        columns_own_wagons: [
          { title: "Номер вагона", data: "Номер вагона" },
          {
            title: "Принадлежность СТЖ",
            data: "Принадлежность СТЖ",
            editor: false,
          },
          {
            title: "В управлении компании",
            data: "В управлении компании",
            editor: false,
          },
          { title: "Тип вагона", data: "Тип", editor: false },
          { title: "Примечание", data: "Примечание" },

          // В управлении компании
        ],
        getInsuredWagonsData: [{ title: "Номер вагона", data: "wagon_number" }],
        getOwnWagonsCompareData: [{ title: "Номер вагона", data: "wagon_number" }],
      };
    },
    created() {
      const savedColumnOrder = localStorage.getItem("userColumnOrder");
      const savedColumnSettings = localStorage.getItem("userColumnSettings");

      if (savedColumnOrder) {
        this.columns = JSON.parse(savedColumnOrder);
      }

      if (savedColumnSettings) {
        this.columns = JSON.parse(savedColumnSettings);
      }
    },
    mounted() {
      this.start_column = [...this.columns];
      const hotInstance = this.$refs.hotTableComponent.hotInstance;
      moment.locale("ru");

      hotInstance.addHook("afterChange", this.handleAfterChange);
    },
    computed: {
      ...mapState({
        clients: (state) => state.client.name_client,
      }),
      sizeTable() {
        return this.rangeTable * 10 + 40 + "vh";
      },
    },


    methods: {
      amountRequests(total_objects, requestsProcessed) {
        this.total_objects = total_objects;
        this.processed_objects = requestsProcessed;
      },
      clearTableData() {
        this.getInsuredWagonsData = [];
        this.getOwnWagonsCompareData = [];
      },
      updateFullData() {
        this.getInsuredWagonsData = [];
        this.getOwnWagonsCompareData = [];
        this.$toast.info("Происходит перезагрузка данных с учетом измененных данных в разделе незастрахованных вагонов", { timeout: 5000 });
        this.$refs.childComponent.getRequestToServerData();
      },
      async saveData() {
        try {
          this.loader = true;
          let result = [];
          for (let i in this.modifiedRows) {
            if (this.modifiedRows[i].wagon_number === "#color:#dadada") {
              continue;
            } else {
              result.push(this.modifiedRows[i]);
            }
          }
          result?.forEach((updatedRow) => {
            updatedRow.build_date = updatedRow?.build_date?.split(".").reverse().join("-") ?? null;
            updatedRow.lifetime = updatedRow?.lifetime?.split(".").reverse().join("-") ?? null;
            updatedRow.cutting_date = updatedRow?.cutting_date?.split(".").reverse().join("-") ?? null;
            updatedRow.agr_date = updatedRow?.agr_date?.split(".").reverse().join("-") ?? null;
            updatedRow.agr_date_end = updatedRow?.agr_date_end?.split(".").reverse().join("-") ?? null;
            updatedRow.last_operation_date = updatedRow?.last_operation_date?.split(".").reverse().join("-") ?? null;
            updatedRow.state_change_date = updatedRow?.state_change_date?.split(".").reverse().join("-") ?? null;
            updatedRow.on_balance_1c = updatedRow?.on_balance_1c == "Да" ? true : false;
          });
          let promises = result.map((item) => api_directory.editInsuranceWagons(item.id, item));
          await Promise.all(promises);
          this.modifiedRows = [];
          this.loader = false;
          this.$toast.success("Данные по застрахованным вагонам сохранены,\nДанные истории вагонов изменению не подлежат", { timeout: 5000 });
        } catch (err) {
          console.log(err);
          this.$toast.error(err.message, { timeout: 2000 });
          this.loader = false;
        }
      },
      handleAfterChange(changes, source) {
        if (source === "edit" || source === "CopyPaste.paste" || source === "Autofill.fill") {
          const hotInstance = this.$refs.hotTableComponent.hotInstance;

          changes.forEach(([row, prop, oldValue, newValue]) => {
            if (oldValue !== newValue) {
              const rowData = { ...hotInstance.getSourceDataAtRow(row) }; // Копируем данные строки

              // Найти индекс строки в modifiedRows по id
              const existingRowIndex = this.modifiedRows.findIndex((modifiedRow) => modifiedRow.id === rowData.id);

              if (existingRowIndex !== -1) {
                // Обновляем только измененное поле, сохраняя все предыдущие данные
                this.modifiedRows[existingRowIndex] = {
                  ...this.modifiedRows[existingRowIndex],
                  [prop]: newValue, // Обновляем только измененное поле
                };
              } else {
                // Если запись не найдена, добавляем новую с измененным полем
                const newRowData = {
                  ...rowData, // Копируем все поля строки
                  [prop]: newValue, // Обновляем измененное поле
                };
                this.modifiedRows.push(newRowData);
              }
            }
          });
        }
      },
      onColumnMove(startColumn, endColumn) {
        const updatedColumns = [...this.columns];
        const movedColumn = updatedColumns.splice(startColumn, 1)[0];
        updatedColumns.splice(endColumn, 0, movedColumn);

        this.columns = updatedColumns;

        // Сохраняем новый порядок в LocalStorage
        localStorage.setItem("userColumnOrder", JSON.stringify(this.columns));

        console.log("Updated column order:", this.columns);
      },
      onColumnResize(newSize, columnIndex) {
        this.columns[columnIndex].width = newSize;

        // Сохраняем новые размеры в LocalStorage
        localStorage.setItem("userColumnSettings", JSON.stringify(this.columns));

        console.log("Updated column size:", this.columns);
      },
      async handleContextMenuClick(type) {
        const hotInstance = this.$refs.hotTableComponent.hotInstance;
        const selected = hotInstance.getSelectedLast();

        if (selected) {
          const rowIndex = selected[0];

          // Получаем данные из отфильтрованной таблицы
          const filteredRowData = hotInstance.getDataAtRow(rowIndex);

          // Определяем номер вагона из отфильтрованной строки
          const filteredWagonNumber = filteredRowData ? filteredRowData[0] : null;

          // Находим полные данные из исходного набора данных
          const fullRowData = this.getInsuredWagonsData.find((item) => item.wagon_number === filteredWagonNumber);

          if (type === "history" && fullRowData) {
            this.loader = true;
            try {
              // Запрос на получение истории по номеру вагона
              let historyData = await api_directory.getHistoryByWagon(fullRowData.wagon_number);

              // Проверяем, что historyData.data существует и является массивом
              if (historyData?.data && Array.isArray(historyData.data)) {
                // Добавляем данные истории в поле __children
                historyData.data.forEach((item) => {
                  // Проверяем, что chaged_data существует и является объектом
                  if (item.chaged_data && typeof item.chaged_data === "object") {
                    // Для каждого ключа в объекте item.chaged_data добавляем #color:#dadada к строковым значениям
                    Object.keys(item.chaged_data).forEach((key) => {
                      // Обрабатываем поле wagon_number
                      if (item.chaged_data["wagon_number"]) {
                        item.chaged_data["wagon_number"] = "#color:#dadada";
                      }

                      // Массив полей с датами
                      const dateKeys = ["build_date", "lifetime", "cutting_date", "agr_date", "agr_date_end", "last_operation_date", "state_change_date"];

                      // Обработка дат
                      if (dateKeys.includes(key) && item.chaged_data[key]) {
                        if (typeof item.chaged_data[key] === "string") {
                          item.chaged_data[key] = `${item.chaged_data[key].split("-").reverse().join(".")}#color:#dadada`;
                        }
                      }

                      // Обработка поля on_balance_1c
                      if (key === "on_balance_1c") {
                        item.chaged_data[key] = `${item.chaged_data[key] === true ? "Да" : "Нет"}#color:#dadada`;
                      }

                      // Добавляем цвет к остальным строковым значениям
                      if (typeof item.chaged_data[key] === "string") {
                        item.chaged_data[key] = `${item.chaged_data[key]}#color:#dadada`;
                      }
                    });

                    // Добавляем обработанные данные в __children
                    fullRowData.__children.push({ ...item.chaged_data, child: true });
                  }
                });

                console.log(fullRowData, "Полные данные добавлены в __children");
                this.loader = false;
              } else {
                console.warn("История по вагону пуста или данные отсутствуют.");
                this.$toast.error("История по вагону пуста или данные отсутствуют.");
                this.loader = false;
              }

              // Обновляем таблицу после изменения данных
              this.updateHotTableData();
            } catch (error) {
              console.error("Ошибка при получении данных истории:", error);
              this.loader = false; // Обязательно отключаем загрузку при ошибке
            }
            return;
          }

          // Если найдены полные данные, открываем модальное окно
          if (fullRowData) {
            this.titleInsuredCase = type == "new" ? "Новый страховой случай" : "Архивные страховые случаи";
            this.insuredCase = true;
            this.DataForModal = [fullRowData]; // Передаем полные данные в модальное окно
            this.openModalPage(fullRowData, type);
          } else {
            console.error("Не удалось найти полные данные для выбранного вагона.");
          }
        }
      },

      async openModalPage(item, type) {
        let response;
        let wagonNumber = item.wagon_number;
        if (type === "new") {
          // Открытие нового случая
          this.DataForModal = [{ ...item, status: "Новый" }];
          this.$nextTick(() => this.$bvModal.show("modal-12345678"));
        } else if (type === "archived") {
          // Открытие архивных случаев
          response = await api_directory.getDataInsuranceCases({ wagon_number: wagonNumber, status: "Архивный" });
          this.DataForModal = response.data.data;
          this.$nextTick(() => this.$bvModal.show("modal-12345678"));
          // this.$nextTick(() => this.initializeHotTable());
        }
      },

      initializeHotTable() {
        const hotInstance = this.$refs.modalHotTable?.hotInstance;
        if (hotInstance) {
          hotInstance.loadData(this.tableData);
          hotInstance.updateSettings({ data: this.tableData });
          hotInstance.render();
        } else {
          console.error("hotInstance не определен");
        }
      },
      openModal() {
        this.insuredCase = false;
        this.$nextTick(() => this.$bvModal.show("modal-1234567"));
      },
      cellConfig(row, col) {
        const cellProperties = {};
        return (cellProperties.className = "myCustomClass");
      },

      applyCustomWagonFilter() {
        const wagonNumbers = this.wagonFilter.split(" ").filter((num) => num.trim() !== "");

        // Применяем фильтр по номерам вагонов
        const filteredData = this.getInsuredWagonsData.filter((item) => {
          return wagonNumbers.length === 0 || wagonNumbers.includes(item.wagon_number);
        });

        // Обновляем данные таблицы после фильтрации
        const hotInstance = this.$refs.hotTableComponent.hotInstance;
        hotInstance.loadData(filteredData); // Загружаем отфильтрованные данные
      },

      DownloadExcel() {
        const hotInstance = this.$refs.hotTableComponent.hotInstance;

        // Получаем данные Handsontable в виде массива
        const data = hotInstance.getData();

        // Добавляем заголовки столбцов (опционально)
        const headers = hotInstance.getColHeader();
        const tableData = [headers, ...data];

        // Создаем рабочую книгу и добавляем лист с данными
        const ws = XLSX.utils.aoa_to_sheet(tableData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        // Генерируем файл Excel и сохраняем его
        const excelData = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelData], { type: "application/octet-stream" });
        saveAs(blob, "Застрахованные_вагоны.xlsx");
      },

      startStopLoader(value) {
        this.loader = value;
      },
      // Добавление новых столбцов  в таблицы
      addNewObjectInColumns(data) {
        this.$nextTick(() => {
          const hotInstance = this.$refs.hotTableComponent.hotInstance;
          this.columns = [
            {
              title: "Номер вагона",
              data: "wagon_number",
              width: "150px",
              minWidth: "150px",
              readOnly: true,
            },
            {
              title: "Тип вагона",
              data: "wagon_type",
              editor: "select",
              selectOptions: ["ПВ", "ЦС"],
              width: "150px",
            },
            {
              title: "Собст. на момент страхования",
              data: "owner_at_insurance_moment",
              editor: "select",
              selectOptions: ["ДЕЛОВОЙ ВИЗИТ, ООО", 'ООО "ТРАНСПОРТНЫЕ ТЕХНОЛОГИИ"', "OОО TRANSPORT TECHNOLOGIES GP"],
            },
            {
              title: "Страховая компания",
              data: "insurance_company",
              width: "200px",
            },
            { title: "№ договора", data: "agr_number", width: "150px" },
            {
              title: "Дата договора",
              data: "agr_date",
              type: "date",
              width: "150px",
              dateFormat: "DD.MM.YYYY",
              correctFormat: true, // Принудительное применение формата
              allowInvalid: false, // Отклоняет некорректные даты
              datePickerConfig: {
                showWeekNumber: false,
                i18n: {
                  previousMonth: "Предыдущий месяц",
                  nextMonth: "Следующий месяц",
                  months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
                  weekdays: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
                  weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
                },
                format: "DD.MM.YYYY",
              },
            },
            {
              title: "Дата прекращения действия договора страхования",
              data: "agr_date_end",
              type: "date",
              width: "200px",
              dateFormat: "DD.MM.YYYY",
              correctFormat: true, // Принудительное применение формата
              allowInvalid: false, // Отклоняет некорректные даты
              width: 450,
              datePickerConfig: {
                showWeekNumber: false,
                i18n: {
                  previousMonth: "Предыдущий месяц",
                  nextMonth: "Следующий месяц",
                  months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
                  weekdays: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
                  weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
                },
                format: "DD.MM.YYYY",
              },
            },
            { title: "Собственник", data: "owner", width: "150px" },
            {
              title: "Страховая сумма, руб",
              data: "insurance_sum",
              type: "numeric",
              numericFormat: { pattern: "0 0.00" },
              width: "200px",
            },
            {
              title: "На балансе",
              data: "on_balance_1c",
              width: "150px",
              editor: "select",
              selectOptions: ["Да", "Нет"],
            },
            {
              title: "Дата постройки",
              data: "build_date",
              dateFormat: "YYYY-MM-DD",
              correctFormat: true,
              width: "150px",
            },
            { title: "Срок службы", data: "lifetime", width: "150px" },
            { title: "Группа СТЖ", data: "group", width: "150px" },
            {
              title: "Собственник СТЖ",
              data: "owner_at_insurance_moment",
              width: "180px",
            },
            ...data,
          ];
          hotInstance.updateSettings({ columns: this.columns });
          hotInstance.render();
        });
      },
      isType(value) {
        if (value === "Полувагон") {
          return "ПВ";
        } else if (value === "Цистерна") {
          return "ЦС";
        }
      },
      async getOwnWagonsCompare(data) {
        try {
          const results = data;
          let compareDataMap2 = [];
          for (let i in results) {
            if (results[i]) {
              compareDataMap2.push({
                id: results[i].id,
                "Номер вагона": results[i].wagon_number,
                "В управлении компании": results[i].in_control,
                Тип: results[i].wagon_type,
                "Принадлежность СТЖ": "С",
                Примечание: results[i]?.comment ?? null,
              });
            }
          }

          this.getOwnWagonsCompareData = compareDataMap2;
          this.$nextTick(() => {
            const hotInstance = this.$refs.hotTableComponent.hotInstance;
            hotInstance.loadData(this.getInsuredWagonsData);
            hotInstance.updateSettings({ data: this.getInsuredWagonsData });
            hotInstance.render();
          });
        } catch (err) {
          this.mini_loader = false;
        }
      },

      getInsuredWagons(data) {
        this.getInsuredWagonsData = [...data].sort((a, b) => {
          const agrDateEndA = moment(a.agr_date_end, "DD.MM.YYYY");
          const agrDateEndB = moment(b.agr_date_end, "DD.MM.YYYY");
          const today = moment();

          // Если договор просрочен, он должен идти выше
          if (agrDateEndA.isBefore(today) && !agrDateEndB.isBefore(today)) {
            return -1;
          }
          if (!agrDateEndA.isBefore(today) && agrDateEndB.isBefore(today)) {
            return 1;
          }
          // Если оба договора просрочены или оба действующие, оставляем их на месте
          return 0;
        });
        this.originalData = this.getInsuredWagonsData;
        this.updateHotTableData();
      },

      updateHotTableData() {
        this.$nextTick(() => {
          const hotInstance = this.$refs.hotTableComponent.hotInstance;

          // Создаем новую структуру данных с дочерними элементами
          const modifiedData = this.getInsuredWagonsData.map((row) => {
            const newRow = { ...row }; // Копируем родительскую строку

            if (Array.isArray(row.__children)) {
              // Если есть дочерние элементы, добавляем их с полем readOnly
              newRow.__children = row.__children.map((child) => ({
                ...child,
                readOnly: true, // Устанавливаем readOnly для дочерних элементов
              }));
            }

            return newRow;
          });

          hotInstance.updateSettings({
            data: modifiedData, // Используем новую структуру данных
            afterRenderer: (TD, row, col, prop, value, cellProperties) => {
              const rowData = modifiedData[row]; // Получаем данные строки
              // const agrDateEnd = new Date(rowData.agr_date_end.split(".").reverse().join("-")); // Преобразуем в дату
              // const today = new Date();
              TD.style.fontSize = "12px";

              // Проверяем, если дата окончания договора меньше текущей даты
              // if (agrDateEnd < today) {
              //   TD.style.backgroundColor = "#ffcccc"; // Красный цвет фона для просроченной строки
              // } else {
              //   TD.style.backgroundColor = ""; // Убираем красный фон, если договор не просрочен
              // }

              // Разбираем цвет из данных с помощью синтаксиса "#color:"
              if (value && typeof value === "string" && value.includes("#color:")) {
                const [realValue, color] = value.split("#color:");
                TD.style.backgroundColor = color; // Применяем цвет к ячейке
                TD.innerHTML = realValue; // Отображаем только значение без цвета
              } else {
                TD.innerHTML = value; // Если нет цвета, просто показываем значение
              }

              // Дополнительно окрашиваем первый столбец в желтый цвет
              if (col === 0) {
                TD.style.backgroundColor = "#fbfddd"; // Желтый цвет для первого столбца
              }
            },
          });

          hotInstance.render(); // Рендерим таблицу
        });
      },
    },
  };
</script>

<style scoped>
  .handsontable {
    overscroll-behavior: contain !important;
  }
  .ht_master .wtHolder {
    scroll-behavior: smooth;
  }
  .searchBlock {
    border: 1px solid #e1e1e1;
    width: 350px;
  }
  .air_block {
    width: 90%;
    height: auto;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background: #ffffff;
    box-shadow: -25px 25px 41px #cfcfcf, 25px -25px 41px #ffffff;
    position: relative;
    left: 50%;
    padding: 1%;
    transform: translate(-50%, 0);
  }

  .air_block_header {
    color: #cacaca;
  }
</style>
