export default {
    set(key, data, ttl) {  // ttl = время жизни данных в миллисекундах
      const now = new Date().getTime();  // Получаем текущее время в миллисекундах
      const item = {
        data: data,  // Сохраняем сами данные
        expiry: now + ttl  // Устанавливаем срок истечения данных (текущее время + время жизни кэша)
      };
      localStorage.setItem(key, JSON.stringify(item));  // Сохраняем объект в localStorage
    },
  
    get(key) {
      const itemStr = localStorage.getItem(key);  // Получаем элемент из localStorage
      if (!itemStr) {  // Если элемента нет, возвращаем null
        return null;
      }
  
      const item = JSON.parse(itemStr);  // Парсим строку обратно в объект
      const now = new Date().getTime();  // Текущее время
  
      // Проверяем, не истек ли срок действия кэша
      if (now > item.expiry) {
        localStorage.removeItem(key);  // Если истек — удаляем элемент из localStorage
        return null;  // Возвращаем null, сигнализируя, что данные устарели
      }
  
      return item.data;  // Если данные актуальны, возвращаем их
    }
  };

//   Расширенные комментарии:
// set(key, data, ttl):
    // Функция set позволяет кэшировать данные с указанием времени жизни (ttl, time-to-live).
    // Мы сохраняем объект с полями data (сами данные) и expiry (время истечения кэша) в localStorage.
    // Используем localStorage, так как это простой и доступный метод хранения данных между сессиями в браузере. Важно помнить, что localStorage синхронен, но его можно использовать для хранения данных до 5–10 MB (в зависимости от браузера).
// get(key):
    // Функция get проверяет, существуют ли кэшированные данные и актуальны ли они. Если срок кэша истек, данные удаляются и возвращается null, сигнализируя, что нужно сделать новый запрос.
    // Использование localStorage.removeItem(key) очищает устаревшие данные, чтобы не захламлять память браузера.
    // Особенности:
    // В данном примере кэширование данных и срок жизни задается для каждого запроса, что обеспечивает гибкость, позволяя устанавливать разные значения TTL для различных запросов.