import { render, staticRenderFns } from "./modal_watch.vue?vue&type=template&id=309aff6f&scoped=true"
import script from "./modal_watch.vue?vue&type=script&lang=js"
export * from "./modal_watch.vue?vue&type=script&lang=js"
import style0 from "./modal_watch.vue?vue&type=style&index=0&id=309aff6f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "309aff6f",
  null
  
)

export default component.exports