<script>  
import HeaderUIElement from "../ui/HeaderUIElement.vue";  
import { mapState } from "vuex";  
import api from "@/api/staff";  
import TrainingCourseModule from "./TrainingCourseModule.vue";  

export default {  
  components: { HeaderUIElement, TrainingCourseModule },  
  data() {  
    return {  
      state: "",  
      myAwards: [],  
      awards: {  
        award_1: "📯",  
        award_2: "🏆",  
        award_3: "🏀",  
        award_4: "🎉",  
        award_5: "⭐️",  
        award_6: "👠",  
      },  
      isVisibleSpinner: false,  
      tooltipOptions: {  
        title: "Давайте знакомиться!<br><button class='btn btn-primary' size='sm' @click='onButtonClick'>Начать знакомство</button>",  
        html: true,  
        variant: "primary",  
      },  
      isVisibleTrainingCourseModule: false,  
      isEditing: false,  
      phoneInput: "", // Инициализируем как пустую строку  
      originalPhone: "", // Для хранения оригинального номера телефона  
      hobby: "",
    };  
  },  
  async mounted() {  
    this.isVisibleSpinner = true;  
    let id = JSON.parse(localStorage.getItem("vuex"));  
    let response = await api.currentUser(id.auth.uid);  
    this.hobby = response.data.hobby;
    this.setMyAwards(response.data.gifts);  
    this.originalPhone = response.data.phone_personal; // Сохраняем оригинальный номер  
    this.phoneInput = this.originalPhone; // Устанавливаем начальное значение для phoneInput  
    this.isVisibleSpinner = false;  
  },  
  methods: {  
    editPhone() {  
      this.isEditing = true;  
      this.phoneInput = this.originalPhone; // Устанавливаем номер телефона при редактировании  
    },  
    async savePhone() {  
      if (this.phoneInput === "") {  
        this.cancelEdit();  
      } else {  
        
        this.user.phone_personal = this.phoneInput;   
        this.originalPhone = this.phoneInput; // Обновляем оригинальный номер  
        this.isEditing = false;   
        await api.changeUserData(this.user.id, { phone_personal: this.phoneInput });
      }  
    },  
    cancelEdit() {  
      this.isEditing = false; // Выход из режима редактирования  
      this.phoneInput = this.originalPhone; // Восстанавливаем исходное значение  
    },  
    setMyAwards(gifts) {  
      this.myAwards = gifts.split(",").map((item) => item.trim());  
    },  
    translateAwards(val) {  
      return this.awards[val] || ""; // Вернем пустую строку, если награда не найдена  
    },  
    onButtonClick() {  
      this.isVisibleTrainingCourseModule = true;  
      this.$refs.tooltip.$emit('close')
    },  
  },  
  computed: {  
    ...mapState({  
      user: (state) => state.auth.user.user,  
    }),  
  },  
};  
</script>  

<template>  
  <div>  
    <TrainingCourseModule v-if="isVisibleTrainingCourseModule" @closeElement="isVisibleTrainingCourseModule = false" />  
    <HeaderUIElement :is_visible_input="false">Личный кабинет</HeaderUIElement>  
    <div class="content">  
      <section class="left_side_item_photo">  
        <div class="content_item">  
          <div class="img"></div>  
          <b-button size="sm" squared variant="outline-dark w-100 mt-auto">Редактировать</b-button>  
        </div>  
      </section>  
      <section class="left_side_item_change">  
        <div class="content_item_change_description">  
          <h6>Мои награды</h6>  
          <b-col md="6" class="mb-3 m-auto" v-if="isVisibleSpinner">  
            <b-icon icon="three-dots" animation="cylon" font-scale="2"></b-icon>  
          </b-col>  
          <div class="awards">  
            <div>  
              <span v-for="award in myAwards" :key="award" style="padding: 0 0.5vw; font-size: 2vh">{{ translateAwards(award) }}</span>  
            </div>  
            <p v-if="myAwards == '' && isVisibleSpinner == false" style="font-size: 12px">У вас пока что нет наград :(</p>  
          </div>  
        </div>  
      </section>  
      <section class="right_side_item_description">  
        <div class="description_fio">  
          <p>{{ user?.last_name }} {{ user?.first_name }} {{ user?.middle_name }}</p>  
        </div>  
        <hr />  
        <div class="description_data">  
          <p class="about_user_des">  
            Должность:   
            <span>{{ user?.post }}</span>  
          </p>  
          <p class="about_user_des">  
            Отдел:  
            <span>{{ user?.department }}</span>  
          </p>  
          <p class="about_user_des">Увлечения и хобби:
            <span>{{ hobby }}</span>  
          </p>  

          <div class="about_user_des" style="display: flex;">  
            Моб. телефон:  
            <span v-if="!isEditing" @click="editPhone">{{ user?.phone_personal }}</span>  
            <div v-else style="margin-left: 2%; display: flex; gap: 5px;">  
              <input type="text" v-model="phoneInput" @keyup.enter="savePhone" placeholder="Введите номер телефона" />  
              <b-button @click="savePhone" size="sm" variant="success">Сохранить</b-button>  
              <b-button @click="cancelEdit" size="sm" variant="danger">Отмена</b-button>  
            </div>  
          </div>  
        </div>  
      </section>  
    </div>  

    <div>  
      <b-tooltip target="trigger" placement="lefttop" :html="true" variant="primary" ref="tooltip">  
        Давайте знакомиться!  
        <br />  
        <button class="btn btn-primary" @click="onButtonClick">Начать знакомство</button>  
      </b-tooltip>  
      <img id="trigger" src="../files/sticker.webp" alt="талисман компании" class="talisman" />  
    </div>  
  </div>  
</template>  

<style scoped lang="scss">  
  @import "../style/style.scss";  
  @import "../style/style_my_profile.scss";  
</style>