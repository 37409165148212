<script>
  import api from "@/api/auth";
  export default {
    props: {
      nameApplication: {
        type: String,
      },
      fullData: {
        type: Object,
      },
    },
    data() {
      return {};
    },
    methods: {
      openModal() {
        const requiredFields = [
          this.fullData?.last_name,
          this.fullData?.first_name,
          this.fullData?.post,
          this.fullData?.department,
          this.fullData?.work_place,
          this.fullData?.start_date,
          this.fullData?.emails.length > 0,
        ];
        console.log(requiredFields);
        // Проверяем, есть ли пустые строки среди обязательных полей
        const hasEmptyField = requiredFields.some((field) => {
          return field === "" || field === null || field === undefined;
        });

        if (hasEmptyField) {
          this.$toast.error("Пожалуйста, заполните все обязательные поля.", { timeout: 3000 });
          return;
        }
        this.$bvModal.show("modal-center");
      },
      async sendApplication() {
        if (this.nameApplication !== "Заявка на создание рабочего места") {
          this.$toast.info("Запрос отправлен в кадровую службу\nНе забудьте передать заполненный бланк!", { timeout: 5000 });
        }
        const query = {
          order_type: this.nameApplication,
          on_date: new Date().toISOString().slice(0, 10),
        };
        if (this.nameApplication === "Заявка на создание рабочего места") {
          query.status = "in_queue";
          query.status = "executed";
          query.recipients = this.fullData.emails.map((item) => item.id);
          query.comment = `Добрый день, Коллеги!\n
          Прошу подготовить рабочее место новому сотруднику, оформить пропуск, предоставить канцелярские принадлежности и выдать корпоративный ежедневник.\n
          ФИО: ${this.fullData?.last_name} ${this.fullData?.first_name} ${this.fullData?.middle_name};\n
          Должность: ${this.fullData?.post}\n
          Подразделение: ${this.fullData?.department}\n
          Рабочее место: ${this.fullData?.work_place}\n
          Дата выхода: ${this.fullData?.start_date?.split("-").reverse().join(".")}\n
          `;
        }
        try{
          await api.sendDataToPersonnelService(query);
          this.$toast.info("Запрос отправлен в кадровую службу\nИ аффилированным лицам", { timeout: 5000 });
        }catch(err){
          this.$toast.error(err, { timeout: 3000 });
        }
        this.$bvModal.hide("modal-center");
      },
    },
  };
</script>
<template>
  <div>
    <b-modal id="modal-center" centered title="Отправка заявки">
      <p class="my-4">Вы подтверждаете отправку заявки в кадровую службу?</p>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-between">
          <b-button variant="danger" size="sm" @click="$bvModal.hide('modal-center')">Отменить</b-button>
          <b-button variant="success" size="sm" @click="sendApplication">Да, подтверждаю</b-button>
        </div>
      </template>
    </b-modal>

    <b-button size="sm" class="mb-2 border rounded p-2" style="background: #364a70" @click="openModal">
      <b-icon icon="cloud-upload" aria-hidden="true" focusable="false"></b-icon>
      Отправить данные
    </b-button>
  </div>
</template>

<style scoped></style>
