<template>
  <div>
    <b-modal id="modal-center" centered title="Отправка заявки">
      <p class="my-4">
        Вы подтверждаете отправку заявки в кадровую службу?
        <span v-if="order_type == 'Заявление на выдачу копии трудовой книжки' || order_type == 'Заявление о выдаче справки с места работы'">
          Никакой документ в кадровую службу относить не требуется.
        </span>
      </p>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-between">
          <b-button variant="danger" size="sm" @click="$bvModal.hide('modal-center')">Отменить</b-button>
          <b-button variant="success" size="sm" @click="sendApplication">Да, подтверждаю</b-button>
        </div>
      </template>
    </b-modal>
    <iframe :src="documentUrl" width="100%" height="0" frameborder="0"></iframe>
    <div class="workspace" v-if="state === ''">
      <section class="header">
        <h4 class="description">Подать новое заявление</h4>
        <input type="text" placeholder="Поиск заявления..." @input="updateApplication($event.target.value)" />
      </section>
      <div class="content">
        <div
          :class="['application_item', { disabled: application.extra_des == 'disabled' }]"
          v-for="application in filteredApplications"
          :key="JSON.stringify(application)"
          @click="application.name !== 'Заявление создание нового сотрудника' ? openModal(application.name, $event.target) : openForm(application.name, $event.target)"
        >
          <p style="color: #365e7d">{{ application.name }}</p>
        </div>
      </div>
    </div>
    <!-- @click="openForm(application.name, $event.target)" -->
    <section class="forms">
      <component :is="state" @clearForm="clearForm"></component>
    </section>
  </div>
</template>

<script>
  import DismissalFormVue from "../forms/DismissalForm.vue";
  import RestForm from "../forms/RestForm.vue";
  import RestWoMoneyForm from "../forms/RestWoMoneyForm.vue";
  import TransferForm from "../forms/TransferForm.vue";
  import DismissalForm from "../forms/DismissalForm.vue";
  import EducationalPaidLeaveForm from "../forms/EducationalPaidLeaveForm.vue";
  import ActTransferJobForm from "../forms/ActTransferJobForm.vue";
  import ReportEntertainmentExpenses from "../forms/ReportEntertainmentExpenses.vue";
  import PlanApplication from "../forms/PlanApplication.vue";
  import EducationForm from "../forms/EducationForm.vue";
  import NewUserInCompany from "../forms/NewUserInCompany.vue";
  import api from "@/api/auth";
  export default {
    components: { RestForm, RestWoMoneyForm, TransferForm, DismissalForm, EducationalPaidLeaveForm, ActTransferJobForm, ReportEntertainmentExpenses, PlanApplication, EducationForm, NewUserInCompany },
    data() {
      return {
        searchValue: "",
        state: "",
        applications: [
          { name: "Заявление на ежегодный отпуск", description: "", value_description: "" },
          { name: "Заявление на отпуск без сохранения зп", description: "", value_description: "" },
          { name: "Заявление на перевод", description: "", value_description: "" },
          { name: "Заявление на увольнение", description: "", value_description: "" },
          { name: "Заявление на учебный оплачиваемый отпуск", description: "", value_description: "" },
          { name: "Акт передачи дел на период отпуска", description: "", value_description: "" },
          { name: "План заявка и отчет о командировке", description: "", value_description: "" },
          { name: "Заявление на выдачу копии трудовой книжки", description: "", value_description: "" },
          { name: "Заявление о выдаче справки с места работы", description: "", value_description: "" },
          { name: "Заявление на обучение", description: "", value_description: "" },
          { name: "Заявление создание нового сотрудника", description: "", value_description: "" },
          { name: "Опрос 1"}
        ],
        filePaths: {
          "Заявление на ежегодный отпуск": "/docs/Заявление на ежегодный отпуск.doc",
          "Заявление на отпуск без сохранения зп": "/docs/Заявление на отпуск без сохранения зп.doc",
          "Заявление на перевод": "/docs/Заявление на перевод.doc",
          "Заявление на увольнение": "/docs/Заявление на увольнение.doc",
          "Заявление на учебный оплачиваемый отпуск": "/docs/Заявление на учебный оплачиваемый отпуск.doc",
          "Акт передачи дел на период отпуска": "/docs/Акт передачи дел на период отпуска.docx",
          "Заявление на выдачу копии трудовой книжки": "/docs/Заявление на выдачу копии трудовой книжки.doc",
          "Заявление о выдаче справки с места работы": "/docs/Заявление о выдаче справки с места работы.doc",
          "План заявка и отчет о командировке": "/docs/План заявка и отчет о командировке.xlsx",
          "Заявление на обучение": "/docs/Заявление на обучение.doc",
          "Опрос 1.docx": "/docs/Опрос 1.docx",
        },
        order_type: "",
        documentUrl: "", // URL вашего документа
      };
    },
    computed: {
      filteredApplications() {
        return this.applications.filter((application) => application.name.toLowerCase().includes(this.searchValue.toLowerCase()));
      },
    },
    methods: {
      openForm(name, e) {
        if (e.classList.contains("disabled")) {
          this.$toast.info(`${name} находится в разработке`, {
            timeout: 3500,
          });
          return;
        }
        switch (name) {

          case "Заявление создание нового сотрудника":
            this.state = "NewUserInCompany";
            break;

        }
      },

      async sendApplication() {
        this.$toast.info("Запрос отправлен в кадровую службу\nНе забудьте передать заполненный бланк!", { timeout: 5000 });
        const query = {
          order_type: this.order_type,
          on_date: new Date().toISOString().slice(0, 10),
        };
        // console.log("query",query)
        let response = await api.sendDataToPersonnelService(query);
        console.log(response);
        this.$bvModal.hide("modal-center");
      },
      openModal(name, e) {
        this.order_type = name;

        // Устанавливаем путь к документу на основе имени
        this.documentUrl = this.filePaths[name] || ""; // Если путь не найден, установим пустую строку
        this.$nextTick(() => this.$bvModal.show("modal-center"));
      },
      clearForm(val) {
        this.state = "";
      },
      updateApplication(searchValue) {
        this.searchValue = searchValue;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @use "../style/style.scss";
</style>
