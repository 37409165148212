<template>
  <div>
    <Loader :loader="loader" />

    <div class="shipment-kind">
    <div class="shipment-kind__header">
      <h4 class="header-text">Род подвижного состава</h4>
      <hr />
    </div>
    <p class="explanation">
      * Устанавливаемый в расчет вагон должен соответствовать заданному ранее виду отправки. <br>
      * Принадлежность влияет на стоимость перевозки. Она подразделяется на: <br>
      &nbsp; &nbsp;- <span style="font-weight: 500;">инвентарный парк </span> - вагоны, принадлежащие железной дороге;<br>
      &nbsp; &nbsp;- <span style="font-weight: 500;">собственный</span> - вагоны, принадлежащие собственникам;<br>
      &nbsp; &nbsp;- <span style="font-weight: 500;">привлеченный ОАО "РЖД"</span> - вагоны, привлеченные ОАО "РЖД" у других собственников (параметр становится доступным только при экспортно-импортной и внутренней перевозке по территории России грузов или контейнеров в полувагонах, привлеченных у ОАО "ФГК");<br>
      &nbsp; &nbsp;- <span style="font-weight: 500;">арендованный у перевозчика</span> - вагоны, арендованные у железной дороги (правила расчета аналогичны собственным вагонам)

    </p>
    <div class="shipment-kind__content">
      <input type="text" id="search" class="textarea" style="width: 100% !important" v-on:keyup="handleInputOnKeyup" v-model="search" placeholder="Введите тип вагона (полувагон)" />
      <br />
    </div>
    <div class="shipment-kind__content__input">
      <div class="option" v-for="wagon in getCurrentWagonType" :key="wagon.id" style="display:block">
        <input type="radio" :id="wagon.id" :value="wagon.id" v-model="wagonType" />
        <label  :for="wagon.id">&nbsp;{{ wagon.name }}</label>
        <hr>
      </div>
    </div>
    <div class="expand-info">
      <label for="amountWagon">Количество <br />
        <input id="amountWagon" type="number" placeholder="Количество" class="textarea" v-model="amount" min="0" style="width: 100%"/>
      </label>

<label for="belong">Принадлежность <br />
  <select name="" id="belong" class="textarea" v-model="belong" style="width: 100%">
        <option value="" disabled selected>Принадлежность</option>
        <option value="1">Инвентарный парк</option>
        <option value="2">Собственный</option>
        <option value="3">Арендованный</option>
        <option value="7">Привлеченный ОАО "РЖД"</option>
      </select>
</label>
   
    </div>
  </div>
  <Notifications
        :show="showNotify"
        :header="notifyHead"
        :message="notifyMessage"
        :block-class="notifyClass"
        id="notif"
      />
  </div>

</template>

<script>
import { mapState } from "vuex";
import api from "@/api/wagonPark";
import Loader from "@/components/loader/loader.vue";
import Notifications from "@/components/notifications/Notifications.vue";

export default {
  data() {
    return {
      wagon: "",
      wagon_type: [],
      wagonType: "",
      belong: "",
      search: "",
      flame: "",
      amount: "",
      loader: false,
      showNotify: false,
      notifyHead: "",
      notifyMessage: "",
      notifyClass: "",
      lengthRoute: "",
    };
  },
  components: { Loader, Notifications },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      uid: (state) => state.auth.uid,
    }),
    getCurrentWagonType() {
      return (this.wagon_type.filter(item => item.name.toLowerCase().indexOf(this.search) !== -1))
    },
  },
  watch: {
    wagonType() {
      this.notifyHead = "Успешно";
        this.notifyMessage = "Вагон выбран и добавлен";
        this.notifyClass = "wrapper-success";
        this.showNotify = true;
        setTimeout(() => (this.showNotify = false), 2000);
      this.$emit('wagon', {
        wagon_id: this.getWagonTypeId(this.wagonType),
        wagon_type: this.getWagonById(this.wagonType)
      })
    },
    belong() {
      this.$emit('belong', {
        belong: this.belong
      })
    },
    amount() {
      this.$emit('amount', this.amount)
    }
  },
  mounted() {
    api.getWagonType()
    .then((response) => {
      this.wagon_type = response.data.data;
    }).catch(error => {
      console.log(error)
    })
  },
  methods: {
    handleInputOnKeyup() {
      this.search = this.search.toLowerCase();
    },
    getWagonById(data) {
      let searchWagon = data
      let wagon = this.wagon_type.find(item => item.id === searchWagon).name
      return wagon
    },
    getWagonTypeId(wagon){
      return this.wagon_type.find(item => item.id === wagon).type_id
    },
   
  },
};
</script>
<style scoped>
.explanation {
  font-size: 13px;
  color: grey;
  text-align: left;
  padding: 0 0 2% 4%;
}
.option {
  height: 50px;
  font-size: 17px;
  display: block;
}
label {
  color: grey;
  font-size: 1rem;
}
.shipment-kind__content__input{
  min-height: 25vh;
  max-height: 25vh;
  overflow: auto;
  border: 1px solid #EFF0F2 !important;
  width: 95%;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}
button {
  background: white;
  color: black;
  border: none;
  outline: none;
  letter-spacing: 1px;
  border-radius: 7px;
  font-weight: 500;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  height: 25px;
}

button:hover {
  background: white;
}

.expand-info {
  display: flex;
  justify-content: space-around;
  padding: 5% 0 5% 5% !important;
  margin-bottom: 3%;
  width: 95%;
  position: relative;
  left: 50%;
  transform: translate(-50%,0);
  border: 1px solid #EFF0F2 ;
  border-top: none;
}



.textarea {
  background: white;
  border: 1px solid grey !important;
  width: 30%;
}

.textareaS {
  border: 1px solid grey;
  width: 30%;
  border-radius: 5px;
}

.shipment-kind {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  min-height: 55vh;
  max-height: 85vh;
  width: 90%;
  border: 2px solid #1e86f5;
  border-radius: 10px;
}

.header-text {
  color: #444b54;
  padding-top: 2%;
  padding-left: 3%;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
}

.shipment-kind__content {
  width: 95%;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 5px;
  margin-bottom: 3%;
}
</style>