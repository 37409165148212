<template>
  <div>
    <div class="form" id="element-to-convert" ref="form_1">
      <br />
      <div class="header">
        <h6 class="director_title">
          Генеральному директору
          <br />
          ООО «Транспортные Технологии»
          <br />
          Тертычному С.Н.
        </h6>
        <div class="input_block">
          <label class="input_label">
            <input type="text" class="right_aligned" />
            <span class="input_span">(должность)</span>
          </label>
          <label class="input_label">
            <input type="text" class="right_aligned" />
            <span class="input_span">(наименование подразделения)</span>
          </label>
          <label class="input_label">
            <input type="text" class="right_aligned" />
            <span class="input_span">(фамилия, инициалы)</span>
          </label>
        </div>
      </div>
      <div class="text">
        <h6 class="text_title">Заявление</h6>
        <div class="text_content">
          <b>
            <p>Прошу Вас предоставить основной ежегодный оплачиваемый отпуск</p>
            с «
            <input type="text" class="small_input right_aligned" />
            »
            <input type="text" class="large_input right_aligned" />
            &nbsp;
            <input type="text" class="small_input right_aligned" />
            г. по «
            <input type="text" class="small_input right_aligned" />
            »
            <input type="text" class="large_input right_aligned" />
            &nbsp;
            <input type="text" class="small_input right_aligned" />
            г. на
            <input type="text" class="right_aligned" />
            календарных дней.
          </b>
        </div>
      </div>
      <div class="date_block">
        <label class="input_label">
          <input type="text" class="right_aligned" />
          <span class="input_span"><b>(подпись)</b></span>
        </label>
        <p class="date_info">
          «
          <input type="text" class="small_input right_aligned" />
          »
          <input type="text" class="large_input right_aligned" />
          <input type="text" class="small_input right_aligned" />
          г.
        </p>
      </div>
      <div class="visa">
        <b>Визы:</b>
        <br />
        <b>
          Руководитель структурного подразделения:
          <input type="text" class="right_aligned" />
          /
          <input type="text" class="right_aligned" />
          /
        </b>
      </div>
    </div>
    <div class="navigation">
      <span class="back" @click="$emit('clearForm', true)">
        <b-icon icon="chevron-left"></b-icon>
        Вернуться назад
      </span>
      <ButtonSendApplicationUIElement :nameApplication="'Заявление на отпуск'" />

      <b-button size="sm" class="mb-2 border rounded p-2" style="background: #264722" @click="exportToPDF">
        <b-icon icon="check-all" aria-hidden="true" focusable="false"></b-icon>
        Скачать документ
      </b-button>
    </div>
  </div>
</template>

<script>
  import html2pdf from "html2pdf.js";
  import ButtonSendApplicationUIElement from "../ui/ButtonSendApplicationUIElement.vue";
  export default {
    components: {
      ButtonSendApplicationUIElement,
    },
    methods: {
      exportToPDF() {
        const element = document.getElementById("element-to-convert");
        const originalStyles = element.style.cssText;

        element.style.height = "auto";
        element.style.boxShadow = "none";
        element.style.width = "796px"; // Set specific width for PDF

        this.$nextTick(() => {
          html2pdf()
            .set({
              margin: 1,
              filename: "Заявление на отпуск.pdf",
              image: { type: "jpeg", quality: 1 },
              html2canvas: { scale: 2 },
              jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            })
            .from(element)
            .save()
            .then(() => {
              element.style.cssText = originalStyles;
            });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../style/style_form.scss";

  .form {
    margin-left: 1%;
    padding-right: 4%;
  }

  .header {
    width: 40%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  h6.director_title {
    font-weight: bold;
    text-align: left; /* Выравнивание по левой грани */
    margin: 0; /* Убираем отступы, чтобы он лучше встраивался */
  }

  h6.text_title {
    font-weight: bold;
    text-align: center;
  }

  p,
  b,
  h6,
  span {
    font-family: "Times New Roman", sans-serif;
  }

  b {
    font-size: 14px;
  }

  .input_block {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .input_label {
    width: 100%;
    margin-bottom: 5px; /* Уменьшено расстояние между инпутами */
  }

  .input_span {
    display: block;
    text-align: center;
    margin-top: 5px;
    font-size: 10px !important;
  }

  input {
    margin-top: 0 !important;
    width: 100%; /* Устанавливаем ширину инпутов на 100% */
    text-align: right; /* Выравнивание текста по правому краю */
  }

  .text {
    margin-top: 10px;
    padding-left: 7%;
  }
  .text_content {
    margin-top: 2%;
  }
  .text_content,
  .visa {
    margin-left: 6%;
  }

  .date_block {
    margin-top: -1%;
  }

  .date_info {
    text-align: right;
  }

  .small_input {
    width: 8% !important;
    text-align: right; /* Выравнивание текста по правому краю для маленьких инпутов */
  }

  .large_input {
    width: 12% !important;
    text-align: right; /* Выравнивание текста по правому краю для больших инпутов */
  }

  .navigation {
    margin-top: 2%;
  }

  .download_button {
    width: 20%;
  }

  .pdf_icon {
    width: 20px;
  }

  b {
    font-weight: bold;
  }
</style>
