<template>
  <div>
    <Loader :loader="loader" />
    <b-modal id="standard_directory_created" hide-footer>
      <template #modal-title>Подтверждение действия</template>
      <div class="d-block text-center">
        <h4>Вы уверены, что хотите удалить данные?</h4>
        <p>В случае удаления, данные будут потеряны безвозвратно</p>
      </div>
      <b-button variant="danger" @click="deleteTarifData(selected_record)">Да, я уверен</b-button>
      <b-button class="mt-3" block @click="$bvModal.hide('standard_directory_created')">Нет, отменить</b-button>
    </b-modal>
    <!-- <b-container> -->
    <a class="WatchAllArenda" v-on:click="visible = !visible">
      {{ visible ? "Скрыть данные по ставкам" : "Отобразить данные по ставкам" }}
    </a>
    <div class="table-content" v-show="visible">
      <v-select v-model="filter_arendaData.page_size" :options="['1', '10', '25']" label="item" style="width: 15vw" placeholder="Кол-во договоров" />
      <v-select v-model="filter_arendaData.client" :options="name_client" label="client" style="width: 15vw" placeholder="Клиент"></v-select>
      <v-select v-model="filter_arendaData.cargo" :options="cargo_code" label="name" style="width: 15vw" placeholder="Груз" />
      <label for="tenant" class="checkbox-label">
        <input type="checkbox" style="height: 20px" v-model="filter_arendaData.is_active" />
        Действующее приложение
      </label>

      <b-button size="sm" class="mb-2 border rounded p-2 d-flex" @click="getStandardData()" style="margin-left: auto !important; background: #264722; min-width: 150px !important">
        <b-icon icon="cloud-download" aria-hidden="true" focusable="false"></b-icon>
        &nbsp;Запросить данные
      </b-button>
    </div>
    <p class="amount" style="padding-top: 2%; display: flex; justify-content: space-between" v-show="visible">
      <span>Всего записей: {{ total_objects }}</span>
      <span style="color: red">Временно сохранение измененных данных невозможно!</span>
    </p>
    <!-- <button @click="DownloadExcel()">Скачать в Excel</button> -->
    <!-- <button class="Delete button" style="width: 15%; white-space: nowrap; margin: 2% 0; height: 30px" @click="open_modal(selectedItems)">Удалить выбранное</button> -->
    <div style="max-width: 100%; overflow: auto; margin-bottom: 5%">
      <table border="1" v-show="visible" ref="theTable" style="width: 100%">
        <thead>
          <th>№</th>
          <th>Номер дог.</th>
          <th>Дата</th>
          <th>Дата оконч.</th>
          <th>Клиент</th>
          <th>Ответственный</th>
        </thead>
        <tbody>
          <template v-for="(item, index) in data">
            <tr :id="item.id">
              <td @click="open_modal(item.id)" class="delete">{{ index + 1 }}</td>
              <td>{{ item.agreement_number }}</td>
              <td>{{ item.on_date?.split("-").reverse().join(".") }}</td>
              <td>{{ item.end_date?.split("-").reverse().join(".") }}</td>
              <td>{{ item.client }}</td>
              <td>{{ item.responsible_name }}</td>
            </tr>
            <tr>
              <td colspan="13">
                <div style="width: 100%">
                  <!-- Кнопка для разворачивания списка приложений -->
                  <button @click="toggleAttachmentsVisibility(item.id)" class="openAttachments">Приложений {{ item.attachments.length }} шт.</button>
                  <div v-if="isAttachmentsVisible(item.id)" style="width: 100%">
                    <table style="width: 100%">
                      <tr v-for="att in item.attachments" :key="att.id" style="width: 100%">
                        <td colspan="13">
                          <!-- Кнопка для разворачивания конкретного приложения -->
                          <button @click="toggleAttachmentDetails(item.id, att.agreement_number)" class="openAttachmentsSec">
                            Приложение {{ att.agreement_number }}
                            <span v-if="att.attachments[0]?.on_date">от</span>
                            {{ att.attachments[0]?.on_date?.split("-").reverse().join(".") }}
                          </button>
                          <!-- Handsontable для развёрнутого приложения -->
                          <div v-if="isAttachmentDetailsVisible(item.id, att.agreement_number)" 
                          style="width: 100%; max-height: 400px; overflow: auto; overscroll-behavior: contain;">
                            <HotTable
                              ref="hotTable"
                              :settings="hotSettings"
                              :data="att.attachments"
                              :licenseKey="'non-commercial-and-evaluation'"
                            />
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div id="wrapper" v-show="visible">
      <ul id="pagination">
        <li v-for="btn in total_pages" :key="btn.id">
          <a
            @click="getPagination(filter_arendaData.page_size, btn)"
            :class="{
              active123: Truefalse(btn),
              active_new: pageNumber == btn,
            }"
          >
            {{ btn }}
          </a>
        </li>
      </ul>
    </div>
    <br />
    <br />
    <!-- </b-container> -->
    <Notifications :show="showNotify" :header="notifyHead" :message="notifyMessage" :block-class="notifyClass" id="notif" />
  </div>
</template>

<script>
  import api from "@/api/directory";
  import apiStations from "@/api/wagonPark";
  import Loader from "@/components/loader/loader.vue";
  import Notifications from "@/components/notifications/Notifications.vue";
  import { mapState } from "vuex";
  import { HotTable } from "@handsontable/vue";
  import { registerAllModules } from "handsontable/registry";
  import { registerLanguageDictionary, getLanguagesDictionaries, ruRU } from "handsontable/i18n";
  registerLanguageDictionary(ruRU);
  registerAllModules();
  import moment from "moment";
  import "moment/locale/ru";
  export default {
    components: { Loader, Notifications, HotTable },
    data() {
      return {
        hotSettings: {
          columns: [
            { title: "Дата оконч.", data: "end_date" },
            { title: "Расстояние от", data: "distance_min" },
            { title: "Расстояние до", data: "distance_max" },
            { title: "Ставка", data: "stavka" },
            { title: "НДС", data: "nds" },
            { title: "Груз", data: "cargo" },
            { title: "Станция отпр.", data: "departure_station_name" },
            { title: "Станция назн.", data: "destination_station_name" },
            { title: "Сдвоенный рейс", type: "checkbox", data: "for_paired_flights" },
            { title: "Груз наимен", data: "cargos_list" },
            { title: "Мн. станций отправки", data: "departure_stations" },
            { title: "Расстояние", data: "distance" },
            { title: "Станция следующей погрузки", data: "next_loading_stations" },
            { title: "Станции исключения назначения", data: "exclude_next_loading_stations" },
            { title: "Страна", data: "country_id" },
            { title: "Вагоны", data: "wagons_list" },
            { title: "Ответственный", data: "responsible_name", readonly: true },
          ],
          rowHeaders: true,
          manualRowResize: true,
          manualColumnResize: true,
          collapsibleColumns: true,
          height: "auto",
          width: "100%",
        },
        all_checkbox: [],
        selectAll: false,
        selectedItems: [],

        cache: new Map(),
        watchAnnex: true,
        cargo_search_visible: false,
        cargo: "",
        visible: true,
        loader: false,
        selected_record: "",
        loader: false,
        data: "",
        interval: 3,
        pagination: "",
        total_pages: "",
        total_objects: 0,

        pageNumber: 1,

        ten_visible: false,
        filter_arendaData: {
          page_size: "10",
          client: "",
          cargo: "",
          wagon_type: "Цистерна",
          is_active: true,
        },
        showNotify: false,
        notifyHead: "",
        notifyMessage: "",
        notifyClass: "",

        visibleAttachments: {}, // Хранит состояние видимости для каждого элемента
        visibleAttachmentDetails: {}, // Хранит состояние видимости для деталей вложений
      };
    },
    mounted() {
      document.body.addEventListener("click", this.CloseWindow);
      moment.locale("ru");
    },

    computed: {
      ...mapState({
        uid: (state) => state.auth.uid,
        user: (state) => state.users.users,
        cargo_code: (state) => state.cargo_code.cargo_code,
        road: (state) => state.road.roadAsCountries,
        name_client: (state) => state.client.name_client,
      }),
    },
    methods: {
      toggleAttachmentsVisibility(itemId) {
        // Переключаем видимость списка приложений
        this.$set(this.visibleAttachments, itemId, !this.visibleAttachments[itemId]);
      },
      isAttachmentsVisible(itemId) {
        // Проверяем, виден ли список приложений
        return !!this.visibleAttachments[itemId];
      },
      toggleAttachmentDetails(itemId, attachmentId, att) {
        // Создаём уникальный ключ для приложения
        const key = `${itemId}-${attachmentId}`;
        console.log(key, att, "key");
        this.$set(this.visibleAttachmentDetails, key, !this.visibleAttachmentDetails[key]);
      },
      isAttachmentDetailsVisible(itemId, attachmentId) {
        // Проверяем видимость для конкретного приложения
        const key = `${itemId}-${attachmentId}`;
        return !!this.visibleAttachmentDetails[key];
      },

      toggleSelectAll(annex_number) {
        this.selectAll = !this.selectAll;
        if (this.selectAll) {
          console.log(this.data[0].attachments, annex_number, "Начальные данные");
          for (let i in this.data[0].attachments) {
            if (this.data[0].attachments[i].agreement_number == annex_number) {
              this.selectedItems = this.data[0].attachments[i].attachments.map((item) => item.id);
            }
          }
        } else {
          this.selectedItems = [];
        }
        console.log(this.selectedItems);
      },

      toggleItemSelection(itemId) {
        if (this.isSelected(itemId)) {
          this.selectedItems = this.selectedItems.filter((id) => id !== itemId);
        } else {
          this.selectedItems.push(itemId);
        }

        console.log(this.selectedItems);
      },
      isSelected(itemId) {
        return this.selectedItems.includes(itemId);
      },
      getFullInformationByRequest(val) {
        this.loader = true;
        apiStations
          .getCurrentStation(val)
          .then((response) => {
            let fullInformationBySation = response.data.data.filter((item) => item.name.toLowerCase() == val.toLowerCase())[0];
            console.log(fullInformationBySation);
            this.loader = false;
            this.notifyHead = "Успешно";
            this.notifyMessage = `Станция: ${fullInformationBySation.name} <br>
                                            Дорога: ${fullInformationBySation.road.name}`;
            this.notifyClass = "wrapper-success";
            this.showNotify = true;
            setTimeout(() => {
              this.showNotify = false;
            }, 4000);
          })
          .catch((err) => {
            console.log(err);
            this.loader = false;
          });
      },
      deleteRow(index) {
        this.data.splice(index, 1);
      },
      IdToName(val) {
        let value = this.user.filter((item) => item.id == val);
        return value[0]?.last_name;
      },
      Truefalse(btn) {
        if (btn == this.pageNumber) {
          return true;
        }
        if (btn == 1) {
          return true;
        }
        if (btn == this.total_pages) {
          return true;
        }
        if (btn > this.pageNumber && btn < this.pageNumber + this.interval) {
          return true;
        }
        if (btn < this.pageNumber && btn > this.pageNumber - this.interval) {
          return true;
        }

        return false;
      },
      async getPagination(pg_size, pg_number) {
        this.loader = true;

        api
          .getAllTarifDataPagination(this.filter_arendaData, pg_size, pg_number)
          .then((response) => {
            this.loader = false;
            this.data = response.data.data;
            // console.log(this.data);
            this.data = response.data.data;
            // Так как с сервера приходят в виде мало сгруппированном, здесь происходить группировка приложений
            function groupAttachments(attachments) {
              const groupedAttachments = {};

              attachments.forEach((attachment) => {
                const agreementNumber = attachment.agreement_number;

                if (!groupedAttachments[agreementNumber]) {
                  groupedAttachments[agreementNumber] = [];
                }

                groupedAttachments[agreementNumber].push(attachment);
              });

              return Object.keys(groupedAttachments).map((key) => ({
                agreement_number: key,
                attachments: groupedAttachments[key],
              }));
            }
            this.loader = false;
            // Обрабатываем каждый элемент данных

            const promises = this.data.map(async (item) => {
              item.attachments = groupAttachments(item.attachments);
              if (item) {
                await Promise.all(item.attachments.flatMap((value) => value.attachments || [])).then(async (codes) => {
                  for (const code of codes) {
                    if (code?.departure_station_id !== null) {
                      code.departure_station_id = code.departure_station_id;
                    }
                    if (code?.destination_station_id !== null) {
                      code.destination_station_id = code.destination_station_id;
                    }
                  }
                });
              }
              return item;
            });

            // // Дожидаемся завершения всех промисов и устанавливаем this.loader = false

            this.pageNumber = response.data.page_number;
          })

          .catch((error) => {
            this.loader = false;
            this.notifyHead = "Ошибка";
            this.notifyMessage = error.response.data;
            this.notifyClass = "wrapper-error";
            this.showNotify = true;
            setTimeout(() => {
              this.showNotify = false;
            }, 2000);
            console.log(error);
          });
      },

      //   Получение данных в таблицу для просмотра и редактировния
      async getStandardData() {
        this.loader = true;
        this.data = [];
        if (this.filter_arendaData.client != "") {
          this.filter_arendaData.client = this.filter_arendaData.client.client;
        }
        console.log(this.filter_arendaData, "query params");

        try {
          const response = await api.getTarifData(this.filter_arendaData);
          this.pageNumber = response.data.page_number;
          this.pagination = response.data.links;
          this.total_pages = response.data.total_pages;
          this.total_objects = response.data.total_objects;
          this.data = response.data.data;

          // Изменение данные в приложениях
          // let second_promises = response.data.data[0].attachments.map((item) => {
          //     if(item.agreement_number == '82'){
          //         console.log(item)
          //         return api.editStavkiRevenue(item.id, { on_date: '2024-05-01' });
          //     }
          // });

          // await Promise.all(second_promises);

          function groupAttachments(attachments) {
            const groupedAttachments = {};

            attachments.forEach((attachment) => {
              const agreementNumber = attachment.agreement_number;

              if (!groupedAttachments[agreementNumber]) {
                groupedAttachments[agreementNumber] = [];
              }

              groupedAttachments[agreementNumber].push(attachment);
            });

            return Object.keys(groupedAttachments).map((key) => ({
              agreement_number: key,
              attachments: groupedAttachments[key],
            }));
          }
          this.loader = false;
          // Обрабатываем каждый элемент данных

          const promises = this.data.map(async (item) => {
            item.attachments = groupAttachments(item.attachments);
            if (item) {
              await Promise.all(item.attachments.flatMap((value) => value.attachments || [])).then(async (codes) => {
                for (const code of codes) {
                  if (code?.departure_station_id !== null) {
                    code.departure_station_id = code.departure_station_id;
                  }
                  if (code?.destination_station_id !== null) {
                    code.destination_station_id = code.destination_station_id;
                  }
                }
              });
            }
            return item;
          });

          // // Дожидаемся завершения всех промисов и устанавливаем this.loader = false
          await Promise.allSettled(promises);
          // let result = []
          // let data_for_senchakov = this.data.map((item) => {
          //     for(let attachment of item.attachments) {
          //         result.push({
          //             'Приложение': attachment.agreement_number,
          //             'Клиент': item.client,
          //             'Дата начала': attachment.attachments[0].on_date
          //         })
          //     }
          //     return result
          // })
          // console.log(this.data, data_for_senchakov)
        } catch (error) {
          this.loader = false;
          this.notifyHead = "Ошибка";
          this.notifyMessage = error.response;
          this.notifyClass = "wrapper-error";
          this.showNotify = true;
          setTimeout(() => {
            this.showNotify = false;
          }, 2000);
        }
      },

      checkCargo(item, code6) {
        this.filter_arendaData.cargo = code6;
        this.cargo = item;
        this.cargo_search_visible = false;
      },
      CloseWindow() {
        this.ten_visible = false;
        this.cargo_search_visible = false;
      },
      async submitData(element, id, frst, lst, event) {
        document.getElementById(`${lst}${id}`).style.display = "block";
        let operationBuffer;
        if (frst == "departure_station" || frst == "destination_station") {
          operationBuffer = element.replace(/ [А-Я]{2}[^ ]*/g, "  ").split("  ")[0];
          try {
            let result = await apiStations.getCurrentStation(operationBuffer);
            if (result.data.data.length == 0) {
              document.getElementById(`${lst}${id}`).style.display = "none";
              this.notifyHead = "Ошибка";
              this.notifyMessage = "Станция с таким наименованием не найдена";
              this.notifyClass = "wrapper-error";
              wagon_DOM.classList.remove("error");
              this.showNotify = true;
              setTimeout(() => {
                this.showNotify = false;
              }, 2500);
              return;
            }
            let findStation = result.data.data.filter((item) => {
              return item.name.toLowerCase() === operationBuffer.toLowerCase();
            });
            // console.log(findStation)
            element = findStation[0]?.code;
          } catch (error) {
            this.notifyHead = "Ошибка";
            this.notifyMessage = error;
            this.notifyClass = "wrapper-error";
            this.showNotify = true;
            setTimeout(() => {
              this.showNotify = false;
            }, 3500);
          }
        }
        let name = frst;
        let data = [];
        data.push({ [name]: element, responsible: this.uid });
        console.log(id, data[0], "i am");
        api
          .patchTarifData(id, data[0])
          .then((response) => {
            document.getElementById(`${lst}${id}`).style.display = "none";
            let wagon_DOM = document.getElementById(`${frst}${id}`);
            wagon_DOM.classList.add("success");
            setTimeout(() => {
              wagon_DOM.classList.remove("success");
            }, 1000);
            // document.getElementById(`${lst}${id}`).value = operationBuffer
            if (event) {
              event.target.value = operationBuffer;
            }
          })
          .catch((error) => {
            document.getElementById(`${lst}${id}`).style.display = "none";
            let wagon_DOM = document.getElementById(`${frst}${id}`);
            wagon_DOM.classList.add("error");
            setTimeout(() => {
              wagon_DOM.classList.remove("error");
            }, 1000);
            this.notifyHead = "Ошибка";
            this.notifyMessage = error;
            this.notifyClass = "wrapper-error";
            this.showNotify = true;
            setTimeout(() => {
              this.showNotify = false;
            }, 3500);
          });
      },

      open_modal(id) {
        this.selected_record = id;
        this.$bvModal.show("standard_directory_created");
      },

      // Удаление множества элементов
      deleteTarifData(id) {
        if (Array.isArray(id)) {
          this.loader = true;
          let requests = id.map((url) => api.deleteTarifData(url));
          Promise.all(requests)
            .then((res) => {
              this.loader = false;
              this.$bvModal.hide("standard_directory_created");
              this.notifyHead = "Успешно";
              this.notifyMessage = "Данные удалены";
              this.notifyClass = "wrapper-success";
              this.showNotify = true;
              setTimeout(() => {
                this.showNotify = false;
              }, 2500);
              this.getStandardData();
            })
            .catch((err) => {
              this.loader = false;
              this.getStandardData();
              // this.notifyHead = "Ошибка";
              // this.notifyMessage = "Данные не удалены";
              // this.notifyClass = "wrapper-error";
              // this.showNotify = true;
              // setTimeout(() => {
              //   this.showNotify = false;
              // }, 2500);
              this.$bvModal.hide("standard_directory_created");
            });

          return;
        }
        this.loader = true;

        api
          .deleteTarifData(id)
          .then((response) => {
            this.loader = false;
            this.notifyHead = "Успешно";
            this.notifyMessage = "Данные удалены";
            this.notifyClass = "wrapper-success";
            this.showNotify = true;
            setTimeout(() => {
              this.showNotify = false;
            }, 2500);
            this.$bvModal.hide("AcTDelete");
            this.$bvModal.hide("standard_directory_created");
          })
          .catch((error) => {
            this.loader = false;
            this.notifyHead = "Ошибка";
            this.notifyMessage = "Данные не удалены";
            this.notifyClass = "wrapper-error";
            this.showNotify = true;
            setTimeout(() => {
              this.showNotify = false;
            }, 2500);
            console.log(error);
            this.$bvModal.hide("standard_directory_created");
          });
      },
    },
  };
</script>

<style scoped>
  .openAttachments {
    border: none !important;
    text-align: center;
    width: 100%;
    background: rgb(218, 218, 218) !important;
  }
  .openAttachmentsSec {
    border: none !important;
    text-align: center;
    width: 100%;
    background: rgb(253, 253, 253);
  }
  .openAttachmentsSec:hover{
    background: rgb(206, 226, 205);
  }
  .openAttachmentsSec:focus{
    background: rgb(172, 199, 171);

  }
  td input {
    text-align: center;
  }

  tr,
  td,
  th {
    border: 1px solid black;
  }
  label {
    margin: 0 !important;
  }
  #wrapper {
    margin: 0 auto;
    display: block;
    margin-top: 2%;
    max-width: 80%;
    width: 80%;
  }

  .page-header {
    text-align: center;
    font-size: 1.5em;
    font-weight: normal;
    border-bottom: 1px solid #ddd;
    margin: 30px 0;
  }

  #pagination {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  #pagination li {
    display: inline;
  }

  #pagination li a {
    display: inline-block;
    text-decoration: none;
    padding: 5px 10px;
    color: #000;
    cursor: pointer;
  }

  /* Active and Hoverable Pagination */
  #pagination li a {
    border-radius: 5px;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }

  #pagination li a.active_new {
    background-color: #18842a;
    color: #fff;
  }

  #pagination li a:hover:not(.active_new) {
    background-color: #ddd;
  }

  #pagination li a:not(.active123) {
    display: none;
  }

  /* border-pagination */
  .b-pagination-outer {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    display: flex;
  }

  #border-pagination {
    margin: 0 auto;
    padding: 0;
    text-align: center;
  }

  #border-pagination li {
    display: inline;
  }

  #border-pagination li a {
    display: block;
    text-decoration: none;
    color: #000;
    padding: 5px 10px;
    border: 1px solid #ddd;
    float: left;
  }

  #border-pagination li a {
    -webkit-transition: background-color 0.4s;
    transition: background-color 0.4s;
  }

  #border-pagination li a.active_new {
    background-color: #18842a;
    color: #fff;
  }

  #border-pagination li a:hover:not(.active_new) {
    background: #ddd;
  }

  .delete:hover {
    background: lightcoral;
    color: white;
  }

  .success {
    transition: 0.5s ease-in-out;
    background: rgba(42, 190, 67, 0.4);
    color: black;
  }

  .error {
    transition: 0.5 ease-in-out;
    background: lightcoral;
    color: black;
  }

  .WatchAllArenda {
    color: #929292;
    margin-top: 20%;
    font-size: 25px;
    cursor: pointer;
  }

  .inputcontainer {
    position: relative;
  }

  /* input,
  select {
    border: 1px solid grey !important;
    width: 100%;
    max-height: 35px !important;
    margin: 0 !important;
    box-sizing: border-box;
  } */

  .mini {
    height: 40px;
  }

  .icon-container {
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
  }

  .table-content {
    display: flex;
    flex-wrap: wrap; /* Позволяет элементам переноситься */
    gap: 10px; /* Пространство между элементами */
    margin-top: 20px; /* Отступ сверху для блока */
    border-radius: 4px;
    border: 1px solid rgb(216, 216, 216);
    padding: 2%;
    justify-content: flex-end; /* Выравнивание всех элементов по правому краю */
  }

  .table-content > * {
    margin-top: 20px; /* Отступ сверху для всех дочерних элементов */
  }

  .checkbox-label {
    display: flex; /* Чтобы текст и чекбокс были на одной линии */
    align-items: center; /* Центрируем по вертикали */
  }

  table {
    margin-top: 1%;
  }

  thead th {
    background: #ececec;
    font-family: "Montserrat", sans-serif;
  }

  li {
    cursor: pointer;
  }
</style>
