import { render, staticRenderFns } from "./BCH.vue?vue&type=template&id=5777032c&scoped=true"
import script from "./BCH.vue?vue&type=script&lang=js"
export * from "./BCH.vue?vue&type=script&lang=js"
import style0 from "./BCH.vue?vue&type=style&index=0&id=5777032c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5777032c",
  null
  
)

export default component.exports