<template>
  <div>
    <HeaderUIElement :is_visible_input="false">Заявка на создание рабочего места для сотрудника</HeaderUIElement>
    <div class="white_box">
      <div class="content">
        <label for="">
          Фамилия
          <span class="required">*</span>
          <input type="text" v-model="formData.last_name" />
        </label>
        <label for="">
          Имя
          <span class="required">*</span>
          <input type="text" v-model="formData.first_name" />
        </label>
        <label for="">
          Отчество
          <input type="text" v-model="formData.middle_name" />
        </label>
        <label for="">
          Должность
          <span class="required">*</span>
          <input type="text" v-model="formData.post" />
        </label>
        <label for="">
          Подразделение
          <span class="required">*</span>
          <input type="text" v-model="formData.department" />
        </label>
        <label for="">
          Рабочее место и доступы
          <span class="required">*</span>
          <textarea v-model="formData.work_place"></textarea>
        </label>
        <label for="">
          Дата начала
          <span class="required">*</span>
          <input type="date" v-model="formData.start_date" />
        </label>
        <label for="" class="last">
          Почты
          <span class="required">*</span>
          <v-select v-model="formData.emails" :options="fullFIO" multiple label="fio" class="select" style="margin-top: 1px" />
        </label>
      </div>
      <div class="button-container">
        <ButtonSendApplicationUIElement :fullData="formData" :nameApplication="'Заявка на создание рабочего места'"  />
      </div>
    </div>

    <span class="back" @click="$emit('clearForm', true)">
      <b-icon icon="chevron-left"></b-icon>
      Вернуться назад
    </span>
  </div>
</template>

<style lang="scss" scoped>
  .white_box {
    width: 50vw;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    padding: 2%;

    .content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 25px;

      input,
      textarea {
        height: 40px;
        width: 100%;
        border: var(--vs-border-width) var(--vs-border-style) var(--vs-border-color);
        border-radius: var(--vs-border-radius);
        padding: 4px 8px;
        background: var(--vs-search-input-bg);
      }

      textarea {
        resize: vertical;
        margin-top: 8px;
      }

      .last {
        grid-column: span 2;
      }

      .select {
        width: 100%;
      }

      .required {
        color: red; /* Цвет для обязательных полей */
        font-size: 0.9em; /* Размер шрифта для метки */
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }
</style>

<script>
  import { mapState } from "vuex";
  import HeaderUIElement from "../ui/HeaderUIElement.vue";
  import ButtonSendApplicationUIElement from "../ui/ButtonSendApplicationUIElement.vue";

  export default {
    components: { HeaderUIElement, ButtonSendApplicationUIElement },
    data() {
      return {
        formData: {
          last_name: "",
          first_name: "",
          middle_name: "",
          post: "",
          department: "",
          work_place: "",
          start_date: "",
          emails: [],
        },
      };
    },
    computed: {
      ...mapState({
        users: (state) => state.auth.users,
      }),
      fullFIO() {
        return this.users.map((user) => ({
          id: user.id,
          email: user.email,
          fio: `${user?.last_name ?? ""} ${user?.first_name ?? ""} ${user?.second_name ?? ""}`.trim(),
        }));
      },
    },

  };
</script>
