<script>
  import structure from "../files/structure.json";
  export default {
    name: "OrgStructure",
    props: {
      employees: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        orgStructure: structure,
        parentPath: [0],
        isDataReady: false,
        selectedDepartment: null, // Сотрудники выбранного отдела
        departmentInfo: null, // Информация о текущем отделе
        selectedEmployee: null, // Информация о выбранном сотруднике
        displayedManager: { fio: "Притула Дмитрий Владимирович", post: "Генеральный директор" },
        atEmployeeLevel: false, // Новый флаг для уровня сотрудников
        historyManager: [],
        commercialEmployees: [], // для отдела "Коммерческий отдел"
      };
    },
    async mounted() {},
    watch: {
      historyManager() {
        console.log(this.historyManager, "менеджеры");
      },
      employees: {
        immediate: true,
        handler(newEmployees) {
          if (newEmployees && newEmployees.length > 0) {
            this.isDataReady = true;
          }
        },
      },
    },
    computed: {
      currentLevel() {
        if (!this.isDataReady || this.selectedDepartment) return [];
        const level = this.parentPath.reduce((acc, index) => acc[index].subtree, this.orgStructure);
        return this.enrichWithEmployeeData(level);
      },
    },
    methods: {
      navigateToSubTree(item) {
        if (item.subtree && item.subtree.length > 0) {
          this.selectedDepartment = null;
          this.departmentInfo = null;
          this.atEmployeeLevel = false;
          this.parentPath.push(this.currentLevel.indexOf(item));

          if (item.fio) {
            this.displayedManager = { fio: item.fio, post: item.post };
            this.historyManager.push(item.fio);
          }
        } else {
          this.historyManager.push(this.historyManager.at(-1));

          const filteredByDate = item.users_department;

          const uniqueUsersMap = new Map();
          filteredByDate.forEach((emp) => {
            const key = `${emp.first_name} ${emp.last_name}`;
            if (!uniqueUsersMap.has(key)) {
              uniqueUsersMap.set(key, emp);
            } else {
              if (emp.middle_name) {
                uniqueUsersMap.set(key, emp);
              }
            }
          });

          this.selectedDepartment = Array.from(uniqueUsersMap.values());
          this.departmentInfo = { department: item.mergedDepartments || item.department, manager: item.fio };
          this.atEmployeeLevel = true;
        }
      },

      goBack() {
        if (this.atEmployeeLevel) {
          this.selectedDepartment = null;
          this.departmentInfo = null;
          this.atEmployeeLevel = false;
          this.historyManager.pop();
          this.displayedManager.fio = this.historyManager.at(-1);
        } else if (this.parentPath.length > 1) {
          this.parentPath.pop();
          this.selectedDepartment = null;
          this.departmentInfo = null;
          this.historyManager.pop();
          this.displayedManager.fio = this.historyManager.at(-1);

          if (this.parentPath.length === 1) {
            this.displayedManager = { fio: "Притула Дмитрий Владимирович", post: "Генеральный директор" };
          }
        }
      },

      enrichWithEmployeeData(level) {
        // Переменная для сотрудников "Коммерческого отдела"
        return level.map((item) => {
          if (item.department === "Коммерческий отдел") {
            // Сохраняем сотрудников "Коммерческого отдела"
            this.commercialEmployees = this.employees.filter((emp) => {
              const isCommercial = emp.department?.trim() === "Коммерческий отдел";
              return isCommercial;
            });
          }
          
          if (item.department === "Сектор по работе с универсальными грузами") {
            // Сотрудники "Сектора по работе с универсальными грузами"
            const sectorEmployees = this.employees.filter((emp) => emp.department?.trim() === "Сектор по работе с универсальными грузами");
            console.log(sectorEmployees, this.commercialEmployees, 'Сотрудники 2-х отделов')
            // Объединяем сотрудников двух отделов
            item.users_department = [...sectorEmployees, ...this.commercialEmployees];

            // Вывод для отладки
            console.log("Сотрудники сектора + коммерческого отдела:", item.users_department);

            // Устанавливаем объединённое название
            item.mergedDepartments = "Сектор по работе с универсальными грузами и Коммерческий отдел";
          } else if (item.department) {
            // Логика для остальных отделов
            item.users_department = this.employees.filter((emp) => emp.department?.toLowerCase().trim() === item.department?.toLowerCase().trim());
          }

          // Поиск начальника, если указана должность
          if (item.post) {
            const employee = this.employees.find((emp) => {
              const isSamePost = emp.post?.toLowerCase().trim() === item.post?.toLowerCase().trim();
              const hasMatchingDepartment = item.subtree?.some((sub) => sub.department?.toLowerCase().trim() === emp.department?.toLowerCase().trim());

              if (item.post === "Начальник отдела") return isSamePost && hasMatchingDepartment;
              return isSamePost;
            });

            if (employee) {
              item.fio = `${employee.last_name} ${employee.first_name} ${employee.middle_name || ""}`.trim();
            }
          }

          return item;
        });
      },

      correctAmount(item) {
        if (!item || !item.users_department) {
          return 0;
        }

        const val = item.users_department;

        const uniqueUsersMap = new Map();
        val.forEach((emp) => {
          const key = `${emp.first_name} ${emp.last_name}`;
          if (!uniqueUsersMap.has(key)) {
            uniqueUsersMap.set(key, emp);
          }
        });

        return uniqueUsersMap.size;
      },
    },
  };
</script>

<template>
  <div>
    <div v-if="employees.length > 0">
      <div class="company_structure">
        <div>
          <h4>Организационная структура ООО "Транспортные Технологии"</h4>
          <p class="manager">Начальник: {{ displayedManager.fio }}</p>
          <p class="text">Должность: {{ displayedManager.post }}</p>
        </div>
        <div class="company_structure_content">
          <template v-if="!selectedDepartment">
            <template v-for="item in currentLevel">
              <div @click="navigateToSubTree(item)" :key="JSON.stringify(item)" class="list_item">
                <p class="header">{{ item.mergedDepartments || item.department || item.post }}</p>
                <p class="description" v-if="item.fio">Начальник: {{ item.fio }}</p>
                <p v-if="item.subtree && item.subtree.length > 0" class="description_second">Отделов в подчинении: {{ item.subtree.length }}</p>
                <p v-else class="description_second">Персонала в отделе: {{ correctAmount(item) }}</p>
              </div>
            </template>
          </template>
        </div>
        <div v-if="selectedDepartment" class="users-department">
          <h4>{{ departmentInfo.department }}</h4>
          <p class="text">Сотрудники подразделения:</p>
          <div style="max-height: 30vh; height: auto; overflow: auto">
            <table class="custom-table">
              <thead>
                <tr>
                  <th>Фамилия</th>
                  <th>Имя</th>
                  <th>Отчество</th>
                  <th>Должность</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="emp in selectedDepartment">
                  <tr :key="emp.id">
                    <td>{{ emp.last_name }}</td>
                    <td>{{ emp.first_name }}</td>
                    <td>{{ emp.middle_name }}</td>
                    <td>{{ emp.post }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <button @click="goBack" class="backList" v-if="parentPath.length > 1 || selectedDepartment">
        <b-icon icon="chevron-left" scale="1"></b-icon>
        Вернуться назад
      </button>
    </div>
    <div v-else class="loading">
      <p>Загрузка организационной структуры...</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use "../style/style_org_structure.scss";
  @use "../style/style.scss";
</style>
