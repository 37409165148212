<script>
  import HeaderUIElement from "../ui/HeaderUIElement.vue";
  import api from "@/api/staff";
  import OrganizationalStructureModule from "./OrganizationalStructureModule.vue";
  import MyStaffModule from "./MyStaffModule.vue";
  import AwardsStaffModule from "./AwardsStaffModule.vue";
  import { mapState } from "vuex";
  import TemporarilyAbsentEmployee from "./TemporarilyAbsentEmployee.vue";
  export default {
    name: "StaffList",
    components: { HeaderUIElement, OrganizationalStructureModule, MyStaffModule, AwardsStaffModule, TemporarilyAbsentEmployee },
    data() {
      return {
        state: "",
        employees: [],
      };
    },
    computed: {
      ...mapState({
        uid: (state) => state.auth.user.user.id,
      }),
    },
    async mounted() {
      let employees = await api.getAllStaff({ page_size: 500 });
      this.employees = employees.data.data
      .filter((emp) => {
        if (!emp.employment_date && !emp.dismissal_date) {
          return true; // Сотрудник без дат считается активным
        }
        const dateJoined = new Date(emp.employment_date);
        const dismissalDate = new Date(emp.dismissal_date);
        return dateJoined > dismissalDate; // Активный сотрудник
      });
    },
    methods: {
      updateStaff(value) {},
    },
  };
</script>

<template>
  <div class="workspace" v-if="state === ''">
    <HeaderUIElement :is_visible_input="false" :placeholder_value="'Поиск сотрудника...'" @updateApplication="updateStaff">Список сотрудников</HeaderUIElement>
    <br />
    <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Организационная структура" active>
            <b-card-text style="margin-left: -8% !important">
              <OrganizationalStructureModule :employees="employees" />
            </b-card-text>
          </b-tab>
          <b-tab title="Временно отсутствующие сотрудники">
            <b-card-text style="margin-left: -8% !important">
              <TemporarilyAbsentEmployee />
            </b-card-text>
          </b-tab>
          <b-tab title="Мои сотрудники">
            <b-card-text style="margin-left: -8% !important">
              <MyStaffModule />
            </b-card-text>
          </b-tab>
          <b-tab title="Награждение сотрудников" v-if="uid == '104' || uid == '36' || uid == '374' || uid == '102'">
            <b-card-text style="margin-left: -8% !important">
              <AwardsStaffModule />
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "../style/style.scss";
</style>
