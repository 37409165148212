const cp_work_names = [
    ['Анкор',	'АНКОР',	"ООО АНКОР"],
    ['Атлант',	'АТЛАНТ',	"ООО Атлант"],
    ['ВАГОН',	'ВАГОН',	'ООО ВАГОН'],
    ['ВАГОН ПРОФИТ', 	'ВАГОН ПРОФИТ',	'ООО ВАГОН ПРОФИТ'],
    ['Вектор Рейл Актив',	'ВЕКТОР РЕЙЛ АКТИВ, ООО',	"ООО ВЕКТОР РЕЙЛ АКТИВ"],
    ["Грузовая Компания",	"Грузовая компания",	"ООО Грузовая компания"],
    ['ГС-Запад',	'Грузовая служба-Запад - ф-л Смоленск',	'Филиал ООО Грузовая служба запад в г. Смоленске'],
    ['Деловой Визит',	'Деловой Визит',	"ООО ДЕЛОВОЙ ВИЗИТ"],
    ['ДельтаХолдинг',	'ДельтаХолдинг',	"ООО ДельтаХолдинг"],
    ['Дулаанвинг',	'Дулаанвинг',	"КОО Дулаанвинг"],
    ['ИВК',	'ИВК',	"ООО Ивенстиционная Вагонная Компания"],
    ['ИКК',	'ИКК, АО',	"АО Интер Карго Компани"],
    ['ИТек Логистик',	'ИТЕК ЛОГИСТИК, АО',	"АО Итек-Логистик"],
    ['КОНЦЕРН ДЛС','	ДЛСГРУПП, ООО',	"АО КОНЦЕРН ДЛС"],
    ['Максима Логистик',	'Максима Логистик',	"ООО Максима Логистик"],
    ['ММСК',	"ММСК, ООО",	"ООО Медногорский медно-серный комбинат"],
    ["Нефтетранспорт",	"НЕФТЕТРАНСПОРТ, АО",	"АО НЕФТЕТРАНСПОРТ"],
    ["ОйлТранс",	"ОйлТранс",	"ООО ОйлТранс"],
    ["ПМХ-Транспорт",	'ПМХ',	"ООО ПМХ-Транспорт"],
    ["ПромСтройМаш",	"ПромСтройМаш",	"ООО Промышленные Строительные Машины"],
    ["Промтранс",	"ООО Промтранс-НК",	"ООО Промтранс"],
    ["РТС-Транс",	"РТС-ТРАНС, ООО",	"ООО РТС-Транс"],
    ["Рутгерс",	"РУТГЕРС СЕВЕРТАР, ООО", "ООО Рутгерс Севертар"],
    ["Софит",	"Софит",	"ООО Софит"],
    ["Союзметаллсервис",	"СОЮЗМЕТАЛЛСЕРВИС, ООО",	"ООО СОЮЗМЕТАЛЛСЕРВИС"],
    ["ТехРесурс",	"ТОО КАЗТЕХРЕСУРС",	"ООО Техресурс"],
    ["ТЛС",	"ТЛС",	"АО Транслизинг-сервис" ],
    ["Тонж логистик",	"Тонж логистик",	"ООО Тонж Логистик"],
    ["ТрансЛизинг",	"Транслизинг",	"ООО ТрансЛизинг"],
    ["ТЭК Транслин",	"ТЭК Транслин",	"ООО ТЭК Транслин"],
    ["ТЭК-ННЭ",	"ТЭК-ННЭ",	"ООО Транспортно-Экспедиторская Компания Нижегородский Экспресс"],
    ["Эдельвейс",	"Эдельвейс Транс",	"ТОО Эдельвейс Транс"],
    ["Энергоресурсы",	"Энергоресурсы",	"ООО Энергоресурсы"],
    ["АБЦ -РЕЙЛ","АБЦ -РЕЙЛ",		"ООО АБЦ-РЕЙЛ"],
    ["Эй-Си-Рейл","ЭЙ-СИ-РЕЙЛ, ООО", "ООО ЭЙ-Си-Рейл"],
    ["TRANSPORT TECHNOLOGIES GP", "TRANSPORT TECHNOLOGIES GP"	,	"ООО TRANSPORT TECHNOLOGIES GP"]
]


export default {
    cp_work_names
}