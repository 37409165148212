<template>
  <div class="long_search" @click="getRequestToServerData(search)" style="width: 100%">
    <!-- <input type="text" placeholder="Введите номера вагонов..." v-model="search" @input="IputProcessing(search)"> -->
    <!-- <b-button variant="success" class="btn btn-success" @click="getRequestToServerData(search)" style="display: flex; align-items: center; justify-content: center;"> -->
    <span v-if="isSearch">Запросить данные</span>
    <b-icon v-if="!isSearch" icon="three-dots" animation="cylon" font-scale="1.5"></b-icon>
    <!-- </b-button> -->
  </div>
</template>

<script>
  import api from "@/api/directory";
  import api_wagon from "@/api/wagonPark";
  export default {
    data() {
      return {
        isSearch: true,
        search: "",
        intervalResponse: null,
      };
    },
    watch: {
      search() {
        return this.search.length <= 1 ? (this.responseSearchData = null) : this.responseSearchData;
      },
    },
    methods: {
      IputProcessing(val) {
        clearInterval(this.intervalResponse);
        this.intervalResponse = setTimeout(() => {
          this.getRequestToServerData(val);
        }, 500);
      },

      async getRequestToServerData(search) {
        // if (this.search == "" || this.search.length <= 1) return
        this.isSearch = false;
        let obj = { wagons: search };
        let today = new Date().toISOString().slice(0, 10);
        let allData = [];
        let notInsuredData = [];

        try {
          // Очищаем таблицы
          this.$emit("clearTableData");
          // Получаем застрахованные вагоны
          let last_page = 1;
          let response = await api.getAllInsuranceWagons(obj, last_page);
          allData.push(...response.data.data);
          while (last_page < response.data.total_pages) {
            last_page += 1;
            let res = await api.getAllInsuranceWagons(obj, last_page);
            allData.push(...res.data.data);
          }

          // Для каждого элемента добавляем дочерние элементы
          for (let i of allData) {
            i.__children = [];
          }

          // Получаем незастрахованные вагоны циклом по страницам
          let last_page_not_insured = 1;
          let response2 = await api_wagon.getNotInsuredWagons(last_page_not_insured);
          notInsuredData.push(...response2.data.data);
          while (last_page_not_insured < response2.data.total_pages) {
            last_page_not_insured += 1;
            let res2 = await api_wagon.getNotInsuredWagons(last_page_not_insured);
            notInsuredData.push(...res2.data.data);
          }
          // Уведомление об успешной загрузке
          this.$toast.success("Данные по разделу загружены", {
            timeout: 3000,
          });

          // Форматирование дат и других полей
          allData.forEach((item) => {
            item.agr_date = item?.agr_date?.split("-").reverse().join(".") || null;
            item.agr_date_end = item?.agr_date_end?.split("-").reverse().join(".") || null;
            item.build_date = item?.build_date?.split("-").reverse().join(".") || null;
            item.last_operation_date = item?.last_operation_date?.split("-").reverse().join(".") || null;
            item.state_change_date = item?.state_change_date?.split("-").reverse().join(".") || null;
            item.lifetime = item?.lifetime?.split("-").reverse().join(".") || null;
            item.insurance_sum = item?.insurance_sum?.toFixed(2) || null;
            item.on_balance_1c = item?.on_balance_1c ? "Да" : "Нет";
          });
          
          // Отправка данных через события
          this.$emit("getInsuredWagons", allData);
          this.$emit("getOwnWagonsCompare", notInsuredData);

          this.isSearch = true;
        } catch (err) {
          console.log(err);
          this.isSearch = true;
        }
      },
    },
  };
</script>

<style scoped>
  /* .long_search {
    position: relative;
    width: 90%;
    margin: 0 auto;
}

.long_search {
    position: relative;
    width: 100%;
    height: 110%;
}

.long_search input {
    width: 100%;
    height: 100%;
    border: 1px solid #007BFF !important;
    border-radius: 10px;
    text-align: left !important;
    padding-left: 2% !important;
} */

  ::-webkit-input-placeholder {
    text-align: left;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    text-align: left;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    text-align: left;
  }

  :-ms-input-placeholder {
    text-align: left;
  }

  .long_search button {
    min-width: 13vw;
    width: auto;
    height: 4vh;
    /* position: absolute;
    top: 4px;
    right: 5px; */

    border-radius: 8px;
  }
</style>
