import { render, staticRenderFns } from "./Administartion_agreement.vue?vue&type=template&id=24d1a786&scoped=true"
import script from "./Administartion_agreement.vue?vue&type=script&lang=js"
export * from "./Administartion_agreement.vue?vue&type=script&lang=js"
import style0 from "./Administartion_agreement.vue?vue&type=style&index=0&id=24d1a786&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24d1a786",
  null
  
)

export default component.exports