<template>
  <div>
    <Loader :loader="loader" />

    <div class="air_block">
      <div class="air_block_header">
        <h5>Данные по продлению срока ввоза</h5>
        <hr />
      </div>
      <br />
      <div class="air_block_content">
        <input type="text" class="air_block_content__input" v-model="wagons" placeholder="№ вагонов" />
        <vue-select v-model="CountriesList" :options="CountriesUnique" multiple placeholder="Выберите страну для 1 таблицы..." style="width: 100%" />
        <b-button-toolbar>
          <b-button-group style="display: flex">
            <b-button size="sm" class="mb-2 border rounded p-2" style="width: auto; white-space: nowrap; background: #264722" @click="SendExtension()">
              <b-icon icon="cloud-download" aria-hidden="true" focusable="false"></b-icon>
              Запросить данные
            </b-button>
            <b-button v-if="InformationByWagon.length > 0" size="sm" class="mb-2 border rounded p-2" style="width: auto; white-space: nowrap; background: #242C47" @click="DownloadExcel('Данные по продлению срока ввоза', 'table1')">
              <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
              Выгрузить в Excel
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </div>
      <br />
      <div class="air_block_content" style="width: 100%; overflow: auto">
        <table class="custom-table" ref="table1">
          <thead>
            <tr>
              <th>Вагон</th>
              <th>Текущая страна</th>
              <th>Текущая станция</th>
              <th>Дата последней операции</th>
              <th>Операция</th>
              <th>Станция назначения</th>
              <th>Сутки от последней операции</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in InformationByWagon" :key="item.id">
              <td>{{ item?.wagon }}</td>
              <td>{{ item?.current_country }}</td>
              <td>{{ item?.current_station__name }}</td>
              <td>{{ item?.last_operation_datetime.slice(0, 10)?.split("T")[0].split("-").reverse().join(".") }}</td>
              <td>{{ item?.operation }}</td>
              <td>{{ item?.destination_station__name }}</td>
              <td>{{ DayByLastOperation(item?.last_operation_datetime.slice(0, 10)) | filtersValue }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <div class="air_block_header" style="display: flex; justify-content: space-between; width: 100%">
        <h5>Данные из дислокаций</h5>
        <b-button  v-if="resDislocation.length > 0" size="sm" class="mb-2 border rounded p-2" style="width: auto; white-space: nowrap; background: #242C47" @click="DownloadExcel('Данные из дислокаций', 'table2')">
          <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
          Выгрузить в Excel
        </b-button>
      </div>
      <hr />
      <div class="air_block_content" style="width: 100%; overflow: auto">
        <table class="custom-table" ref="table2">
          <thead>
            <tr>
              <th>Вагон</th>
              <th>Текущая страна</th>
              <th>Текущая станция</th>
              <th>Дата последней операции</th>
              <th>Операция</th>
              <th>Станция назначения</th>
              <th>Сутки от последней операции</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in resDislocation" :key="item.id">
              <td>{{ item.data.data[0]?.wagon }}</td>
              <td>{{ item.data.data[0]?.current_country }}</td>
              <td>{{ item.data.data[0]?.current_station_name }}</td>
              <td>{{ item.data.data[0]?.last_operation_datetime?.split("T")[0].split("-").reverse().join(".") }}</td>
              <td>{{ item.data.data[0]?.operation }}</td>
              <td>{{ item.data.data[0]?.destination_station_name }}</td>
              <td>{{ DayByLastOperation(item.data.data[0]?.last_operation_datetime?.split("T")[0]) | filtersValue }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <div class="air_block_header">
        <h5>Данные из рейсов</h5>
        <hr />
      </div>
      <div class="air_block_content" style="width: 100%; overflow: auto">
        <table class="custom-table">
          <thead>
            <tr>
              <th>Вагон</th>
              <th>Полигон</th>
              <th>Станция отправления</th>
              <th>Дорога отправления</th>
              <th>Станция назначения</th>
              <th>Дорога назначения</th>
              <th>Дата прибытия ЭТРАН</th>
              <th>Сутки от последней операции</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in maxDeliveryDateRecord" :key="item.id">
              <td>{{ item?.wagon }}</td>
              <td>{{ item?.polygon }}</td>
              <td>{{ item?.departure_station.name }}</td>
              <td>{{ item?.departure_station?.road?.name }}</td>
              <td>{{ item?.destination_station?.name }}</td>
              <td>{{ item?.destination_station?.road?.name }}</td>
              <td>{{ item?.delivery_date_etran?.split("T")[0] | filtersDate }}</td>
              <td>{{ DayByLastOperation(item?.delivery_date_etran?.split("T")[0]) | filtersValue }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/api/wagonPark";
  import MultiSelectUni from "@/components/ui/MultiSelectUni.vue";
  import { mapState } from "vuex";
  import Loader from "@/components/loader/loader.vue";
  import VueSelect from "vue-select";
  import * as XLSX from "xlsx";

  export default {
    components: { MultiSelectUni, Loader, VueSelect },
    data() {
      return {
        CountriesList: [],

        visible: true,
        loader: false,
        wagons: "",
        tableWagon: false,
        wagonsTable: "",
        selectedCountriesIds: [],
        InformationByWagon: "",
        watchInformation: false,
        maxDeliveryDateRecord: [],
        resDislocation: [],
      };
    },

    watch: {
      wagons() {
        let str = this.wagons.replace(/[^0-9]/g, "");
        let arr = str.match(/.{1,8}/g);
        this.wagonsTable = arr;
      },
    },
    filters: {
      filtersValue(val) {
        return Math.abs(val);
      },
      filtersDate(val) {
        return val.split("-").reverse().join(".");
      },
    },
    computed: {
      ...mapState({
        countries: (state) => state.countries.countrie,
      }),
      CountriesUnique() {
        let uniqueData = this.countries.map((item) => item.trim());
        return Array.from(new Set(uniqueData));
      },

      CountrieObj() {
        //1
        const result = [];
        this.countries.forEach((el, idx) => {
          el && result.push({ id: idx, value: el });
        });
        return result;
      },
      selectedCountries() {
        //2
        return this.CountrieObj.filter((el) => this.selectedCountriesIds.includes(el.id));
      },
    },

    methods: {
      DayByLastOperation(date) {
        return Math.ceil((new Date(date) - new Date()) / (1000 * 3600 * 24));
      },
      async SendExtension() {
        this.maxDeliveryDateRecord = [];
        this.resDislocation = [];
        this.loader = true;
        let countries = [];
        this.CountriesList.forEach((item) => {
          countries.push(item);
        });
        let data = {
          wagons: this.wagonsTable ? this.wagonsTable.join(",") : null,
          countries: countries ? countries.join(",") : null,
        };
        try {
          let response = await api.getExtensionData(data);

          let res = this.wagonsTable.map((item) => api.getDislocationByWagon(item));
          let resDislocation = await Promise.all(res);
          console.log(resDislocation, "resDislocation");
          this.resDislocation = resDislocation;

          let promises = this.wagonsTable.map((item) => api.getFlights(item));
          let flight = await Promise.all(promises);
          let result = [];

          // Извлекаем записи с максимальной датой
          for (let i in flight) {
            result.push(flight[i].data.data.sort((a, b) => new Date(b.delivery_date_etran) - new Date(a.delivery_date_etran))[0]);
          }
          this.maxDeliveryDateRecord = result;

          let stationCache = {};

          // Функция для получения названия станции с мемоизацией
          const getStationName = async (stationCode) => {
            if (stationCache[stationCode]) {
              // Если название станции уже в кэше, возвращаем его
              return stationCache[stationCode];
            } else {
              const response = await api.getCurrentStationByCode(stationCode);
              const stationName = response.data.data[0];
              stationCache[stationCode] = stationName;
              return stationName;
            }
          };

          let promiseStationNameDeparture = this.maxDeliveryDateRecord.map((item) => getStationName(item.departure_station));
          let promiseStationNameDestination = this.maxDeliveryDateRecord.map((item) => getStationName(item.destination_station));

          let [departures, destinations] = await Promise.all([Promise.all(promiseStationNameDeparture), Promise.all(promiseStationNameDestination)]);

          for (let i = 0; i < this.maxDeliveryDateRecord.length; i++) {
            this.maxDeliveryDateRecord[i].departure_station = departures[i];
            this.maxDeliveryDateRecord[i].destination_station = destinations[i];
          }

          console.log(this.maxDeliveryDateRecord, "Обновленные результаты с названиями станций.");
          this.InformationByWagon = response.data;
          this.watchInformation = true;
          this.loader = false;
        } catch (err) {
          console.debug(err);
          this.loader = false;
        }
      },

      DownloadExcel(name, typeRef) {
  const fileName = `Таблица '${name}'.xlsx`;
  let  table 
  if(typeRef == "table1") table = this.$refs.table1
  else if(typeRef == "table2") table = this.$refs.table2
  const wb = XLSX.utils.table_to_book(table);
  XLSX.writeFile(wb, fileName);
},


      updateSelectedCountries(selected) {
        this.selectedCountriesIds = selected;
      },
      removeselectedCountries(id) {
        this.selectedCountriesIds.splice(this.selectedCountriesIds.indexOf(id), 1);
      },
    },
  };
</script>

<style scoped>
  @import url("./style/style_ex_period.scss");
  th,
  td {
    white-space: nowrap;
    padding: 0 10px !important;
  }
</style>
