import { render, staticRenderFns } from "./WPMainData.vue?vue&type=template&id=c192b592&scoped=true"
import script from "./WPMainData.vue?vue&type=script&lang=js"
export * from "./WPMainData.vue?vue&type=script&lang=js"
import style0 from "./WPMainData.vue?vue&type=style&index=0&id=c192b592&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c192b592",
  null
  
)

export default component.exports