<template>
  <div class="block_translucent">
    <span style="position: absolute; top: 15px; right: 15px" @click="$emit('closeElement')">❌</span>
    <div class="content">
      <img src="../files/sticker2.webp" alt="" class="talisman" v-if="currentSlideIndex < 3 || currentSlideIndex === 5 || currentSlideIndex === 6" />
      <img src="../files/sticker.webp" alt="" class="talisman2" v-if="currentSlideIndex === 3 || currentSlideIndex === 4" />

      <div class="first_step" v-if="currentSlideIndex === 0">
        <img src="@/assets/logo2.png" alt="Логотип компании" class="logo_comp" />

        <div class="text">
          <h5 align="center">Привет! Добро пожаловать в нашу команду!</h5>
          <h5 align="center">Мы очень рады, что ты теперь часть</h5>
          <h5 align="center">ООО «Транспортные Технологии»!</h5>
          <p>
            Процесс адаптации на новом рабочем месте - всегда непросто и волнительно. Мы тебя понимаем, как никто другой, ведь каждый из нас прошёл этот путь. Но мы можем пообещать тебе только одно –
            у нас этот процесс будет интересным и насыщенным, и на этом пути тебя будут ждать задания, которые помогут понять систему нашей работы и узнать команду.
          </p>
          <p>
            Итак, в Отделе по работе с персоналом ты получил карту нового сотрудника. И раз уж этот год для нас космический, то процесс адаптации новичка можно сравнить с курсом молодого бойца в
            центре подготовки космонавтов. Ты готов начать? Тогда мы тебе расскажем основные этапы:
          </p>

          <p>
            <img src="../assets/map.jpg" alt="карта" class="leftimg" />
            Карта, которую ты держишь в руках – поможет тебе в первый месяц работы. В определенные дни там тебя будут ждать разные задания, будь внимателен, за их выполнение даются наклейки, которые
            нужно приклеивать на карту. Звучит как детская игра? Ну, может быть немного, но за этим стоят реальные «рабочие» задачи, выполнение которых в будущем тебе сослужат хорошую службу. В конце
            месяца мы встретимся и проследим твой прогресс.
          </p>

          <p>
            <img src="../assets/comp.jpg" alt="Компьютер" class="leftimg" />
            Сегодня тебя подключат к адаптационному курсу, который можно не спеша пройти в течение месяца. Там ты сможешь найти всю общую информацию, которая понадобится тебе от графика работы и
            зарплаты, до философии и ценностей команды).
          </p>

          <p>
            <img src="../assets/users_table.jpg" alt="Сотрудники" class="leftimg" />
            В течение 2 первых недель руководитель отдела проведет вводную встречу, на которой расскажет про процессы работы отдела, и вы вместе создадите план обучения на первое время. Не стесняйся
            уточнять все, что показалось непонятным.
          </p>
          <br />
          <p>
            <img src="../assets/watch.jpg" alt="Часы" class="leftimg" />
            В первый месяц работы с тобой встретится Первый заместитель Генерального директора – нашей компании – Кузьмичев Алексей Сергеевич. На встрече он расскажет тебе про глобальную философию
            нашей компании, про ее планы и миссию. Ты прошёл большой путь отбора и попал в команду, а это слово для нас значит очень много.
          </p>

          <p>Ты всегда можешь попросить помощи у всех коллег и своего непосредственного руководителя. Для тебя мы на связи 24/7 по любым вопросам.</p>

          <p style="float: right">
            --- Желаем тебе интересного пути и успехов в работе!
            <br />
            С уважением, Коллеги ООО «Транспортные Технологии»
          </p>
        </div>
        <b-button variant="info" @click="nextSlide" size="sm">Продолжить</b-button>
      </div>
      <div class="first_step second" v-if="currentSlideIndex === 1">
        <img src="@/assets/logo2.png" alt="Логотип компании" class="logo_comp2" />

        <div class="text">
          <h6 style="color: #0070c0">О компании:</h6>
          <p>
            ООО «Транспортные Технологии» (ТТ) – независимый оператор железнодорожного подвижного состава. В группу компаний входят ТОО «GLP OPERATOR» (Казахстан) и ООО «TRANSPORT TECHNOLOGIS GP»
            (Узбекистан)
          </p>
          <p>
            Компания начала свою работу в марте 2015 года с парком чуть более 1 000 вагонов и уже почти 10 лет динамично развивается, укрепляя свои позиции на рынке. По итогам первого полугодия 2024
            года парк группы компаний составляет 17 511 вагонов.
          </p>
          <div style="display: flex; justify-content: space-evenly">
            <img src="../assets/f1.png" alt="" />
            <img src="../assets/f2.png" alt="" />
          </div>
          <p>Основополагающими принципами построения устойчивой модели бизнеса Компании являются:</p>
          <ul>
            <li>Баланс в крупнейших рыночных сегментах;</li>
            <li>Распределение финансовой нагрузки между собственным, лизинговым, арендованным и привлеченным подвижным составом</li>
          </ul>
          <h6 style="color: #0070c0">Компания ставит перед собой амбициозные стратегические цели</h6>
          <ul>
            <li>Повышение качества транспортных услуг при соблюдении принципа клиентоориентированности в условиях резко меняющегося рынка</li>
            <li>Увеличение рыночной доли Компании</li>
          </ul>

          <h6 style="color: #0070c0">Задачи</h6>
          <ul>
            <li>Повышение уровня клиентоориентированности</li>
            <li>Увеличение скорости реакции на вызовы рынка</li>
            <li>Расширение географии перевозок и логистической эффективности</li>
            <li>Диверсификация клиентского портфеля</li>
            <li>Увеличение количества парка цистерн и полувагонов в управлении</li>
            <li>Автоматизация бизнес-процессов</li>
          </ul>

          <h6 style="color: #0070c0">Услуги Компании</h6>
          <p>
            ТТ предоставляет полный комплекс услуг по транспортировке нефтепродуктов, насыпных и генеральных грузов на всей территории Российской Федерации и сопредельных государств. Наша Компания
            имеет высокие компетенции для соответствия жестким требованиям, предъявляемым к качеству вагонов в соответствии с руководящими документами ОАО «РЖД» и отраслевыми стандартами.
          </p>
          <ul>
            <li>Предоставление полувагонов и цистерн под погрузку грузов, разрешенных к перевозке по сети железных дорог России и сопредельных государств</li>
            <li>Полноценное оперированием вагонным парком включая функцию грузоотправителя и обеспечение отправителя грузов по согласованным графикам</li>
            <li>Экспедирование грузов включая оплату провозных платежей по территории РФ и сопредельных государств</li>
            <li>Организация ремонта вагонов планового и текущего отцепочного</li>
            <li>Передача вагонов в аренду долгосрочную и краткосрочную</li>
          </ul>
          <p>
            Качественную и эффективную работу компании обеспечивает штат высококвалифицированных работников, имеющих за своими плечами огромный опыт нахождения оптимальных и эффективных методов
            решения транспортно-логистических задач в этом бизнесе.
          </p>

          <h6 style="color: #0070c0">Правила внутреннего трудового распорядка:</h6>

          <h5 align="center" style="color: #00b050; font-family: 'Arial Black'">Режим труда и отдыха</h5>
          <h5 align="center" style="color: #0070c0">Общепринятый график работы пятидневный</h5>
          <div style="display: flex; align-items: center; justify-content: space-evenly">
            <div style="display: flex; flex-direction: column; align-items: center">
              <h6>ПН-ЧТ</h6>
              <h6>9:00 – 18:00</h6>
            </div>
            <div style="display: flex; flex-direction: column; align-items: center">
              <h6>Пт.</h6>
              <h6>9:00 – 16:45</h6>
            </div>
          </div>
          <br />
          <h5 align="center" style="color: #0070c0">Режим работы диспетчерского центра определяется графиком работы</h5>
          <h6 align="center">8:00 – 20:00</h6>
          <b align="center">Перерыв для отдыха и питания продолжительностью 45 мин. без временных ограничений.</b>
          <p>
            В офисе имеется кухня, где есть все необходимое в круглосуточном доступе. Обед можно приносить с собой или купить готовую продукцию в магазине. Также можно пообедать в кафе на 1-м этаже в
            офисе Башня 2000.
          </p>
          <p>
            В компании ведется строгий учет рабочего времени, опоздания не приветствуются. Если Вы опаздываете или нужно пораньше уйти с работы, необходимо написать служебную записку с пояснениями в
            отдел по работе с персоналом и службу безопасности
          </p>

          <h6 style="color: #0070c0">Отпуск</h6>
          <p>В Компании предоставляется ежегодный оплачиваемый отпуск, продолжительностью 28 календарных дней.</p>
          <table class="table_wo_border">
            <tr>
              <td><img src="../assets/propusk.jpg" alt="Пропускная система" style="width: 120px" /></td>
              <td><img src="../assets/magazine.jpg" alt="Журнал командировок" style="width: 100px" /></td>
              <td><img src="../assets/scales.jpg" alt="Весы" style="width: 80px" /></td>
            </tr>
            <tr>
              <td><p style="font-family: 'Arial Black'; margin-top: 5px">Пропускная система</p></td>
              <td><p style="font-family: 'Arial Black'; margin-top: 5px">Журнал учета местных командировок</p></td>
              <td><p style="font-family: 'Arial Black'; margin-top: 5px">Баланс фактически отработанного времени</p></td>
            </tr>
          </table>
          <span style="font-size: 12px">* Правила внутреннего трудового распорядка (Приказ от «16» декабря 2019 года № 81)</span>
          <h6 style="color: #0070c0">Деловая этика</h6>
          <h5 align="center" style="font-family: 'Arial Black'; color: #00b050">Дресс-код</h5>
          <table style="width: 100%">
            <tr>
              <td style="width: 30%">
                <img src="../assets/dress_code.jpg" alt="Дресс код" style="width: 80%" />
              </td>
              <td style="width: 70%">
                <p align="justify" style="margin: 2px">
                  В рабочие дни с понедельника по четверг сотрудники Компании должны соблюдать деловой стиль в одежде. По пятницам допустим, как деловой, так и свободный стиль одежды. Обращаем Ваше
                  внимание: одежда должна быть чистой, опрятной и выглаженной, а сотрудники должны соблюдать правила личной гигиены.
                </p>
              </td>
            </tr>
          </table>
          <span style="font-size: 12px">* Кодекс корпоративного поведения работников (Приказ № 31 от «31» октября 2018 г.)</span>

          <br />
          <h5 align="center" style="font-family: 'Arial Black'; color: #00b050">Социальные льготы, гарантии и компенсации</h5>
          <ul>
            <li><b style="color: #4472c4">Полис добровольного медицинского страхования работнику</b></li>
            <li><b style="color: #4472c4">Компенсация фитнес</b></li>
            <li><b style="color: #4472c4">Компенсация корпоративной связи</b></li>
            <li><b style="color: #4472c4">Подарки для детей сотрудников на Новый год</b></li>
            <li><b style="color: #4472c4">Тематические пятницы</b></li>
            <li><b style="color: #4472c4">Проведение мастер – классов</b></li>
            <li><b style="color: #4472c4">TEAM Bulding</b></li>
          </ul>
          <br />
          <table class="table_wo_border">
            <tr>
              <td><b style="color: #00b050">Материальная помощь</b></td>
              <td><b style="color: #00b050">Развитие персонала</b></td>
              <td><b style="color: #00b050">Поддержка материнства</b></td>
            </tr>
            <tr>
              <td>
                <li><b style="color: #4472c4">при заключении брака</b></li>
              </td>
              <td>
                <li><b style="color: #4472c4">повышение квалификации</b></li>
              </td>
              <td>
                <li><b style="color: #4472c4">единовременное пособие при рождении ребенка</b></li>
              </td>
            </tr>
            <tr>
              <td>
                <li><b style="color: #4472c4">при рождении ребенка</b></li>
              </td>
              <td>
                <li><b style="color: #4472c4">обучение внутренними ресурсами</b></li>
              </td>
              <td>
                <li><b style="color: #4472c4">пособие по уходу за ребенком</b></li>
              </td>
            </tr>
            <tr>
              <td>
                <li><b style="color: #4472c4">смерть близкого родственника</b></li>
              </td>
            </tr>
          </table>
          <br />
          <br />
          <h6 align="center" style="font-family: 'Arial Black'; color: #00b050">Ежемесячная доплата за выслугу лет (непрерывный стаж работы в Компании)</h6>
          <ul>
            <li style="color: #4472c4"><b>непрерывный стаж в компании от 2х лет-в размере 7%</b></li>
            <li style="color: #4472c4"><b>непрерывный стаж в компании от 3х лет-в размере 10%</b></li>
            <li style="color: #4472c4"><b>непрерывный стаж в компании от 5ти лет-в размере 13%</b></li>
          </ul>
          <h5 align="center" style="color: #4472c4">Уровни признания заслуг работников</h5>
          <h5 align="center" style="font-family: 'Arial Black'; color: #00b050">Награды в Компании</h5>
          <table style="width: 100%">
            <tr>
              <td>
                <ul>
                  <li><h5 align="left" style="color: #4472c4">Почетная грамота</h5></li>
                  <li><h5 align="left" style="color: #4472c4">Благодарственное письмо</h5></li>
                  <li><h5 align="left" style="color: #4472c4">Благодарность</h5></li>
                  <li><h5 align="left" style="color: #4472c4">Ценный подарок</h5></li>
                </ul>
              </td>
              <td>
                <img src="../assets/awards.jpg" alt="Награда" style="width: 60%" />
              </td>
            </tr>
          </table>
          <br />
          <h6 align="center" style="color: #0070c0">Формирование рейтинга признания</h6>
          <p>
            <span style="font-size: 1.25em; font-weight: bold; color: red">П</span>
            – профессионализм, что подразумевает высокий уровень профессиональных компетенций, их постоянное развитие и совершенствование;
          </p>
          <p>
            <span style="font-size: 1.25em; font-weight: bold; color: red">Р</span>
            – результативность, что предполагает достижение высоких результатов профессиональной деятельности;
          </p>
          <p>
            <span style="font-size: 1.25em; font-weight: bold; color: red">О</span>
            – обновление, что подразумевает постоянный поиск и внедрение передовых инструментов и практик при осуществлении профессиональной деятельности, готовность реагировать и меняться в
            соответствии потребностями бизнеса;
          </p>
          <p>
            <span style="font-size: 1.25em; font-weight: bold; color: red">Движение</span>
            – предполагает постоянное стремление к поставленной цели, продвижению по карьерной лестнице, продвижение в рейтинге системы признания, а также непрерывное развитие и самосовершенствование.
          </p>
          <br />
          <h5 align="center" style="font-family: 'Arial Black'; color: #00b050">Заработанная плата</h5>
          <table style="width: 100%">
            <tr>
              <td>
                <h5 align="center" style="color: #4472c4">Постоянная часть- должностной оклад</h5>
                <b>Определяется:</b>
              </td>
            </tr>
          </table>
          <br />

          <table>
            <tr style="border: none">
              <td style="border: none"><img src="../assets/down-arrow.png" alt="стрелка вниз" /></td>
              <td style="border: none"><img src="../assets/down-arrow.png" alt="стрелка вниз" /></td>
              <td style="border: none"><img src="../assets/down-arrow.png" alt="стрелка вниз" /></td>
              <td style="border: none"><img src="../assets/down-arrow.png" alt="стрелка вниз" /></td>
            </tr>
            <tr>
              <td style="font-size: 14px">
                <b>Уровень профессионального потенциала</b>
                (образование и опыт работы)
              </td>
              <td style="font-size: 14px">
                <b>Профессиональные компетенции</b>
                (уровень знаний и навыков, необходимых для исполнения должностных обязанностей)
              </td>
              <td style="font-size: 14px"><b>Уровень заработной платы, сложившийся на рынке труда</b></td>
              <td style="font-size: 14px">
                <b>Условия, характер труда:</b>
                напряженность; срочный характер работы и т.д.
                <br />
                <b>Высокая степень востребованности:</b>
                необходимость оперативного укомплектования; дефицитность профессии и т.д.
              </td>
            </tr>
          </table>

          <br />
          <h5 align="center" style="font-family: 'Arial Black'; color: #0070c0">Сроки выплаты заработной платы:</h5>
          <h6 align="center">- аванс 22 числа текущего месяца</h6>
          <h6 align="center">- окончательный расчет – 07 числа следующего месяца</h6>

          <h6><i style="color: #4975c5">Рабочие моменты:</i></h6>
          <p>
            При первом входе в свою учетную запись домена логин будет содержать первую букву вашего имени, точку и фамилию в латинской раскладке (например: i.ivanov). Пароль по умолчанию
            Tehtrans*текуший год (например: Tehtrans2024). Также при входе, windows сообщит вам, что необходимо сменить пароль на свой.
          </p>
          <p>
            На общем диске –
            <u>.Z:\Обмен находится вся необходимая для дальнейшей работы информация (рабочие папки, справочник сотрудников, реквизиты и т.д.).</u>
          </p>
          <p>При возникновении неполадок в работе компьютера, а также его настройках, необходимо:</p>
          <ul>
            <li>создать обращение в службу технической поддержки (Z-общая-инструкция-инструкция ZAMMAD)</li>
            <li>либо позвонить по тел. 177 или 298</li>
          </ul>
          <p>По административным вопросам можно обращаться к секретарю ресепшен (доб. 202)</p>
        </div>
        <b-button variant="info" @click="nextSlide" size="sm">Продолжить</b-button>
      </div>
      <div class="block_description" v-if="currentSlideIndex === 2 && step2State === 1">
        <p class="typing-text">
          <span class="line">Коллега, давай знакомиться!</span>
          <span class="line">Меня зовут Вагончик, я очень открытый и искренний, люблю узнавать что-то новое через чтение научной литературы.</span>
          <span class="line">А еще я очень требовательный и люблю порядок в работе.</span>
          <span class="line">Ты, наверное, уже смог в этом убедиться, когда знакомился с локальными документами Компании.</span>
        </p>
        <b-button v-if="step2State === 1" variant="info" @click="changeStep2Text" size="sm">Далее</b-button>
      </div>
      <div class="block_description" v-if="currentSlideIndex === 2 && step2State === 2">
        <p class="typing-text">
          <span class="line">А еще я люблю не только много работать, но и отдыхать!</span>
          <span class="line">Всегда провожу время с пользой, много гуляю, играю, принимаю участие в соревнованиях!</span>
          <span class="line">Ой, что-то я заговорился, перейди по ссылке и посмотри все сам!</span>
          <span class="line"><a href="https://disk.yandex.ru/d/JHWWzLJLzNBJJg/20200125110231_Priroda_10-344.jpg" target="_blank">Здесь фото и видео наших будней и праздников :)</a></span>
        </p>
        <b-button variant="info" @click="nextSlide" size="sm">Можно дальше, пожалуй</b-button>
      </div>
      <!-- Хобби -->
      <div class="block_description2" v-if="currentSlideIndex === 3">
        <p class="typing-text">
          <span class="line">А теперь ты расскажи мне о себе.</span>
          <span class="line">Чем любишь заниматься в свободное время?</span>
          <span class="line">Какие есть увлечения и хобби?</span>
          <span class="line">Почему решил присоединиться к нашей команде?</span>
        </p>
      </div>
      <div class="hobbies_user" v-if="currentSlideIndex === 3">
        <div class="hobbies_user__header">
          <p>Хобби и увлечения</p>
        </div>
        <div class="hobbies_user__content">
          <p>Введите свои или выберите на основе предложенных вашими коллегами</p>
          <div class="hobbies_user__content__list">
            <input type="text" v-model="hobby_1" />
            <input type="text" v-model="hobby_2" />
            <input type="text" v-model="hobby_3" />
          </div>
          <p>Варианты предложенные коллегами</p>
          <div class="hobbies_user__content__list">
            <v-select style="width: 90%" v-model="allHobbies" :options="hobbyStaff" label="hobby" multiple />
          </div>
          <b-button style="width: auto; margin: 4% 5% 2% auto" variant="info" @click="nextSlide" size="sm">Сохранить данные и продолжить</b-button>
        </div>
      </div>
      <!-- Корпоратив -->
      <div class="block_description2" v-if="currentSlideIndex === 4">
        <p class="typing-text">
          <span class="line">Здорово!! Ты молодец!</span>
          <span class="line">Я думаю, что с нами ты не заскучаешь!</span>
          <span class="line">У нас веселая и очень артистичная команда!</span>
          <span class="line"><a href="https://disk.yandex.ru/i/Hg_9QgWOTLY3PQ" target="_blank">Посмотри небольшой ролик с нашим коллективом</a></span>
        </p>
        <b-button variant="info" @click="nextSlide" size="sm">Продолжить</b-button>
      </div>
      <div v-if="currentSlideIndex === 4">
        <canvas ref="fireworksCanvas" class="fireworks-canvas"></canvas>
      </div>
      <!-- Правила -->
      <div class="block_description" v-if="currentSlideIndex === 5">
        <p class="typing-text">
          <span class="line">Немного повеселились, теперь пора браться за работу!</span>
          <span class="line">Как я уже говорил-мы любим порядок во всем. В Компании есть свои нормы поведения —</span>
          <span class="line">они помогают нам идти в одном направлении и не тратить время на обсуждение каких-то рутинных моментов.</span>
          <span class="line">В бизнесе соблюдение корпоративной этики играет важную роль в достижении стратегических целей. Согласен?</span>
        </p>
        <b-button variant="info" @click="nextSlide" size="sm">Да, согласен(а)</b-button>
      </div>

      <div class="first_step" v-if="currentSlideIndex === 6">
        <img src="@/assets/logo2.png" alt="Логотип компании" class="logo_comp2" />

        <div class="text">
          <h6 style="color: #0070c0">Вот наши правила</h6>
          <ul class="list_rules">
            <li>Мы не повторяем одну и ту же ошибку несколько раз. Мы всегда делаем работу над ошибками. Если сотрудник не учится на своих ошибках, мы расстаемся с ним.</li>
            <hr />
            <li>
              Мы работаем на результат. Мы решаем проблемы/задачи с заданным результатом в установленный срок. Если сотрудник не выполняет принятые на себя обязательства в обозначенный срок, мы
              расстаемся с ним.
            </li>
            <hr />
            <li>
              Мы не обманываем, не выдаем желаемое за действительное, не сплетничаем. Обман нас, себя и других, искажение информации- преднамеренное или нет в Компании недопустимы. Сплетни являются
              разновидностью лжи. Уличенный в обмане, искажении информации сотрудник покидает Компанию.
            </li>
            <hr />
            <li>Мы не «циклимся» на проблеме, не ищем виноватого, мы ищем решение. Это дает нам силы и позитив.</li>
            <hr />
            <li>
              Мы говорим по делу. Мы не превращаемся в радио, не вещаем, не манифестируем. Мы говорим конкретно о том, что имеет отношение к текущему моменту времени. Это приближает нас к результату и
              дает нам уверенность.
            </li>
            <hr />
            <li>
              Мы не строим домыслы. Мы приветствуем и поощряем открытые вопросы. Лучший способ узнать- это спросить. Мы благодарим за это. Это повышает доверие друг к другу. Помните, если нам кажется,
              что кто-то что-то чувствует или думает- это чувствуем мы, мы видим все из своей «картины мира».
            </li>
            <hr />
            <li>Следуя этим, на первый взгляд, не хитрым, но очень важным правилам, мы продолжим работать вместе в Компании, к которой будет удовольствие и праздник.</li>
          </ul>
          <h6 style="color: #0070c0">Давайте сделаем это вместе!</h6>
          <b-button variant="info" @click="nextSlide" size="sm" style="margin-left: 85% !important">Продолжить</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import api from "@/api/staff";
import { time } from "@amcharts/amcharts5";
  export default {
    data() {
      return {
        step2State: 1, // Указывает, какой текст показывать на этапе 2
        currentSlideIndex: 0, // Индекс текущего слайда
        canvas: null,
        ctx: null,
        w: 0,
        h: 0,
        particles: [],
        probability: 0.04, // Вероятность создания нового фейерверка
        xPoint: 0,
        yPoint: 0,
        hobby_1: "",
        hobby_2: "",
        hobby_3: "",
        allHobbies: "",
      };
    },
    mounted() {
      if (this.currentSlideIndex === 4) {
        this.initializeCanvas();
      }
    },
    computed: {
      ...mapState({
        users: (state) => state.users.users,
        uid: (state) => state.auth.uid,
      }),
      hobbyStaff() {
        let result = [];
        let allUsersHasHobby = this.users.filter((user) => user.hobby);
        let preData = allUsersHasHobby.reduce((acc, item) => {
          if (!acc.includes(item.hobby)) {
            acc.push(item.hobby);
          }
          return acc;
        }, []);
        for (let item of preData) {
          if (item.includes(",")) {
            let arr = item.split(",");
            result.push(arr);
          } else {
            result.push(item);
          }
        }
        return Array.from(new Set(result.flat()));
      },
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.resizeCanvas);
    },
    watch: {
      currentSlideIndex(newVal) {
        if (newVal === 4) {
          this.$nextTick(() => {
            if (this.$refs.fireworksCanvas) {
              this.initializeCanvas();
            }
          });
        }
      },
    },

    methods: {
      // Инициализация
      initializeCanvas() {
        this.canvas = this.$refs.fireworksCanvas;
        this.ctx = this.canvas.getContext("2d");
        this.resizeCanvas();
        window.addEventListener("resize", this.resizeCanvas);
        this.updateWorld();
      },

      // Изменение размера canvas
      resizeCanvas() {
        if (this.canvas) {
          this.w = this.canvas.width = window.innerWidth;
          this.h = this.canvas.height = window.innerHeight;
        }
      },

      // Главный цикл обновления
      updateWorld() {
        this.updateParticles();
        this.paintParticles();
        window.requestAnimationFrame(this.updateWorld);
      },

      // Обновление частиц
      updateParticles() {
        if (this.particles.length < 500 && Math.random() < this.probability) {
          this.createFirework();
        }
        this.particles = this.particles.filter((particle) => particle.move());
      },

      // Рисование частиц
      paintParticles() {
        this.ctx.globalCompositeOperation = "source-over";
        this.ctx.fillStyle = "rgba(14, 14, 14, 0.5)";
        this.ctx.fillRect(0, 0, this.w, this.h);

        this.ctx.globalCompositeOperation = "lighter";
        this.particles.forEach((particle) => particle.draw(this.ctx));
      },

      // Создание фейерверка
      createFirework() {
        this.xPoint = Math.random() * (this.w - 200) + 100;
        this.yPoint = Math.random() * (this.h - 200) + 100;
        const nFire = Math.random() * 50 + 100;
        const c = `rgb(${~~(Math.random() * 200 + 55)}, ${~~(Math.random() * 200 + 55)}, ${~~(Math.random() * 200 + 55)})`;
        for (let i = 0; i < nFire; i++) {
          const particle = this.createParticle(c);
          this.particles.push(particle);
        }
      },

      // Создание частицы
      createParticle(color) {
        const particle = {
          x: this.xPoint,
          y: this.yPoint,
          vx: (Math.random() - 0.5) * 10,
          vy: (Math.random() - 0.5) * 10,
          alpha: Math.random() * 0.5 + 0.5,
          w: Math.random() * 4 + 1,
          gravity: 0.05,
          color,

          move() {
            this.x += this.vx;
            this.vy += this.gravity;
            this.y += this.vy;
            this.alpha -= 0.01;
            return this.alpha > 0 && this.y < window.innerHeight;
          },

          draw(ctx) {
            ctx.save();
            ctx.beginPath();
            ctx.translate(this.x, this.y);
            ctx.arc(0, 0, this.w, 0, Math.PI * 2);
            ctx.fillStyle = this.color;
            ctx.globalAlpha = this.alpha;
            ctx.fill();
            ctx.restore();
          },
        };

        return particle;
      },
      closeElement() {
        this.$emit("closeElement"); // Закрытие всего компонента
      },
      changeStep2Text() {
        this.step2State = 2; // Переключаем текст на этапе 2
      },
      async nextSlide() {
        if (this.currentSlideIndex === 3) {
          let result = [];
          result.push(this.hobby_1.trim(), this.hobby_2.trim(), this.hobby_3.trim(), ...this.allHobbies);
          let hobbyUser = result.filter((item) => item != "").join(",");
          try{
            await api.changeUserData(this.uid, { hobby: hobbyUser });
            this.$toast.success("Данные по Вашим хобби сохранены", {
              timeout: 1500,
            });

          } catch (error) {
            this.$toast.error(`Данные по Вашим хобби не сохранены\n${error}`, {
              timeout: 1500,
            });
          }


        }
        if (this.currentSlideIndex < 6) {
          // Убедиться, что не выйдем за пределы
          this.currentSlideIndex++;
        } else {
          this.closeElement();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
@use "../style/style_training_form.scss";
</style>
