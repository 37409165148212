import { render, staticRenderFns } from "./NewUserInCompany.vue?vue&type=template&id=fe8a5d84&scoped=true"
import script from "./NewUserInCompany.vue?vue&type=script&lang=js"
export * from "./NewUserInCompany.vue?vue&type=script&lang=js"
import style0 from "./NewUserInCompany.vue?vue&type=style&index=0&id=fe8a5d84&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe8a5d84",
  null
  
)

export default component.exports