<template>
    <div id="navbarMain">
      <b-card no-body>
        <b-tabs pills card vertical nav-wrapper-class="w-100" style="
              background: white;
              height: 95vh;
              text-align: center !important;
              justify-content: center !important;
              overflow: auto;
            ">
          <b-container class="bv-example-row">
            <b-tab title="Формирование парка " active @click="newTab('Формирование парка')"> </b-tab>
            <b-tab title="Размещение парка " active @click="newTab('Размещение парка')"> </b-tab>
            <b-tab title="3. Арендованный парк " active @click="newTab('3. Арендованный парк')"> </b-tab>
            <b-tab title="4. Парк, переданный в аренду " active @click="newTab('4. Парк, переданный в аренду')"> </b-tab>
            <b-tab title="5. Справка о выполнении перевозок полувагонами " active @click="newTab('5. Справка о выполнении перевозок полувагонами')"> </b-tab>
            <b-tab title="6. Справка о выполнении перевозок вагоно-цистернами" active @click="newTab('6. Справка о выполнении перевозок вагоно-цистернами')"> </b-tab>

            <b-tab title="7. Анализ перевозки и выручки по сегменту полувагонов" active @click="newTab('7. Анализ перевозки и выручки по сегменту полувагонов')"> </b-tab>
            <b-tab title="8. Производство по универсальным перевозкам (собственный парк)" active @click="newTab('8. Производство по универсальным перевозкам (собственный парк)')"> </b-tab>
            <b-tab title="9. Производство по универсальным перевозкам (привлеченный парк)" active @click="newTab('9. Производство по универсальным перевозкам (привлеченный парк)')"> </b-tab>
            <!-- 10 -->
            <b-tab title="10. Производство по наливным перевозкам (собственный парк)" active @click="newTab('10. Производство по наливным перевозкам (собственный парк)')"> </b-tab>
            <!-- 11 -->
            <b-tab title="11. Производство по наливным перевозкам (привлеченный парк)" active @click="newTab('11. Производство по наливным перевозкам (привлеченный парк)')"> </b-tab>
            <!-- 12 -->
            <b-tab title="12. Доходность по виду перевозок по сегменту полувагонов" active @click="newTab('12. Доходность по виду перевозок по сегменту полувагонов')"> </b-tab>
            <!-- 13 -->
            <b-tab title="13. Анализ доходности по сегменту вагоно-цистерн" active @click="newTab('13. Анализ доходности по сегменту вагоно-цистерн')"> </b-tab>
            <!-- 14 -->
            <b-tab title="14. Анализ доходности по направлениям по сегменту вагоно-цистерн (собственный парк)" active @click="newTab('14. Анализ доходности по направлениям по сегменту вагоно-цистерн (собственный парк)')"> </b-tab>
            <!-- 15 -->
            <b-tab title="15. Анализ доходности по направлениям по сегменту вагоно-цистерн (привлеченный парк)" active @click="newTab('15. Анализ доходности по направлениям по сегменту вагоно-цистерн (привлеченный парк)')"> </b-tab>
            <!-- 16 -->
            <b-tab title="16. Операционные доходы и расходы в детализации «до вагона»" active @click="newTab('16. Операционные доходы и расходы в детализации «до вагона»')"> </b-tab>
            <!-- 17 -->
            <b-tab title="17. Свод доходов и расходов по производственной деятельности" active @click="newTab('17. Свод доходов и расходов по производственной деятельности')"> </b-tab>
            <!-- 18 -->
            <b-tab title="18. Операционная прибыль от предоставления вагонов под погрузку по сегменту вагоно-цистерн" active @click="newTab('18. Операционная прибыль от предоставления вагонов под погрузку по сегменту вагоно-цистерн')"> </b-tab>
            <!-- 19 -->
            <b-tab title="19. Операционная прибыль от предоставления вагонов под погрузку по сегменту полувагонов" active @click="newTab('19. Операционная прибыль от предоставления вагонов под погрузку по сегменту полувагонов')"> </b-tab>
            <!-- 20 -->
            <b-tab title="20. Совокупная операционная прибыль от предоставления вагонов под погрузку" active @click="newTab('20. Совокупная операционная прибыль от предоставления вагонов под погрузку')"> </b-tab>
            <!-- 21 -->
            <b-tab title="21. Анализ маржинального дохода по сегменту вагоно-цистерн" active @click="newTab('21. Анализ маржинального дохода по сегменту вагоно-цистерн')"> </b-tab>
            <!-- 22 -->
            <b-tab title="22. Сводные расходы на ремонт ПС" active @click="newTab('22. Сводные расходы на ремонт ПС')"> </b-tab>
            <!-- 23 -->
            <b-tab title="23. Депо плановых ремонтов" active @click="newTab('23. Депо плановых ремонтов')"> </b-tab>
            <!-- 24 -->
            <b-tab title="24. Простои в ремонте" active @click="newTab('24. Простои в ремонте')"> </b-tab>
            <!-- 25 -->
            <b-tab title="25. Сводные показатели деятельности" active @click="newTab('25. Сводные показатели деятельности')"> </b-tab>
            <!-- 26 -->
            <b-tab title="26. Состояние взаиморасчетов с клиентами" active @click="newTab('26. Состояние взаиморасчетов с клиентами')"> </b-tab>
            <!-- 27 -->
            <b-tab title="27. Состояние взаиморасчетов с экспедиторами по сопредельным территориям" active @click="newTab('27. Состояние взаиморасчетов с экспедиторами по сопредельным территориям')"> </b-tab>
            <!-- 28 -->
            <b-tab title="28. Детализированное состояние взаиморасчетов с экспедиторами по сопредельным территориям" active @click="newTab('28. Детализированное состояние взаиморасчетов с экспедиторами по сопредельным территориям')"> </b-tab>

            <b-tab title="Фин. отчет №1" active @click="newTab('Фин. отчет №1')"> </b-tab>
            <b-tab title="Фин. отчет №2" active @click="newTab('Фин. отчет №2')"> </b-tab>
          </b-container>
        </b-tabs>
      </b-card>
    </div>
  </template>
    
  <script>
  
  
  export default {
    name: 'ManagmentRepNavbar',
    props: ['tabs', 'counter'],
    data: function () {
      return {
        inner_counter: this.counter
      }
    },
    methods: {
      newTab(tabname) {
        this.tabs.push({ 'name': tabname, 'id': Date.now() })
  
        localStorage.setItem('tabs', JSON.stringify([...this.tabs]))
      }
    }
  
  }
  
  </script>