<template>
    <h3>Страница не найдена, введитие корректный url-адрес</h3>
</template>

<script>
    export default{
        name: 'errorPage'
    }
</script>

<style>
h3{
    text-align: center;
    margin-top: 20%;
}
</style>