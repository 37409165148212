const state = {
  newYearStart: false,
};

const mutations = {
  increment(state) {
    state.newYearStart = !state.newYearStart;
  },
};

const actions = {
  increment(context) {
    context.commit("increment");
  },
};

export default {
  state,
  mutations,
  actions,
};
