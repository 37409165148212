<template>
  <div>
    <hr />
    <div style="display: flex; vertical-align: middle; align-items: center">
      <b-spinner :variant="'secondary'" style="width: 2rem; height: 2rem" v-show="is_mini_loader"></b-spinner>
      <h4 v-show="getOwnWagonsCompareData.length > 0" class="air_block_header">Перечень незастрахованных вагонов</h4>
    </div>
    <div class="tables-container">
      <div class="table-container">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" v-model="is_show" />
          <label class="form-check-label" for="flexCheckDefault">Показать вагоны с примечанием</label>
        </div>
        <span class="description-text">Для сохранения примечания нажмите ENTER</span>
        <hot-table
          ref="hotTableComponent1"
          :data="checkApplication"
          :rowHeaders="true"
          :columns="columns"
          :manualRowMove="true"
          :manualColumnMove="true"
          :preventOverflow="'horizontal'"
          :filters="true"
          :language="'ru-RU'"
          :manualColumnResize="true"
          :height="'40vh'"
          :width="'100%'"
          :fillHandle="false"
          :dropdownMenu="dropdownMenuOptions"
          @afterSelection="handleSelection"
          :licenseKey="'non-commercial-and-evaluation'"
        ></hot-table>
      </div>
      <div class="table-container">
        <br />
        <span class="description-text">Скопируйте данные из левой таблицы и вставьте данные в эту таблицу</span>
        <hot-table
          ref="hotTableComponent2"
          :data="insuredWagonsData"
          :rowHeaders="true"
          :columns="columns_table_copy"
          :manualRowMove="true"
          :manualColumnMove="true"
          :preventOverflow="'horizontal'"
          :filters="true"
          :language="'ru-RU'"
          :manualColumnResize="true"
          :height="'40vh'"
          :width="'100%'"
          :fillHandle="true"
          :dropdownMenu="dropdownMenuOptions"
          @afterPaste="handlePaste"
          :contextMenu="contextMenuOptions"
          :licenseKey="'non-commercial-and-evaluation'"
        ></hot-table>
      </div>
    </div>
    <br />

    <b-button-toolbar style="display: flex; justify-content: flex-end">
      <b-button-group>
        <b-button size="sm" class="mb-2 border rounded p-2" style="min-width: 150px !important" @click="getInfoByWagons()">
          <b-icon icon="download" aria-hidden="true"></b-icon>
          &nbsp;&nbsp;Подгрузить данные
        </b-button>
        <b-button size="sm" class="mb-2 border rounded p-2" style="background: #264722; min-width: 150px !important" @click="saveData()">
          <b-icon icon="check-all" aria-hidden="true" focusable="false"></b-icon>
          &nbsp;&nbsp;Сохранить изменения
        </b-button>
      </b-button-group>
    </b-button-toolbar>
  </div>
</template>
<script>
  import { HotTable } from "@handsontable/vue";
  import { registerAllModules } from "handsontable/registry";
  import { registerLanguageDictionary, getLanguagesDictionaries, ruRU } from "handsontable/i18n";
  import api from "@/api/directory";
  import api_wagon from "@/api/wagonPark";
  registerLanguageDictionary(ruRU);
  registerAllModules();
  import "handsontable/dist/handsontable.full.css";
  import { wagon_park } from "@/api/reportUO";

  export default {
    components: {
      HotTable,
    },
    props: ["getOwnWagonsCompareData", "columns", "columns_table_copy", "dropdownMenuOptions", "is_mini_loader"],
    data() {
      return {
        totalRequests: 0,
        processedCount: 0,
        sortWagons: [],
        insuredWagonsData: [{ title: "Номер вагона", data: "wagon_number" }],

        contextMenuOptions: {
          items: {
            remove_row: {
              name: "Удалить строку",
            },
          },
        },
        selectedWagons: [],
        status: false,
        is_show: false,
      };
    },
    computed: {
      checkApplication() {
        if (this.is_show) {
          console.log(this.getOwnWagonsCompareData, "проверка");
          // Показывать только те данные, у которых есть примечание
          return this.getOwnWagonsCompareData.filter((item) => item["Примечание"] !== null && item["Примечание"] !== "");
        } else if (!this.is_show) {
          // Показывать все данные, кроме тех, у которых есть примечание
          return this.getOwnWagonsCompareData.filter((item) => item["Примечание"] === null || item["Примечание"] === "");
        }
      },
    },
    watch: {
      is_show() {
        // Перезагружаем таблицу при изменении чекбокса
        this.$refs.hotTableComponent1.hotInstance.loadData(this.checkApplication);
      },
      getOwnWagonsCompareData: {
        async handler(newData) {
          this.updateTableData("hotTableComponent1", this.checkApplication);

          // Обновляем таблицу, если исходные данные изменились
          this.$refs.hotTableComponent1.hotInstance.loadData(this.checkApplication);
        },
        deep: true,
      },

      insuredWagonsData: {
        handler(newData) {
          this.updateTableData("hotTableComponent2", newData, this.insuredWagonsData);
          console.log("newData", newData);
        },
        deep: true,
      },
    },

    mounted() {
      const hotInstance = this.$refs.hotTableComponent1.hotInstance;
      hotInstance.addHook("afterChange", this.handleAfterChange);
      this.updateTableData("hotTableComponent1", this.checkApplication);
      this.updateTableData("hotTableComponent2", this.insuredWagonsData);
    },

    methods: {
      async handleAfterChange(changes, source) {
        if (source === "edit" || source === "CopyPaste.paste" || source === "Autofill.fill") {
          const hotInstance = this.$refs.hotTableComponent1.hotInstance;

          changes.forEach(([row, prop, oldValue, newValue]) => {
            if (oldValue !== newValue) {
              const rowData = { ...hotInstance.getSourceDataAtRow(row) }; // Копируем данные строки
              try {
                this.$emit("startStopLoader", true);
                api_wagon.putNotInsuredWagons(rowData.id, {
                  wagon_number: rowData["Номер вагона"],
                  comment: rowData["Примечание"],
                });

                this.$emit("startStopLoader", false);
                this.$toast.success(`Данные по вагону сохранены`, {
                  timeout: 3000,
                });
                this.checkApplication;
              } catch (err) {
                console.log(err);
                this.$emit("startStopLoader", false);
                this.$toast.error(`Данные не сохранены\n${err}`, {
                  timeout: 3000,
                });
              }
            }
          });
        }
      },
      translateWagonType(type) {
        switch (type) {
          case "Полувагон":
            return "ПВ";
            break;
          case "Цистерна":
            return "ЦС";
            break;
        }
      },
      // Получение и вывод информации по вагонам
      // async getInfoByWagons(data) {
      //   this.$emit("startStopLoader", true);
      //   this.processedCount = 0;
      //   this.totalRequests = this.insuredWagonsData.length * 3; // Три запроса на каждый вагон

      //   try {
      //     this.updateProgressText();
      //     const chunkSize = 100;
      //     let hasError = false;

      //     for (let i = 0; i < this.insuredWagonsData.length; i += chunkSize) {
      //       const chunk = this.insuredWagonsData.slice(i, i + chunkSize);

      //       // Создаем промисы для текущего чанка
      //       const chunkPromises = chunk.map((item) => {
      //         return Promise.allSettled([api_wagon.getFlights(item["wagon_number"]), api_wagon.getWagon(item["wagon_number"]), api_wagon.getWagonsOnBalance({ wagons: [item["wagon_number"]] })]);
      //       });

      //       // Выполняем текущий чанк и обрабатываем результаты
      //       const results = await Promise.all(chunkPromises);

      //       results.forEach((promisesResults, index) => {
      //         const dataIndex = i + index;
      //         const [flightResult, wagonResult, balanceResult] = promisesResults;

      //         // Проверяем, выполнены ли запросы успешно, и обновляем данные
      //         if (flightResult.status === "fulfilled" && wagonResult.status === "fulfilled" && balanceResult.status === "fulfilled") {
      //           const flightData = flightResult.value;
      //           const wagonData = wagonResult.value;
      //           const balanceData = balanceResult.value;

      //           flightData.data.data.sort((a, b) => (a.arrival_next_loading_station_date > b.arrival_next_loading_station_date ? 1 : -1));

      //           if (this.insuredWagonsData[dataIndex]["Номер вагона"] === wagonData.data.wagon_number) {
      //             this.insuredWagonsData[dataIndex].wagon_type = this.translateWagonType(wagonData.data.wagon_type);
      //             this.insuredWagonsData[dataIndex].owner_at_insurance_moment = flightData.data.data.at(-1)?.in_control ?? null;
      //             this.insuredWagonsData[dataIndex].on_balance_1c = Object.values(balanceData.data).length ? "Да" : "Нет";
      //           }
      //           this.processedCount += 3; // Считаем три запроса
      //         } else {
      //           hasError = true;
      //           console.log(`Ошибка при получении данных для вагона: ${chunk[index]["wagon_number"]}`);
      //         }
      //       });

      //       // Останавливаем загрузку, если произошла ошибка
      //       if (hasError) {
      //         this.$toast.error("Ошибка при загрузке данных. Проверьте запросы.");
      //         this.$emit("startStopLoader", false);
      //         return;
      //       }
      //     }

      //     // Обновляем таблицу после выполнения всех запросов
      //     this.updateTableData("hotTableComponent2", this.insuredWagonsData);
      //   } catch (err) {
      //     console.log("Ошибка при выполнении запросов", err);
      //   } finally {
      //     this.$emit("startStopLoader", false);
      //   }
      // },
      async getInfoByWagons(data) {
        this.$emit("startStopLoader", true);
        this.$emit("amountRequests", this.insuredWagonsData.length, this.processedCount);
        this.processedCount = 0;
        this.totalRequests = this.insuredWagonsData.length;
        try {
          this.updateProgressText(); // Изначально обновляем текст прогресса
          const chunkSize = 10;
          let hasError = false;

          for (let i = 0; i < this.insuredWagonsData.length; i += chunkSize) {
            const chunk = this.insuredWagonsData.slice(i, i + chunkSize);

            // Создаем промисы для текущего чанка
            const chunkPromises = chunk.map((item) => {
              return Promise.allSettled([api_wagon.getFlights(item["wagon_number"]), api_wagon.getWagon(item["wagon_number"]), api_wagon.getWagonsOnBalance({ wagons: [item["wagon_number"]] })]);
            });

            // Выполняем текущий чанк и обрабатываем результаты
            const results = await Promise.all(chunkPromises);

            results.forEach((promisesResults, index) => {
              const dataIndex = i + index;
              const [flightResult, wagonResult, balanceResult] = promisesResults;

              // Проверяем результаты каждого запроса отдельно
              let requestsProcessed = 0; // Локальный счетчик для текущего элемента

              if (flightResult.status === "fulfilled") {
                const flightData = flightResult.value;
                flightData.data.data.sort((a, b) => (a.arrival_next_loading_station_date > b.arrival_next_loading_station_date ? 1 : -1));

                if (this.insuredWagonsData[dataIndex]["Номер вагона"] === flightData.data.wagon_number) {
                  this.insuredWagonsData[dataIndex].owner_at_insurance_moment = flightData.data.data.at(-1)?.in_control ?? null;
                  requestsProcessed += 1; // Увеличиваем счетчик для успешного запроса
                }
              } else {
                hasError = true;
                console.log(`Ошибка при получении данных о рейсах для вагона: ${chunk[index]["wagon_number"]}`);
              }

              if (wagonResult.status === "fulfilled") {
                const wagonData = wagonResult.value;

                if (this.insuredWagonsData[dataIndex]["Номер вагона"] === wagonData.data.wagon_number) {
                  this.insuredWagonsData[dataIndex].wagon_type = this.translateWagonType(wagonData.data.wagon_type);
                  requestsProcessed += 1; // Увеличиваем счетчик для успешного запроса
                }
              } else {
                hasError = true;
                console.log(`Ошибка при получении данных о вагоне: ${chunk[index]["wagon_number"]}`);
              }

              if (balanceResult.status === "fulfilled") {
                const balanceData = balanceResult.value;
                this.insuredWagonsData[dataIndex].on_balance_1c = Object.values(balanceData.data).length ? "Да" : "Нет";
                requestsProcessed += 1; // Увеличиваем счетчик для успешного запроса
              } else {
                hasError = true;
                console.log(`Ошибка при получении данных о балансе для вагона: ${chunk[index]["wagon_number"]}`);
              }

              this.processedCount += 1;
              this.$emit("amountRequests", this.insuredWagonsData.length, this.processedCount);
            });

            // Обновляем текст прогресса после обработки всего чанка
            this.updateProgressText();

            // Останавливаем загрузку, если произошла ошибка
            if (hasError) {
              this.$toast.error("Ошибка при загрузке данных. Проверьте запросы.");
            }
          }

          this.$emit("startStopLoader", false);
          this.updateTableData("hotTableComponent2", this.insuredWagonsData);
        } catch (err) {
          console.log("Ошибка при выполнении запросов", err);
        } finally {
          this.$emit("startStopLoader", false);
        }
      },

      updateProgressText() {
        if (this.totalRequests === this.processedCount) {
          this.$toast.success("Данные загружены", { timeout: 3000 });
        }
      },

      updateTableData(componentRef, newData) {
        this.$nextTick(() => {
          const hotInstance = this.$refs[componentRef]?.hotInstance;
          if (hotInstance) {
            hotInstance.loadData(newData);
            hotInstance.updateSettings({ data: newData });
          }
        });
      },
      handleSelection(row, column, row2, column2) {
        const hotInstance = this.$refs.hotTableComponent1.hotInstance;
        const selected = [];
        for (let r = row; r <= row2; r++) {
          for (let c = column; c <= column2; c++) {
            selected.push(hotInstance.getDataAtCell(r, c));
          }
        }
        this.selectedWagons = selected;
      },

      handlePaste(changes) {
        const hotInstance2 = this.$refs.hotTableComponent2.hotInstance;
        changes.forEach((change) => {
          const [row, col, oldVal, newVal] = change;
          if (this.columns_table_copy[col].data === "wagon_number") {
            hotInstance2.setDataAtCell(row, col, newVal);
          }
        });
      },
    },
  };
</script>

<style scoped>
  .air_block_header {
    padding: 1% 0 0 2%;
    color: #cacaca;
  }

  .tables-container {
    display: flex;

    gap: 10px;
  }

  .table-container:nth-child(2) {
    width: 100%;
    /* background: green; */
  }

  .table-container:first-child {
    width: 35%;
    /* background: lightgrey */
  }
</style>
